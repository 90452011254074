import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from "./App";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { RoutesComp } from "./Components/RoutesComp";
import { Provider } from "react-redux";
import Store, { persistor } from "./Redux/Storage";
import { PersistGate } from "redux-persist/integration/react";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d99fe",
    },
    secondary: {
      main: "#141e36",
    },
    success: {
      main: "#28c76f",
    },
    info: {
      main: "#00cfe8",
    },
    warning: {
      main: "#ff9f43",
    },
    danger: {
      main: "#ea5455",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    color: "#5E5873",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Provider store={Store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <RoutesComp />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </React.Fragment>
);
