import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Button } from "@mui/material";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DescriptionIcon from "@mui/icons-material/Description";
import "./Calculate.css";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  editVersionNotes,
  versionComment,
} from "../../../Redux/Projects/Action";
import { useEffect } from "react";
import { Notify } from "../../UI/Notify";

const drawerWidth = 390;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function VersionNotes(props) {
  const dispatch = useDispatch();

  const theme = useTheme();

  const [notification, setNotification] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [open, setOpen] = React.useState(false);
  const projectId = props.projectId;
  const version = Number(props?.version);
  const allComments = useSelector((store) => store.project.allComments);

  const availableVersionNote =
    allComments?.length > 0 &&
    allComments?.filter(
      (comment) => comment?.versionNote && comment?.versionNote?.trim() !== ""
    );

  const filteredComments = allComments?.filter(
    (comment) => comment?.version === version && availableVersionNote
  );

  const initialTextareaContent =
    filteredComments?.length > 0 ? filteredComments[0]?.versionNote : "";

  const [textareaContent, setTextareaContent] = useState(
    initialTextareaContent || ""
  );

  useEffect(() => {
    setTextareaContent(initialTextareaContent || "");
  }, [version, initialTextareaContent]);

  const handleTextareaChange = (event) => {
    setTextareaContent(event.target.value);
  };

  const handleUpdateClick = () => {
    const data = {
      versionNote: textareaContent,
    };
    dispatch(versionComment(data, projectId, version));
    dispatch(editVersionNotes(version, data, allComments));
    setNotification({
      isOpen: true,
      message: "Successfully Updated Note",
      type: "success",
    });

    if (version && data && allComments) {
      dispatch(editVersionNotes(version, data, allComments));
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Notify notification={notification} setNotification={setNotification} />

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <CssBaseline />
        <Toolbar>
          <Button
            onClick={handleDrawerOpen}
            startIcon={<DescriptionIcon />}
            sx={{
              ...(open && {
                display: "none",
              }),
              backgroundColor: "white",
              fontSize: "9px",

              marginLeft: "-20px",
            }}
            variant="contained"
          >
            Version Notes
          </Button>
        </Toolbar>

        <Drawer
          sx={{
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              marginTop: "64px",
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
            <Typography variant="h6">Version Notes</Typography>
          </DrawerHeader>
          <Divider />
          <div
            style={{ padding: "15px", overflowY: "auto" }}
            className="notecontent"
          >
            <TextareaAutosize
              key={version}
              style={{
                width: "100%",
                height: "70%",
                fontSize: "16px",
                lineHeight: "1.4",
                fontFamily: "Roboto",
              }}
              maxRows={10}
              minRows={100}
              placeholder="Version Notes"
              value={textareaContent}
              onChange={handleTextareaChange}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateClick}
            >
              Update
            </Button>
          </div>
        </Drawer>
      </Box>
    </>
  );
}
