import React, { useEffect, useState } from "react";
import "./RecentProjectsGrid.css";
import { Typography, Grid, Button } from "@mui/material";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Notify } from "../UI/Notify";
import {
  updateProject,
  deleteProject,
  updatePricingByProject,
  viewMode,
  getOwnerProjectByPagination,
  fetchArchivedProjects,
  fetchTrashedProjects,
  fetchStarredProjects,
  clearProjectId,
  fetchSearchData,
} from "../../Redux/Projects/Action";
import {
  addDepartment,
  finalUpdateProject,
  cloneProject,
} from "../../Redux/Data/Action";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { RecentProjectList } from "./RecentProjectList";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined"; // import { set } from "immer/dist/internal";
import { useHistory } from "react-router-dom";
import { Collaborators } from "../Pages/Collaborators/Collaborators";
import Cookies from "js-cookie";

export const RecentProjectGrid = (props) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = params.get("page");
  const history = useHistory();

  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const dispatch = useDispatch();

  const [inputValues, setInputValues] = useState(null);
  const [archivedValues, setArchivedValues] = useState(null);
  const [trashValues, setTrashValues] = useState(null);
  const [projects, setProjects] = useState("");

  const allProjects = useSelector((store) => store.project.allProjects);
  const store = useSelector((store) => store);
  const searchQuery = useSelector((store) => store.project?.searchQuery);

  const allVersions = useSelector((store) => store.project?.allVersions);

  const starredHandler = (data) => {
    if (data.starred === false) {
      setInputValues({ ...data, starred: true });
    } else {
      setInputValues({ ...data, starred: false });
    }
  };

  useEffect(() => {
    setProjects(props.data);
  }, [props.data]);

  /**
   *
   * @param {boolean} starredButton
   * @param {boolean} archivedButton
   * @param {boolean} trashButton
   * @returns void
   */
  const actionButtonHandlers = async () => {
    let pathName = location?.pathname?.match(/[^/]/gm).join("");

    if (searchQuery && searchQuery.length) {
      await dispatch(
        fetchSearchData(searchQuery, props.limit, props.currentPage, pathName)
      );
    } else {
      if (pathName.includes("archived")) {
        await dispatch(fetchArchivedProjects(props.limit, props.currentPage));
      } else if (pathName.includes("starred")) {
        await dispatch(fetchStarredProjects(props.limit, props.currentPage));
      } else if (pathName.includes("trash")) {
        await dispatch(fetchTrashedProjects(props.limit, props.currentPage));
      } else {
        await dispatch(
          getOwnerProjectByPagination(props.limit, props.currentPage)
        );
      }
    }
  };

  // on click of starred button
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (inputValues) {
          await dispatch(updateProject(inputValues));

          await actionButtonHandlers();

          setTimeout(() => {
            setNotification({
              isOpen: true,
              message: "Successfully updated Starred Project",
              type: "success",
            });
          }, 1500);
        }
      } catch (error) {
        console.error("Error updating starred project:", error);

        setNotification({
          isOpen: true,
          message: "Error updating Starred Project",
          type: "error",
        });
      }
    };

    fetchData();
  }, [inputValues, dispatch, props.limit, props.currentPage]);

  const archivedHandler = (data) => {
    if (data.archived === false) {
      setArchivedValues({ ...data, archived: true });
    } else {
      setArchivedValues({ ...data, archived: false });
    }
  };

  // on click of archived button
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (archivedValues) {
          await dispatch(updateProject(archivedValues));

          await actionButtonHandlers();

          setTimeout(() => {
            setNotification({
              isOpen: true,
              message: "Successfully updated Archived Project",
              type: "success",
            });
          }, 1500);
        }
      } catch (error) {
        console.error("Error updating archived project:", error);

        setNotification({
          isOpen: true,
          message: "Error updating Archived Project",
          type: "error",
        });
      }
    };

    fetchData();
  }, [archivedValues, dispatch, props.limit, props.currentPage]);

  const trashHandler = (data) => {
    if (data.trash === false) {
      setTrashValues({ ...data, trash: true });
    } else {
      setTrashValues({ ...data, trash: false });
    }
  };

  // on click of trashed button
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (trashValues) {
          await dispatch(updateProject(trashValues));

          await actionButtonHandlers();

          setTimeout(() => {
            setNotification({
              isOpen: true,
              message: "Successfully updated Trashed Project",
              type: "success",
            });
          }, 1500);
        }
      } catch (error) {
        console.error("Error updating trashed project:", error);

        setNotification({
          isOpen: true,
          message: "Error updating Trashed Project",
          type: "error",
        });
      }
    };

    fetchData();
  }, [trashValues, dispatch, props.limit, props.currentPage]);

  //Search logic
  useEffect(() => {
    // if (store.department.searchData) {
    const searchData = props?.data?.filter((item) => {
      return Object.values(item)
        .reverse()
        .join(" ")
        .toLowerCase()
        .includes(store.project.searchData.toLowerCase());
    });
    setProjects(searchData);
    // }
  }, [store.project.searchData]);

  //permanently delete
  const deleteHandler = async (data) => {
    try {
      await dispatch(deleteProject(data));

      if (searchQuery) {
        await dispatch(
          fetchSearchData(searchQuery, props.limit, props.currentPage, "trash")
        );
      } else {
        await dispatch(fetchTrashedProjects(props.limit, props.currentPage));
      }

      setTimeout(() => {
        setNotification({
          isOpen: true,
          message: "Permanently Deleted Project",
          type: "error",
        });
      }, 1000);
    } catch (error) {
      console.error("Error deleting project:", error);

      setNotification({
        isOpen: true,
        message: "Error Deleting Project",
        type: "error",
      });
    }
  };

  // update store for project edit opp
  const updateProjectHandler = (data) => {
    // Department Updated as per all deps
    let selectedDept = [];
    data.departments.map((e) => {
      const dept = store?.settings?.newDepartments.filter(
        (elm) => elm._id === e.departmentId
      );

      selectedDept = [...selectedDept, ...dept];
    });

    dispatch(addDepartment(selectedDept));
    dispatch(finalUpdateProject(data));
  };

  //View Project Pricing

  const pricingViewHandler = (data) => {
    dispatch(viewMode(data));
    dispatch(finalUpdateProject(data));

    dispatch(updatePricingByProject(data._id));
  };

  const cloneHandler = (item) => {
    let selectedDept = [];
    item.departments.map((e) => {
      const dept = store?.settings?.newDepartments.filter(
        (elm) => elm._id === e.departmentId
      );

      selectedDept = [...selectedDept, ...dept];
    });

    dispatch(cloneProject(item._id));
    dispatch(addDepartment(selectedDept));
    history.push("/home/department");
  };

  return (
    <>
      <Notify notification={notification} setNotification={setNotification} />
      <Grid
        container
        pb="40px"
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        spacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {projects
          ? projects.slice(0).map((item, index) => (
              <Grid key={index} item sm={6} lg={3} md={4}>
                <div className="backcardhome">
                  <div className="cards__top">
                    <div className="card_namesection">
                      <Typography
                        sx={{ fontSize: "14px", color: "white" }}
                        variant="caption"
                      >
                        {item.projectTypeName}
                      </Typography>
                    </div>

                    <div className="clone ">
                      <FileCopyOutlinedIcon
                        className="clone myDIV"
                        onClick={() => cloneHandler(item)}
                        sx={{
                          fontSize: "23px",
                          marginRight: "8px",
                          cursor: "pointer",
                        }}
                      />

                      {location.pathname !== "/shared-with-me" ? (
                        <Collaborators
                          _id={item._id}
                          allVersions={allVersions}
                          limit={props.limit}
                          currentPage={props.currentPage}
                        />
                      ) : (
                        item &&
                        item?.collaborator[0]?.collaborators?.filter(
                          (elm) => elm.collaboratorId === Cookies.get("_id")
                        )[0]?.collaboratorRole !== "view" && (
                          <Collaborators
                            _id={item._id}
                            allVersions={allVersions}
                          />
                        )
                      )}
                    </div>
                  </div>
                  <p className="hide" style={{ paddingTop: "10px" }}>
                    Clone Project
                  </p>
                  <div className="viewcard">
                    {item.logo && (
                      <img
                        src={item.logo}
                        style={{ maxWidth: "100px", maxHeight: "80px" }}
                        alt="100px"
                      />
                    )}
                  </div>
                  <div>
                    {location.pathname === "/shared-with-me" ? (
                      <Typography
                        variant="h6"
                        fontWeight="500"
                        textAlign="center"
                        className="shared-with-me"
                      >
                        {item.projectName}
                      </Typography>
                    ) : (
                      <Link
                        to={`/calculate/${item?._id}?page=${page}`}
                        className="link"
                      >
                        <Typography
                          variant="h6"
                          fontWeight="500"
                          textAlign="center"
                          className="projectname"
                          onClick={() => pricingViewHandler(item)}
                        >
                          {/* <PersonPinIcon /> */}
                          {item.projectName}
                        </Typography>
                      </Link>
                    )}
                    <Typography
                      variant="body1"
                      fontWeight="thin"
                      textAlign="center"
                      className="clientName"
                    >
                      {item.clientName}
                      {/* <ArrowRightAltIcon className="projectname__icon " /> */}
                    </Typography>
                  </div>

                  {location.pathname != "/shared-with-me" ? (
                    <span className="project__icons">
                      <Button
                        variant="text"
                        sx={{ padding: "0" }}
                        value={item.name}
                        onClick={() => starredHandler(item)}
                      >
                        <StarIcon
                          fontSize="large"
                          className="project__singleicons btn btn-1"
                          color="red"
                          sx={{
                            fill: item?.starred ? "white" : "transparent",
                            stroke: "white",
                          }}
                        />
                      </Button>

                      <Button
                        sx={{ padding: "0" }}
                        variant="text"
                        value={item}
                        onClick={() => archivedHandler(item)}
                      >
                        <ArchiveIcon
                          fontSize="large"
                          className="project__singleicons btn btn-2"
                          onClick={archivedHandler}
                        />
                      </Button>

                      <Button
                        sx={{ padding: "0" }}
                        variant="text"
                        value={item}
                        onClick={() => trashHandler(item)}
                      >
                        <DeleteForeverIcon
                          fontSize="large"
                          className=" btn btn-3 project__singleicons"
                          onClick={trashHandler}
                        />
                      </Button>
                    </span>
                  ) : (
                    ""
                  )}
                  {location.pathname != "/shared-with-me" ? (
                    <div className="update_buttons">
                      {!item.trash ? (
                        <>
                          <Link
                            style={{
                              color: "black",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                            to={`/department/update/id:${
                              item._id
                            }?page=${location.pathname.substring(1)}`}
                          >
                            <Button
                              variant="contained"
                              sx={{
                                color: "white",

                                width: "100% !important",
                              }}
                              onClick={() => updateProjectHandler(item)}
                              fullWidth
                            >
                              Edit Project
                            </Button>
                          </Link>
                        </>
                      ) : location.pathname === "/archived" ||
                        location.pathname === "/starred" ? (
                        <>
                          <Link
                            style={{
                              color: "black",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                            to={`/department/update/id:${item._id}`}
                          >
                            <Button
                              variant="contained"
                              sx={{
                                color: "white",

                                width: "100% !important",
                              }}
                              onClick={() => updateProjectHandler(item)}
                              fullWidth
                            >
                              Edit Project
                            </Button>
                          </Link>
                        </>
                      ) : (
                        <Button
                          variant="contained"
                          color="error"
                          sx={{
                            color: "white",
                            width: "100% !important",
                          }}
                          onClick={() => deleteHandler(item)}
                          startIcon={<DeleteForeverIcon fontSize="large" />}
                        >
                          Delete
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div className="update_buttons">
                      {item &&
                      item.collaborator[0].collaborators.filter(
                        (elm) => elm.collaboratorId === Cookies.get("_id")
                      )[0]?.collaboratorRole != "view" ? (
                        <Link
                          style={{
                            color: "black",
                            textDecoration: "none",
                            cursor: "pointer",
                          }}
                          to={`/department/update/id:${
                            item._id
                          }?page=${location.pathname.substring(1)}`}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              color: "white",
                            }}
                            onClick={() => updateProjectHandler(item)}
                            fullWidth
                          >
                            Edit Project
                          </Button>
                        </Link>
                      ) : (
                        <Link
                          style={{
                            color: "black",
                            textDecoration: "none",
                            cursor: "pointer",
                          }}
                          to={`/calculate/${
                            item?._id
                          }?page=${location.pathname.substring(1)}`}
                        >
                          <Button
                            variant="contained"
                            color="warning"
                            sx={{
                              color: "white",
                            }}
                            onClick={() => pricingViewHandler(item)}
                            fullWidth
                          >
                            View Project
                          </Button>
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              </Grid>
            ))
          : ""}
      </Grid>
      {false && (
        <RecentProjectList
          limit={props.limit}
          currentPage={props.currentPage}
          data={allProjects.allHome}
          action={true}
        />
      )}
    </>
  );
};
