import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  InputAdornment,
  FormControl,
  OutlinedInput,
  MenuItem,
  AccordionDetails,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addResources,
  deleteResources,
  updateResources,
} from "../../../Redux/Projects/Action";

const Resources = (props) => {
  const [selectedGenericDesignation, setSelectedGenericDesignation] =
    useState("");
  const [designationByGenericName, setDesignationByGenericName] = useState("");
  const [calculationFactor, setCalculationFactor] = useState("");
  const [id, setId] = useState("");
  const [checked, setChecked] = useState(props.defChecked);
  const [update, setUpdate] = useState(false);

  const selectedVersion = useSelector((store) => store.project.selectedVersion);

  const store = useSelector((store) => store);
  const dispatch = useDispatch();

  //If page === shared-with-me version changer = all / null / number
  //If page !== shared-with-me ; version changer = selected version
  const [versionChanger, setversionChanger] = useState(0);

  useEffect(() => {
    if (
      props.page === "shared-with-me" &&
      props.activeCollab &&
      props.activeCollab !== "all"
    ) {
      setversionChanger(Number(props.activeCollab));
      return;
    }
    setversionChanger(selectedVersion);
  }, [selectedVersion, props.page, props.activeCollab]);

  const projectTypeName = useSelector(
    (store) => store.department.projectTypeName
  );
  const [projectType, setProjectType] = useState();

  useEffect(() => {
    if (projectTypeName.toLowerCase() === "retainer project") {
      setProjectType(true);
    } else {
      setProjectType(false);
    }
  }, []);

  const calculationFactorChange = (e) => {
    let enteredCalculation = e.target.value;

    // Remove non-numeric characters except '.'
    enteredCalculation = enteredCalculation.replace(/[^0-9.]/g, "");

    // Ensure the value is not empty and greater than 0
    let calculationValue =
      enteredCalculation !== "" && parseFloat(enteredCalculation) > 0
        ? enteredCalculation
        : "";

    setCalculationFactor(calculationValue);
  };

  const updateHandler = (
    departmentName,
    departmentId,
    selectedGenericDesignation,
    designationByGenericName,
    calculationFactor
  ) => {
    const designId = store.project.designationByGenericName.filter(
      (elem) => designationByGenericName === elem.name
    );

    const updateData = {
      genericDesignationName: selectedGenericDesignation,
      designationName: designationByGenericName,
      designationId: designId[0]._id,
      departmentName: departmentName,
      departmentId: departmentId,
      calculationFactor: calculationFactor,
    };
    const projectId = store.project.updateProjectData._id;
    dispatch(updateResources(updateData, projectId, id, selectedVersion));
    clearDataHandler();
    setUpdate(false);
  };

  const addHandler = (
    departmentName,
    departmentId,
    selectedGenericDesignation,
    designationByGenericName,
    calculationFactor
  ) => {
    const designId = store.project.designationByGenericName.filter(
      (elem) => designationByGenericName === elem.name
    );

    const addData = {
      genericDesignationName: selectedGenericDesignation,
      designationName: designationByGenericName,
      designationId: designId[0]._id,
      departmentName: departmentName,
      departmentId: departmentId,
      calculationFactor: calculationFactor,
    };
    const projectId = store.project.updateProjectData._id;

    dispatch(addResources(addData, projectId, selectedVersion));
    clearDataHandler();
    setUpdate(false);
  };
  const clearDataHandler = () => {
    setCalculationFactor("");
    setSelectedGenericDesignation("");
    setDesignationByGenericName("");
    setId("");
  };

  const handleOnDelete = (data) => {
    const projectId = store.project.updateProjectData._id;
    dispatch(deleteResources(projectId, data._id, selectedVersion));
    clearDataHandler();
    setUpdate(false);
  };

  const editHandler = (data) => {
    setCalculationFactor(data.calculationFactor);
    setSelectedGenericDesignation(data.genericDesignationName);
    setDesignationByGenericName(data.designationName);
    const dataId = data._id;
    setId(dataId);
    setUpdate(true);
  };
  useEffect(() => {}, [store.project.pricingData]);

  return (
    <>
      <AccordionDetails sx={{ padding: "0", paddingBottom: "8px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItem: "center",
            padding: "0 20px",
          }}
        >
          {/* <Checkbox
          checked={checked}
          onChange={() => setChecked(!checked)}
          sx={{ color: !checked ? "#333" : "#FFF" }}
        /> */}
          {/* {props.isAdded ? ( */}
          {/* <> */}
          <TextField
            id="outlined-select-currency"
            select
            sx={{
              maxWidth: "290px",
              minWidth: "260px",
              mx: "20px",
            }}
            fullWidth
            size="small"
            value={selectedGenericDesignation}
            onChange={(e) => setSelectedGenericDesignation(e.target.value)}
          >
            {props?.genericDesignation?.map((option) => (
              <MenuItem key={option._id} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            id="outlined-select-currency"
            select
            defaultValue={""}
            sx={{
              maxWidth: "290px",
              minWidth: "260px",
              mx: "20px",
            }}
            size="small"
            fullWidth
            value={designationByGenericName}
            onChange={(e) => setDesignationByGenericName(e.target.value)}
          >
            {props.designationByGenericName
              .filter(
                (elem) =>
                  elem.genericDesignationName === selectedGenericDesignation &&
                  props.departmentId === elem.departmentId
              )
              .map((option) => (
                <MenuItem key={option._id} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
          </TextField>

          <FormControl
            sx={{
              maxWidth: "170px",
              minWidth: "120px",
              mx: "20px",
            }}
            fullWidth
            variant="outlined"
            size="small"
          >
            <OutlinedInput
              id="outlined-adornment-weight"
              type="number"
              required={true}
              endAdornment={
                <InputAdornment position="end">
                  {projectType ? "%" : "Days"}
                </InputAdornment>
              }
              aria-describedby="outlined-weight-helper-text"
              pattern="[0-9]+"
              inputProps={{
                "aria-label": "weight",
              }}
              value={calculationFactor}
              onChange={calculationFactorChange}
            />
          </FormControl>

          {store.project.pricingData === null ? (
            <Button
              disabled={
                // !checked ||
                selectedGenericDesignation === "" ||
                designationByGenericName === "" ||
                calculationFactor === ""
              }
              variant="contained"
              sx={{
                backgroundColor: "#2C2C2C",
                color: "white",
                width: "80px",
                fontSize: "12px",
                fontFamily: "Arial",
              }}
              onClick={() => {
                props.handleOnAdd(
                  props.departmentName,
                  true,
                  selectedGenericDesignation,
                  designationByGenericName,
                  calculationFactor
                );
                clearDataHandler();
              }}
            >
              Add.
            </Button>
          ) : (
            <Button
              disabled={
                // !checked ||
                selectedGenericDesignation === "" ||
                designationByGenericName === "" ||
                calculationFactor === ""
              }
              variant="contained"
              sx={{
                backgroundColor: "#2C2C2C",
                color: "white",
                width: "80px",
                fontSize: "12px",
                fontFamily: "Arial",
              }}
              onClick={() =>
                !update
                  ? addHandler(
                      props.departmentName,
                      props.departmentId,
                      selectedGenericDesignation,
                      designationByGenericName,
                      calculationFactor
                    )
                  : updateHandler(
                      props.departmentName,
                      props.departmentId,
                      selectedGenericDesignation,
                      designationByGenericName,
                      calculationFactor
                    )
              }
            >
              {!update ? "Add" : "Update"}
            </Button>
          )}
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#2C2C2C",
              color: "white",
              width: "80px",
              fontSize: "12px",
              fontFamily: "Arial",
              marginLeft: "10px",
              visibility: "hidden",
            }}
          >
            edit
          </Button>
        </div>
      </AccordionDetails>

      {store?.project?.pricingData && versionChanger >= 0
        ? store?.project?.pricingData?.versions[
            versionChanger
          ]?.data[0]?.resources
            .filter((e) => e.departmentName === props.departmentName)
            .map((elm) => (
              <AccordionDetails
                key={elm._id}
                sx={{ padding: "0", paddingBottom: "8px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItem: "center",
                    padding: "0 20px",
                  }}
                >
                  {/* <Checkbox
          checked={checked}
          onChange={() => setChecked(!checked)}
          sx={{ color: !checked ? "#333" : "#FFF" }}
        /> */}
                  {/* {props.isAdded ? ( */}
                  <>
                    <TextField
                      id="outlined-select-currency"
                      select
                      defaultValue={""}
                      sx={{
                        maxWidth: "290px",
                        minWidth: "260px",
                        backgroundColor: "#EFEFEF",
                        mx: "20px",
                      }}
                      disabled={true}
                      size="small"
                      value={elm.genericDesignationName}
                      onChange={(e) =>
                        setSelectedGenericDesignation(
                          elm.genericDesignationName
                        )
                      }
                      fullWidth
                    >
                      {props.genericDesignation.map((option) => (
                        <MenuItem key={option._id} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="outlined-select-currency"
                      select
                      defaultValue={""}
                      sx={{
                        maxWidth: "290px",
                        minWidth: "260px",
                        backgroundColor: "#EFEFEF",
                        mx: "20px",
                      }}
                      disabled={true}
                      size="small"
                      value={elm.designationName}
                      onChange={(e) =>
                        setDesignationByGenericName(elm.designationName)
                      }
                      fullWidth
                    >
                      {elm.genericDesignationName &&
                        props.designationByGenericName
                          .filter(
                            (elem) =>
                              elem.genericDesignationName ===
                              elm.genericDesignationName
                          )
                          .map((option) => (
                            <MenuItem key={option._id} value={option.name}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField>
                  </>

                  <FormControl
                    sx={{
                      maxWidth: "170px",
                      minWidth: "120px",
                      backgroundColor: "#EFEFEF",
                      mx: "20px",
                    }}
                    fullWidth
                    variant="outlined"
                    size="small"
                  >
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      type="number"
                      disabled={true}
                      required={true}
                      endAdornment={
                        <InputAdornment position="end">
                          {projectType ? "%" : "Days"}
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                      value={elm.calculationFactor}
                      onChange={(e) =>
                        setCalculationFactor(elm.calculationFactor)
                      }
                    />
                  </FormControl>
                  {store.project.pricingData === null ? (
                    <Button
                      disabled={
                        // !checked ||
                        selectedGenericDesignation === "" ||
                        designationByGenericName === "" ||
                        calculationFactor === ""
                      }
                      variant="contained"
                      sx={{
                        backgroundColor: "#2C2C2C",
                        color: "white",
                        width: "80px",
                        fontSize: "12px",
                        fontFamily: "Arial",
                      }}
                      onClick={() =>
                        !props.isAdded
                          ? props.handleOnAdd(
                              props.departmentName,
                              checked,
                              selectedGenericDesignation,
                              designationByGenericName,
                              calculationFactor
                            )
                          : handleOnDelete(props.full)
                      }
                    >
                      {props.isAdded ? "Delete" : "Add1"}
                    </Button>
                  ) : (
                    <Button
                      // disabled={
                      //   // !checked ||
                      //   selectedGenericDesignation === "" ||
                      //   designationByGenericName === "" ||
                      //   calculationFactor === ""
                      // }
                      variant="contained"
                      sx={{
                        backgroundColor: "#2C2C2C",
                        color: "white",
                        width: "80px",
                        fontSize: "12px",
                        fontFamily: "Arial",
                      }}
                      onClick={() =>
                        !true
                          ? addHandler(
                              props.departmentName,
                              props.departmentId,
                              selectedGenericDesignation,
                              designationByGenericName,
                              calculationFactor
                            )
                          : handleOnDelete(elm)
                      }
                    >
                      {true ? "Delete" : "Add"}
                    </Button>
                  )}
                  <Button
                    // disabled={
                    //   // !!checked ||
                    //   selectedGenericDesignation === "" ||
                    //   designationByGenericName === "" ||
                    //   calculationFactor === ""
                    // }
                    variant="contained"
                    sx={{
                      backgroundColor: "#2C2C2C",
                      color: "white",
                      width: "80px",
                      marginLeft: "10px",
                      fontSize: "12px",
                      fontFamily: "Arial",
                    }}
                    onClick={() => editHandler(elm)}
                  >
                    Edit
                  </Button>
                </div>
              </AccordionDetails>
            ))
        : null}
    </>
  );
};

export default Resources;
