import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import { Button, MenuItem, Select, FormControl } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import {
  deleteUsers,
  requestAllUsers,
  updateUsers,
} from "../../../Redux/Settings/Action";
import { Notify } from "../../UI/Notify";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0f172a",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const UserRole = (props) => {
  const store = useSelector((store) => store);
  const [input, setInput] = React.useState("");
  const dispatch = useDispatch();
  const [notification, setNotification] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleOnChange = (e, data) => {
    setInput({ role: e.target.value, _id: data._id });
  };

  const updateHandler = () => {
    if (input) {
      dispatch(updateUsers(input));
      setNotification({
        isOpen: true,
        message: "Successfully Updated User Role",
        type: "success",
      });
      setInput("");
    }
  };

  const deleteHandler = (data) => {
    dispatch(deleteUsers(data));
    setTimeout(() => {
      dispatch(requestAllUsers());
      setNotification({
        isOpen: true,
        message: "Permanently Deleted User",
        type: "error",
      });
    }, 1000);
  };

  return (
    <>
      <Notify notification={notification} setNotification={setNotification} />

      <TableContainer
        sx={{
          maxWidth: 1200,
          marginBottom: "50px",
          boxShadow:
            " 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.1)",
        }}
        component={Paper}
      >
        <Table sx={{ maxWidth: 1200 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Sl No</StyledTableCell>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Email </StyledTableCell>
              <StyledTableCell align="center">Role </StyledTableCell>
              <StyledTableCell align="center">Action </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {store.settings.allUsers
              ? store.settings.allUsers.map((row, index) => (
                  <StyledTableRow key={row._id}>
                    <StyledTableCell align="center">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.name}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row.email}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <Select
                          defaultValue={row.role}
                          onChange={(e) => handleOnChange(e, row)}
                          name="role"
                        >
                          <MenuItem value={"Admin"}>Admin</MenuItem>
                          <MenuItem value={"Employee"}>Employee</MenuItem>
                        </Select>
                      </FormControl>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        variant="contained"
                        startIcon={<EditIcon />}
                        sx={{ color: "white", marginRight: "10px" }}
                        onClick={updateHandler}
                      >
                        Update
                      </Button>
                      <Button
                        color="error"
                        variant="contained"
                        sx={{ color: "white" }}
                        onClick={() => deleteHandler(row)}
                        startIcon={<DeleteForeverIcon />}
                      >
                        Delete
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              : ""}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
