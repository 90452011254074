import React, { useEffect, useState } from "react";
import "../Pages/Home/Home.css";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addProjectType, addUserName } from "../../Redux/Data/Action";
import Cookies from "js-cookie";

export const ProjectType = () => {
  const [projType, setProjType] = useState();
  const [userName, setUserName] = useState();
  const dispatch = useDispatch();

  const store = useSelector((store) => store);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const project = async () => {
      try {
        const resp = await axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/api/project/projectTypes/get-project-type`,
        });
        setProjType(resp.data);
      } catch (error) {
        console.log("error");
      }
    };
    project();
  }, []);

  useEffect(() => {
    setUserName(Cookies.get("userName"));
  }, [Cookies.get("userName")]);

  const projectType1Handler = (e, item) => {
    if (item) {
      dispatch(addProjectType(item.toLowerCase()));
      dispatch(addUserName(userName));
    }
  };

  return (
    <>
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        spacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {projType &&
          projType.map((item) => (
            <Grid key={item._id} item sm={6} lg={3} md={4}>
              <Link
                style={{
                  color: "black",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                to="/home/department"
              >
                <div className="myDIV">
                  <Button
                    variant="contained"
                    aria-owns={open ? "mouse-over-popover" : undefined}
                    aria-haspopup="true"
                    sx={{
                      padding: "17px 17px",
                      backgroundColor: "#2d99fe",
                      fontSize: "18px",
                      fontWeight: "500",
                      display: "flex",
                      justifyContent: "space-between",

                      maxWidth: "276px",
                      color: "white",
                      textTransform: "capitalize",
                      boxShadow: "5px 6px 12px rgba(1, 2, 5, 0.3)",
                    }}
                    onClick={(e) => projectType1Handler(e, item.name)}
                    value={item.name}
                    fullWidth
                    onMouseEnter={handleClick}
                    onMouseLeave={handleClose}
                  >
                    {item.name}
                    <AddIcon
                      sx={{
                        fontSize: "30px",
                        color: "white",
                      }}
                    />
                  </Button>
                </div>
                <p className="hide">{item.description}</p>
              </Link>
            </Grid>
          ))}
      </Grid>
    </>
  );
};
