const SettingsActionType = {
  // USERS
  FETCH_USERS_START: "FETCH_USERS_START",
  FETCH_USERS_SUCCESS: "FETCH_USERS_SUCCESS",
  FETCH_USERS_FAIL: "FETCH_USERS_FAIL",
  UPDATE_USERS_START: "UPDATE_USERS_START",
  UPDATE_USERS_SUCCESS: "UPDATE_USERS_SUCCESS",
  UPDATE_USERS_FAIL: "UPDATE_USERS_FAIL",
  DELETE_USERS_START: "DELETE_USERS_START",
  DELETE_USERS_SUCCESS: "DELETE_USERS_SUCCESS",
  DELETE_USERS_FAIL: "DELETE_USERS_FAIL",

  // DEPARTMENTS
  FETCH_DEPARTMENT_START: "FETCH_DEPARTMENT_START",
  FETCH_DEPARTMENT_SUCCESS: "FETCH_DEPARTMENT_SUCCESS",
  FETCH_DEPARTMENT_FAIL: "FETCH_DEPARTMENT_FAIL",
  ADD_DEPARTMENT_START: "ADD_DEPARTMENT_START",
  ADD_DEPARTMENT_SUCCESS: "ADD_DEPARTMENT_SUCCESS",
  ADD_DEPARTMENT_FAIL: "ADD_DEPARTMENT_FAIL",
  DELETE_DEPARTMENT_SUCCESS: "DELETE_DEPARTMENT_SUCCESS",
  DELETE_DEPARTMENT_FAIL: "DELETE_DEPARTMENT_FAIL",
  DELETE_DEPARTMENT_START: "DELETE_DEPARTMENT_START",

  //  SERVICE

  FETCH_SERVICE_START: "FETCH_SERVICE_START",
  FETCH_SERVICE_SUCCESS: "FETCH_SERVICE_SUCCESS",
  FETCH_SERVICE_FAIL: "FETCH_SERVICE_FAIL",
  ADD_SERVICE_START: "ADD_SERVICE_START",
  ADD_SERVICE_SUCCESS: "ADD_SERVICE_SUCCESS",
  ADD_SERVICE_FAIL: "ADD_SERVICE_FAIL",
  DELETE_SERVICE_SUCCESS: "DELETE_SERVICE_SUCCESS",
  DELETE_SERVICE_FAIL: "DELETE_SERVICE_FAIL",
  DELETE_SERVICE_START: "DELETE_SERVICE_START",

  // DESIGNATION
  DELETE_DESIGNATION_SUCCESS: "DELETE_DESIGNATION_SUCCESS",
  DELETE_DESIGNATION_FAIL: "DELETE_DESIGNATION_FAIL",
  DELETE_DESIGNATION_START: "DELETE_DESIGNATION_START",
  FETCH_DESIGNATION_START: "FETCH_DESIGNATION_START",
  FETCH_DESIGNATION_SUCCESS: "FETCH_DESIGNATION_SUCCESS",
  FETCH_DESIGNATION_FAIL: "FETCH_DESIGNATION_FAIL",
  ADD_DESIGNATION_START: "ADD_DESIGNATION_START",
  ADD_DESIGNATION_SUCCESS: "ADD_DESIGNATION_SUCCESS",
  ADD_DESIGNATION_FAIL: "ADD_DESIGNATION_FAIL",
  DELETE_DESIGNATION_START: "DELETE_DESIGNATION_START",
  DELETE_DESIGNATION_SUCCESS: "DELETE_DESIGNATION_SUCCESS",
  DELETE_DESIGNATION_FAIL: "DELETE_DESIGNATION_FAIL",
  UPDATE_DESIGNATION_START: "UPDATE_DESIGNATION_START",
  UPDATE_DESIGNATION_SUCCESS: "UPDATE_DESIGNATION_SUCCESS",
  UPDATE_DESIGNATION_FAIL: "UPDATE_DESIGNATION_FAIL",
  BULK_ADD_DESIGNATION_START: " BULK_ADD_DESIGNATION_START",
  BULK_ADD_DESIGNATION_SUCCESS: " BULK_ADD_DESIGNATION_SUCCESS",
  BULK_ADD_DESIGNATION_FAIL: " BULK_ADD_DESIGNATION_FAIL",

  //  GENERIC_DESIGNATION
  FETCH_GENERIC_DESIGNATION_START: "FETCH_GENERIC_DESIGNATION_START",
  FETCH_GENERIC_DESIGNATION_SUCCESS: "FETCH_GENERIC_DESIGNATION_SUCCESS",
  FETCH_GENERIC_DESIGNATION_FAIL: "FETCH_GENERIC_DESIGNATION_FAIL",
  ADD_GENERIC_DESIGNATION_START: "ADD_GENERIC_DESIGNATION_START",
  ADD_GENERIC_DESIGNATION_SUCCESS: "ADD_GENERIC_DESIGNATION_SUCCESS",
  ADD_GENERIC_DESIGNATION_FAIL: "ADD_GENERIC_DESIGNATION_FAIL",
  DELETE_GENERIC_DESIGNATION_START: "DELETE_GENERIC_DESIGNATION_START",
  DELETE_GENERIC_DESIGNATION_SUCCESS: "DELETE_GENERIC_DESIGNATION_SUCCESS",
  DELETE_GENERIC_DESIGNATION_FAIL: "DELETE_GENERIC_DESIGNATION_FAIL",
  SET_GENERIC_DESIGNATION_LABEL: "SET_GENERIC_DESIGNATION_LABEL",

  //  OverHead
  FETCH_OVERHEAD_START: "FETCH_OVERHEAD_START",
  FETCH_OVERHEAD_SUCCESS: "FETCH_OVERHEAD_SUCCESS",
  FETCH_OVERHEAD_FAIL: "FETCH_OVERHEAD_FAIL",
  UPDATE_OVERHEAD_START: "UPDATE_OVERHEAD_START",
  UPDATE_OVERHEAD_SUCCESS: "UPDATE_OVERHEAD_SUCCESS",
  UPDATE_OVERHEAD_FAIL: "UPDATE_OVERHEAD_FAIL",

  // Add Team
  ADD_TEAM_START: "ADD_TEAM_START",
  ADD_TEAM_SUCCESS: "ADD_TEAM_SUCCESS",
  ADD_TEAM_FAIL: "ADD_TEAM_FAIL",

  //Get Sold Projects
  SOLD_PROJECTS_START: "SOLD_PROJECTS_START",
  SOLD_PROJECTS_SUCCESS: "SOLD_PROJECTS_SUCCESS",
  SOLD_PROJECTS_FAIL: "SOLD_PROJECTS_FAIL",

  //Export Single Project
  EXPORT_SINGLE_PROJECT_START: "EXPORT_SINGLE_PROJECT_START",
  EXPORT_SINGLE_PROJECT_SUCCESS: "EXPORT_SINGLE_PROJECT_SUCCESS",
  EXPORT_SINGLE_PROJECT_FAIL: "EXPORT_SINGLE_PROJECT_FAIL",

  //Export all Projects
  EXPORT_ALL_PROJECT_START: "EXPORT_ALL_PROJECT_SUCCESS",
  EXPORT_ALL_PROJECT_SUCCESS: "EXPORT_ALL_PROJECT_SUCCESS",
  EXPORT_ALL_PROJECT_FAIL: "EXPORT_ALL_PROJECT_SUCCESS",
};
export default SettingsActionType;
