import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Notify } from "../UI/Notify";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import logo from "../../assets/logo.png";
import { useDispatch } from "react-redux";
import { setToken } from "../../Redux/Data/Action";

export const Auth = () => {
  const history = useHistory();
  const location = useLocation();
  const search = useLocation().search;
  const code = new URLSearchParams(search).get("code");
  const dispatch = useDispatch();
  Cookies.set("code", JSON.stringify(code));

  useEffect(() => {
    if (code) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/auth/google/get-profile`, {
          code: code,
        })
        .then((response) => {
          if (response.data.token) {
            Cookies.set("token", response.data.token);
            Cookies.set("userName", response.data.user.name);
            Cookies.set("_id", response.data.user._id);
            Cookies.set("role", response.data.user.role);
            Cookies.set("isFirstLogin", true);
            history.push("/dashboard", { state: response.data.token });
            dispatch(setToken(response.data.token));
          }
        })
        .catch((error) => {
          history.push("/login");
        });
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100vw",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "#f2f3f7",
        }}
      >
        <Box sx={{ width: "20%" }} mb="10px">
          <img src={logo} alt="" sx={{ width: "50%", marginBottom: "20px" }} />
        </Box>

        <Box sx={{ width: "20%" }} mt="10px">
          <LinearProgress />
        </Box>
      </Box>
    </>
  );
};
