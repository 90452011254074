import React, { useState } from "react";
import "./Sidebar.css";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import SystemUpdateAltOutlinedIcon from "@mui/icons-material/SystemUpdateAltOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import ShareIcon from "@mui/icons-material/Share";
import { useDispatch } from "react-redux";
import {
  fetchArchivedProjects,
  fetchStarredProjects,
  fetchTrashedProjects,
  getOwnerProjectByPagination,
  setCurrentPageHandler,
  setSearchNewQuery,
} from "../../Redux/Projects/Action";

export const Sidebar = () => {
  const location = useLocation();
  const [role, setRole] = useState(Cookies.get("role"));

  const dispatch = useDispatch();

  const handleClick = (type) => {
    localStorage.setItem("item-per-page", 10);
    if (type === "home") {
      dispatch(setCurrentPageHandler(1, "home"));
      dispatch(getOwnerProjectByPagination(10, 1));
    } else if (type === "starred") {
      dispatch(setCurrentPageHandler(1, "starred"));
      dispatch(fetchStarredProjects(10, 1));
    } else if (type === "archived") {
      dispatch(setCurrentPageHandler(1, "archived"));
      dispatch(fetchArchivedProjects(10, 1));
    } else if (type === "trash") {
      dispatch(setCurrentPageHandler(1, "trash"));
      dispatch(fetchTrashedProjects(10, 1));
    }

    dispatch(setSearchNewQuery(""));
  };

  return (
    <>
      <div className="container">
        <div className="sidebar">
          <ul>
            <li
              className={
                location.pathname.includes("/dashboard") ? "active_link" : ""
              }
            >
              <Typography variant="body1" fontWeight="300">
                <Link to="/dashboard" onClick={() => handleClick("home")}>
                  <span>
                    <HomeOutlinedIcon
                      fontSize="medium"
                      sx={{ color: "white" }}
                    />
                  </span>
                  Home
                </Link>
              </Typography>
            </li>

            <li
              className={
                location.pathname.includes("/shared-with-me")
                  ? "active_link"
                  : ""
              }
            >
              <Typography variant="body1">
                <Link to="/shared-with-me" onClick={() => handleClick()}>
                  <span>
                    <ShareIcon fontSize="medium" sx={{ color: "white" }} />
                  </span>
                  Shared With Me
                </Link>
              </Typography>
            </li>
            <li
              className={
                location.pathname.includes("/starred") ? "active_link" : ""
              }
            >
              <Typography variant="body1">
                <Link to="/starred" onClick={() => handleClick("starred")}>
                  <span>
                    <StarBorderOutlinedIcon
                      fontSize="medium"
                      sx={{ color: "white" }}
                    />
                  </span>
                  Starred
                </Link>
              </Typography>
            </li>

            {/* <li
              className={
                location.pathname.includes("/draft") ? "active_link" : ""
              }
            >
              <Typography variant="body1">
                <Link to="/draft">
                  <span>
                    <InsertDriveFileOutlinedIcon
                      fontSize="medium"
                      sx={{ color: "white" }}
                    />
                  </span>
                  Draft
                </Link>
              </Typography>
            </li> */}
            <li
              className={
                location.pathname.includes("/archived") ? "active_link" : ""
              }
            >
              <Typography variant="body1">
                <Link to="/archived" onClick={() => handleClick("archived")}>
                  <span>
                    <SystemUpdateAltOutlinedIcon
                      fontSize="medium"
                      sx={{ color: "white" }}
                    />
                  </span>
                  Archived
                </Link>
              </Typography>
            </li>

            <li
              className={
                location.pathname.includes("/trash") ? "active_link" : ""
              }
            >
              <Typography variant="body1">
                <Link to="/trash" onClick={() => handleClick("trash")}>
                  <span>
                    <DeleteOutlinedIcon
                      fontSize="medium"
                      sx={{ color: "white" }}
                    />
                  </span>
                  Trash
                </Link>
              </Typography>
            </li>
            {role === "Admin" ? (
              <li
                className={
                  location.pathname.includes("/settings") ? "active_link" : ""
                }
              >
                <Typography variant="body1">
                  <Link to="/settings" onClick={() => handleClick()}>
                    <span>
                      <SettingsOutlinedIcon
                        fontSize="medium"
                        sx={{ color: "white" }}
                      />
                    </span>
                    Settings
                  </Link>
                </Typography>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      </div>
    </>
  );
};
