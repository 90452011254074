// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:500);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".google-btn {\n  width: 184px;\n  height: 42px;\n  background-color: #4285f4;\n  border-radius: 2px;\n  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);\n}\n.google-icon-wrapper {\n  position: absolute;\n  margin-top: 1px;\n  margin-left: 1px;\n  width: 40px;\n  height: 40px;\n  border-radius: 2px;\n  background-color: #fff;\n}\n.google-icon {\n  position: absolute;\n  margin-top: 11px;\n  margin-left: 11px;\n  width: 18px;\n  height: 18px;\n}\n.btn-text {\n  float: right;\n  margin: 11px 11px 0 0;\n  color: #fff;\n  font-size: 14px;\n  letter-spacing: 0.2px;\n  font-family: \"Roboto\";\n}\n.btn-text:hover {\n  box-shadow: 0 0 6px #4285f4;\n}\n.btn-text:active {\n  background: #1669f2;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Auth/Login.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,2CAA2C;AAC7C;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;AACxB;AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,WAAW;EACX,YAAY;AACd;AACA;EACE,YAAY;EACZ,qBAAqB;EACrB,WAAW;EACX,eAAe;EACf,qBAAqB;EACrB,qBAAqB;AACvB;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".google-btn {\n  width: 184px;\n  height: 42px;\n  background-color: #4285f4;\n  border-radius: 2px;\n  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);\n}\n.google-icon-wrapper {\n  position: absolute;\n  margin-top: 1px;\n  margin-left: 1px;\n  width: 40px;\n  height: 40px;\n  border-radius: 2px;\n  background-color: #fff;\n}\n.google-icon {\n  position: absolute;\n  margin-top: 11px;\n  margin-left: 11px;\n  width: 18px;\n  height: 18px;\n}\n.btn-text {\n  float: right;\n  margin: 11px 11px 0 0;\n  color: #fff;\n  font-size: 14px;\n  letter-spacing: 0.2px;\n  font-family: \"Roboto\";\n}\n.btn-text:hover {\n  box-shadow: 0 0 6px #4285f4;\n}\n.btn-text:active {\n  background: #1669f2;\n}\n\n@import url(https://fonts.googleapis.com/css?family=Roboto:500);\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
