import React, { useEffect, useState } from "react";

import {
  Typography,
  Button,
  TextField,
  Autocomplete,
  Input,
} from "@mui/material";
import "./Settings.css";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  addNewDepartment,
  deleteDepartment,
  requestAllDepartment,
} from "../../../Redux/Settings/Action";
import { useDispatch, useSelector } from "react-redux";
import { Notify } from "../../UI/Notify";

export const AddDepartment = () => {
  const [departmentValue, setDepartmentValue] = useState("");
  const [name, setName] = useState("");
  const [deleteValue, setDeleteValue] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");

  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const logoHandler = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const adddepartmentHandler = () => {
    const fd = new FormData();
    fd.append("name", departmentValue);
    fd.append("logo", selectedImage);
    if (departmentValue !== "") {
      dispatch(addNewDepartment(fd));
      setNotification({
        isOpen: true,
        message: "Successfully Added Department",
        type: "success",
      });
      setDepartmentValue("");
    } else {
      setNotification({
        isOpen: true,
        message: "Department Field is empty",
        type: "error",
      });
    }
  };

  const deleteHandler = () => {
    if (deleteValue !== "") {
      dispatch(deleteDepartment(deleteValue));
      setTimeout(() => {
        dispatch(requestAllDepartment());
      }, 500);

      setNotification({
        isOpen: true,
        message: "Permanently Deleted Department",
        type: "error",
      });
      setDeleteValue(null);
    } else {
      setNotification({
        isOpen: true,
        message: "Department Field is empty",
        type: "error",
      });
    }
  };

  return (
    <>
      <Notify notification={notification} setNotification={setNotification} />
      <div className="set__departments">
        <Typography
          className="set__headings"
          sx={{ color: "#5E5873" }}
          variant="h6"
        >
          Add New Department :
        </Typography>
        <TextField
          id="outlined-basic"
          label="Add Department"
          variant="outlined"
          value={departmentValue}
          onChange={(e) => setDepartmentValue(e.target.value)}
          fullWidth
          required
        />
        {/* <label htmlFor="contained-button-file">
          <Input
            accept="image/*"
            id="contained-button-file"
            multiple
            onChange={logoHandler}
            sx={{ marginTop: "10px" }}
            type="file"
          />
        </label> */}

        <div className="set__button">
          <Button
            variant="contained"
            color="success"
            sx={{
              color: "white",
            }}
            onClick={adddepartmentHandler}
            startIcon={<AddCircleOutlinedIcon />}
          >
            Add
          </Button>
        </div>
        <Typography
          className="set__headings"
          sx={{ color: "#5E5873" }}
          variant="h6"
        >
          Delete Department :
        </Typography>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          className="set_autocomplete"
          value={deleteValue}
          onChange={(e, value) => setDeleteValue(value)}
          getOptionLabel={(option) => option.name}
          options={store?.settings?.newDepartments}
          renderInput={(params) => (
            <TextField {...params} label="Select Department To Delete *" />
          )}
          fullWidth
        />
        <div className="set__button">
          <Button
            variant="contained"
            color="danger"
            sx={{
              color: "white",
            }}
            onClick={deleteHandler}
            startIcon={<DeleteForeverIcon />}
          >
            Delete
          </Button>
        </div>
      </div>
    </>
  );
};
