const ProjectActionTypes = {
  SET_PRICING_DATA: "SET_PRICING_DATA",
  SET_PRICING_DATA_DEPARTMENTS: "SET_PRICING_DATA_DEPARTMENTS",
  SET_PRICING_DATA_AD_ONS: "SET_PRICING_DATA_AD_ONS",
  FETCH_PROJECT_START: "FETCH_PROJECT_START",
  FETCH_PROJECT_SUCCESS: "FETCH_PROJECT_SUCCESS",
  FETCH_PROJECT_FAIL: "FETCH_PROJECT_FAIL",
  UPDATE_PROJECT_SUCCESS: "UPDATE_PROJECT_SUCCESS",
  UPDATE_PROJECT_FAIL: "UPDATE_PROJECT_FAIL",
  UPDATE_PROJECT_START: "UPDATE_PROJECT_START",
  DELETE_PROJECT_SUCCESS: "DELETE_PROJECT_SUCCESS",
  DELETE_PROJECT_FAIL: "DELETE_PROJECT_FAIL",
  DELETE_PROJECT_START: "DELETE_PROJECT_START",
  ADD_PROJECT_START: "ADD_PROJECT_START",
  ADD_PROJECT_SUCCESS: "ADD_PROJECT_SUCCESS",
  ADD_PROJECT_FAIL: "ADD_PROJECT_FAIL",
  ADD_PRICING_START: "ADD_PRICING_START",
  ADD_PRICING_SUCCESS: "ADD_PRICING_SUCCESS",
  UPDATE_PRICING_FAIL: "UPDATE_PRICING_FAIL",
  UPDATE_PRICING_START: "UPDATE_PRICING_START",
  UPDATE_PRICING_SUCCESS: "UPDATE_PRICING_SUCCESS",
  ADD_PRICING_FAIL: "ADD_PRICING_FAIL",
  CLEAR_PROJECT: "CLEAR_PROJECT",

  SET_ALL_PROJECTS: "SET_ALL_PROJECTS",
  SET_SEARCH_DATA: "SET_SEARCH_DATA",
  UPDATE_PROJECT_PRICING_SUCCESS: "UPDATE_PROJECT_PRICING_SUCCESS",
  UPDATE_PROJECT_PRICING_FAIL: "UPDATE_PROJECT_PRICING_FAIL",
  UPDATE_PROJECT_PRICING_START: "UPDATE_PROJECT_PRICING_START",
  SET_VIEW_MODE: "SET_VIEW_MODE",
  ALL_PRICING_OF_PROJECT: "ALL_PRICING_OF_PROJECT",

  // clone pricing

  CLONE_PRICING_FAIL: "CLONE_PRICING_FAIL",
  CLONE_PRICING_START: "CLONE_PRICING_START",
  CLONE_PRICING_SUCCESS: "CLONE_PRICING_SUCCESS",

  // Project Logo
  ADD_PROJECT_LOGO_START: "ADD_PROJECT_LOGO_START",
  ADD_PROJECT_LOGO_SUCCESS: "ADD_PROJECT_LOGO_SUCCESS",
  ADD_PROJECT_LOGO_FAIL: "ADD_PROJECT_LOGO_FAIL",

  // resource
  ADD_RESOURCES_SUCCESS: "ADD_RESOURCES_SUCCESS",
  ADD_RESOURCES_FAIL: "ADD_RESOURCES_FAIL",
  ADD_RESOURCES_START: "ADD_RESOURCES_START",
  UPDATE_RESOURCES_SUCCESS: "UPDATE_RESOURCES_SUCCESS",
  UPDATE_RESOURCES_FAIL: "UPDATE_RESOURCES_FAIL",
  UPDATE_RESOURCES_START: "UPDATE_RESOURCES_START",
  DELETE_RESOURCES_SUCCESS: "DELETE_RESOURCES_SUCCESS",
  DELETE_RESOURCES_FAIL: "DELETE_RESOURCES_FAIL",
  DELETE_RESOURCES_START: "DELETE_RESOURCES_START",

  // Addon
  ADD_ADDONS_SUCCESS: "ADD_ADDONS_SUCCESS",
  ADD_ADDONS_FAIL: "ADD_ADDONS_FAIL",
  ADD_ADDONS_START: "ADD_ADDONS_START",
  UPDATE_ADDONS_SUCCESS: "UPDATE_ADDONS_SUCCESS",
  UPDATE_ADDONS_FAIL: "UPDATE_ADDONS_FAIL",
  UPDATE_ADDONS_START: "UPDATE_ADDONS_START",
  DELETE_ADDONS_SUCCESS: "DELETE_ADDONS_SUCCESS",
  DELETE_ADDONS_FAIL: "DELETE_ADDONS_FAIL",
  DELETE_ADDONS_START: "DELETE_ADDONS_START",

  // profit
  UPDATE_PROFIT_SUCCESS: "UPDATE_PROFIT_SUCCESS",
  UPDATE_PROFIT_FAIL: "UPDATE_PROFIT_FAIL",
  UPDATE_PROFIT_START: "UPDATE_PROFIT_START",

  // Discount
  ADD_DISCOUNT_SUCCESS: "ADD_DISCOUNT_SUCCESS",
  ADD_DISCOUNT_FAIL: "ADD_DISCOUNT_FAIL",
  ADD_DISCOUNT_START: "ADD_DISCOUNT_START",

  // Collaborators
  ADD_COLLABORATORS_SUCCESS: "ADD_COLLABORATORS_SUCCESS",
  ADD_COLLABORATORS_FAIL: "ADD_COLLABORATORS_FAIL",
  ADD_COLLABORATORS_START: "ADD_COLLABORATORS_START",
  FETCH_COLLABORATORS_START: "FETCH_COLLABORATORS_START",
  FETCH_COLLABORATORS_SUCCESS: "FETCH_COLLABORATORS_SUCCESS",
  FETCH_COLLABORATORS_FAIL: "FETCH_COLLABORATORS_FAIL",
  UPDATE_COLLABORATORS_SUCCESS: "UPDATE_COLLABORATORS_SUCCESS",
  UPDATE_COLLABORATORS_FAIL: "UPDATE_COLLABORATORS_FAIL",
  UPDATE_COLLABORATORS_START: "UPDATE_COLLABORATORS_START",
  DELETE_COLLABORATORS_SUCCESS: "DELETE_COLLABORATORS_SUCCESS",
  DELETE_COLLABORATORS_FAIL: "DELETE_COLLABORATORS_FAIL",
  DELETE_COLLABORATORS_START: "DELETE_COLLABORATORS_START",
  CLEAR_COLLABORATORS: "CLEAR_COLLABORATORS",
  UPDATE_TEAM_COLLABORATORS_SUCCESS: "UPDATE_TEAM_COLLABORATORS_SUCCESS",
  UPDATE_TEAM_COLLABORATORS_FAIL: "UPDATE_TEAM_COLLABORATORS_FAIL",
  UPDATE_TEAM_COLLABORATORS_START: "UPDATE_TEAM_COLLABORATORS_START",

  // Pricing Versions
  FETCH_VERSIONS_START: "FETCH_VERSIONS_START",
  FETCH_VERSIONS_SUCCESS: "FETCH_VERSIONS_SUCCESS",
  FETCH_VERSIONS_FAIL: "FETCH_VERSIONS_FAIL",
  SELECT_VERSIONS: "SELECT_VERSIONS",

  //  Shared Projects
  FETCH_SHARED_PROJECT_START: "FETCH_SHARED_PROJECT_START",
  FETCH_SHARED_PROJECT_SUCCESS: "FETCH_SHARED_PROJECT_SUCCESS",
  FETCH_SHARED_PROJECT_FAIL: "FETCH_SHARED_PROJECT_FAIL",

  //   GET_PROJECT_BY_OWNER
  GET_PROJECT_BY_OWNER_START: "GET_PROJECT_BY_OWNER_START",
  GET_PROJECT_BY_OWNER_SUCCESS: "GET_PROJECT_BY_OWNER_SUCCESS",
  GET_PROJECT_BY_OWNER_FAIL: "GET_PROJECT_BY_OWNER_FAIL",

  //GET_PROJECT_BY_OWNER_PAGINATION
  GET_PROJECT_BY_OWNER_PAGINATION_START:
    "GET_PROJECT_BY_OWNER_PAGINATION_START",
  GET_PROJECT_BY_OWNER_PAGINATION_SUCCESS:
    "GET_PROJECT_BY_OWNER_PAGINATION_SUCCESS",
  GET_PROJECT_BY_OWNER_PAGINATION_FAIL: "GET_PROJECT_BY_OWNER_PAGINATION_FAIL",

  //Archived Projects
  FETCH_ARCHIVED_PROJECTS_SUCCESS: "FETCH_ARCHIVED_PROJECTS_SUCCESS",
  FETCH_ARCHIVED_PROJECTS_FAILURE: "FETCH_ARCHIVED_PROJECTS_FAILURE",

  //Trashed Projects
  FETCH_TRASHED_PROJECTS_SUCCESS: "FETCH_TRASHED_PROJECTS_SUCCESS",
  FETCH_TRASHED_PROJECTS_FAILURE: "FETCH_TRASHED_PROJECTS_FAILURE",

  //Starred Projects
  FETCH_STARRED_PROJECTS_SUCCESS: "FETCH_STARRED_PROJECTS_SUCCESS",
  FETCH_STARRED_PROJECTS_FAILURE: "FETCH_STARRED_PROJECTS_FAILURE",

  //Search Data
  FETCH_SEARCH_DATA_SUCCESS: "FETCH_SEARCH_DATA_SUCCESS",
  FETCH_SEARCH_DATA_FAILURE: "FETCH_SEARCH_DATA_FAILURE",

  //No search data is found
  NO_SEARCH_RESULTS: "NO_SEARCH_RESULTS",

  SET_TOTAL_COUNT: "SET_TOTAL_COUNT",

  SET_SEARCH_QUERY: "SET_SEARCH_QUERY",

  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",

  //Fetching version for collaborators
  SET_PROJECT_ID: "SET_PROJECT_ID",
  CLEAR_SET_PROJECT_ID: "CLEAR_SET_PROJECT_ID",

  FETCH_TOTAL_VERSIONS_START: "FETCH_TOTAL_VERSIONS_START",
  FETCH_TOTAL_VERSIONS_SUCCESS: "FETCH_TOTAL_VERSIONS_SUCCESS",
  FETCH_TOTAL_VERSIONS_FAIL: "FETCH_TOTAL_VERSIONS_FAIL",

  SET_SELECTED_VERSION: "SET_SELECTED_VERSION",

  // All users for "Add Collaborators"
  FETCH_USERS_To_COLLAB_START: "FETCH_USERS_To_COLLAB_START",
  FETCH_USERS_To_COLLAB_SUCCESS: "FETCH_USERS_To_COLLAB_SUCCESS",
  FETCH_USERS_To_COLLAB_FAIL: "FETCH_USERS_To_COLLAB_FAIL",

  //Mark As Sold
  MARK_AS_SOLD_START: "MARK_AS_SOLD_START",
  MARK_AS_SOLD_SUCCESS: "MARK_AS_SOLD_SUCCESS",
  MARK_AS_SOLD_FAIL: "MARK_AS_SOLD_FAIL",

  //All Versions of an Project
  ALL_VERSIONS_OF_PROJECTS: "ALL_VERSIONS_OF_PROJECTS",
  CLEAR_ALL_VERSIONS: "CLEAR_ALL_VERSIONS",

  //Versioning Notes to a particular version
  UPDATE_VERSION_COMMENT_START: "UPDATE_VERSION_COMMENT_START",
  UPDATE_VERSION_COMMENT_SUCCESS: "UPDATE_VERSION_COMMENT_SUCCESS",
  UPDATE_VERSION_COMMENT_FAIL: "UPDATE_VERSION_COMMENT_FAIL",

  ALL_COMMENTS_OF_PROJECTS: "ALL_COMMENTS_OF_PROJECTS",

  SET_EDIT_VERSION_NOTES: "SET_EDIT_VERSION_NOTES",

  CLEAR_ALL_VERSIONS_OF_PROJECTS: "CLEAR_ALL_VERSIONS_OF_PROJECTS",

  //GeneratePDF
  GENERATE_PDF_SUCCESS: "GENERATE_PDF_SUCCESS",
  GENERATE_PDF_FAIL: "GENERATE_PDF_FAIL",
  GENERATE_PDF_START: "GENERATE_PDF_START",

  //Current Page handler
  SET_CURRENT_PAGE_HANDLER: "SET_CURRENT_PAGE_HANDLER",
};

export default ProjectActionTypes;
