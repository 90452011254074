import React, { useState } from "react";
import {
  Typography,
  Button,
  TextField,
  Autocomplete,
  InputLabel,
  Select,
} from "@mui/material";
import "./Settings.css";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewDesignation,
  bulkAddNewDesignation,
  deleteDesignation,
  requestAllDesignation,
  updateDesignation,
} from "../../../Redux/Settings/Action";
import { Notify } from "../../UI/Notify";

export const Designation = () => {
  const [departmentValue, setDepartmentValue] = useState(null);
  const [deleteDept, setDeleteDept] = useState(null);
  const [updateDept, setUpdateDept] = useState("");
  const [designationValue, setDesignationValue] = useState("");
  const [salary, setSalary] = useState(0);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [excel, setExcel] = useState("");

  const [keyValueDepartment, setkeyValueDepartment] = useState(1);
  const [keyValueGeneric, setkeyValueGeneric] = useState(10);
  const [keyValueDesignation, setkeyValueDesignation] = useState(15);

  const [button, setButton] = useState("add");
  const [genericDesValue, setGenericDesValue] = useState(null);
  const dispatch = useDispatch();
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const store = useSelector((store) => store);

  const addDesignationhandler = () => {
    if (
      departmentValue !== "" &&
      genericDesValue !== "" &&
      name !== "" &&
      salary !== ""
    ) {
      const AddData = {
        name: name,
        description: description,
        genericDesignationName: genericDesValue.name,
        genericDesignationId: genericDesValue._id,
        departmentName: departmentValue.name,
        departmentId: departmentValue._id,
        salary: Number(salary),
        // overHead: Number(overHead),
      };
      dispatch(addNewDesignation(AddData));
      setNotification({
        isOpen: true,
        message: "Successfully Added Designation",
        type: "success",
      });

      setName("");
      setDescription("");
      setDepartmentValue(null);
      setGenericDesValue(null);
      setSalary("");
    } else {
      setNotification({
        isOpen: true,
        message: "Designation Fields are incomplete",
        type: "error",
      });
    }
  };

  const deleteHandler = () => {
    dispatch(deleteDesignation(deleteDept));
    setTimeout(() => {
      dispatch(requestAllDesignation());
    }, 1000);
    setDeleteDept(null);
    setNotification({
      isOpen: true,
      message: "Designation Permanently Deleted",
      type: "error",
    });
  };

  const excelHandler = (e) => {
    const file = e.target.files[0];
    setExcel(file);
  };

  const importHandler = async () => {
    const fd = new FormData();
    fd.append("schbangDatas", excel);
    if (excel !== "") {
      await dispatch(bulkAddNewDesignation(fd));
      await dispatch(requestAllDesignation());

      setNotification({
        isOpen: true,
        message: "Successfully Added Designations",
        type: "success",
      });
      setExcel("");
    } else {
      setNotification({
        isOpen: true,
        message: "Designations Field is empty",
        type: "error",
      });
    }
  };

  const updateHandler = () => {
    if (
      departmentValue !== "" &&
      genericDesValue !== "" &&
      updateDept !== "" &&
      salary !== ""
    ) {
      const updateData = {
        name: updateDept.name,
        description: description,
        genericDesignationName: genericDesValue.name,
        genericDesignationId: genericDesValue._id,
        departmentName: departmentValue.name,
        departmentId: departmentValue._id,
        salary: Number(salary),
        // overHead: Number(overHead),
      };
      dispatch(updateDesignation(updateDept._id, updateData));
      setNotification({
        isOpen: true,
        message: "Successfully Updated Designation",
        type: "success",
      });

      setName("");
      setDescription("");
      setDepartmentValue(null);
      setGenericDesValue(null);
      setSalary("");
      setkeyValueDepartment(keyValueDepartment + 1);
      setkeyValueGeneric(keyValueGeneric + 1);
      setkeyValueDesignation(keyValueDesignation + 1);
    } else {
      setNotification({
        isOpen: true,
        message: "Designation Fields are incomplete",
        type: "error",
      });
    }
  };

  const updateFieldsHandler = (value) => {
    setUpdateDept(value);
    setGenericDesValue(value.genericDesignationName);
    setDepartmentValue(value.departmentName);
    setSalary(value.salary.monthly);
  };

  React.useEffect(() => {}, [name]);

  const salaryChange = (e) => {
    let enteredSalary = e.target.value;

    enteredSalary = enteredSalary.replace(/[^0-9.]/g, "");

    let salaryValue =
      enteredSalary !== "" && parseFloat(enteredSalary) > 0
        ? enteredSalary
        : "";

    setSalary(salaryValue);
  };

  return (
    <>
      <Notify notification={notification} setNotification={setNotification} />
      <div className="set__departments">
        <div className="set__nav">
          <Button
            sx={{ marginRight: "7px" }}
            onClick={() => setButton("add")}
            variant="outlined"
          >
            Add Designation
          </Button>

          <Button
            sx={{ marginLeft: "7px" }}
            onClick={() => setButton("delete")}
            variant="outlined"
            color="error"
          >
            Delete Designation
          </Button>
          <Button
            sx={{ marginLeft: "7px" }}
            onClick={() => setButton("update")}
            variant="outlined"
            color="warning"
          >
            Update Designation
          </Button>
        </div>
        <div className="set__nav">
          <Button
            sx={{ marginTop: "10px" }}
            onClick={() => setButton("bulkadd")}
            variant="outlined"
          >
            Bulk Upload Designation
          </Button>
        </div>
        {button === "update" ? (
          <>
            <Typography
              className="set__headings"
              sx={{ color: "#5E5873" }}
              variant="h6"
            >
              Update Designation :
            </Typography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              onChange={(e, value) => updateFieldsHandler(value)}
              key={keyValueDesignation}
              getOptionLabel={(option) => option.name}
              options={store?.settings?.newDesignation}
              className="set_autocomplete"
              renderInput={(params) => (
                <TextField {...params} label="Select Designation" />
              )}
              fullWidth
            />
            <TextField
              id="standard-multiline-static"
              label="Description"
              variant="outlined"
              value={description}
              multiline
              rows={2}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              sx={{ marginBottom: "15px" }}
            />

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              // value={departmentValue}
              key={keyValueDepartment}
              onChange={(e, value) => setDepartmentValue(value)}
              getOptionLabel={(option) => option.name}
              options={store?.settings?.newDepartments || ""}
              className="set_autocomplete"
              renderInput={(params) => (
                <TextField {...params} label="Select Department" />
              )}
              fullWidth
            />
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              // value={genericDesValue}
              onChange={(e, value) => setGenericDesValue(value)}
              key={keyValueGeneric}
              getOptionLabel={(option) => option.name}
              options={
                store?.settings?.newGenericDesignation.filter(
                  (item) =>
                    departmentValue && item.departmentId === departmentValue._id
                ) || ""
              }
              className="set_autocomplete"
              renderInput={(params) => (
                <TextField {...params} label="Select Generic Designation" />
              )}
              fullWidth
            />
            <TextField
              id="outlined-basic"
              label="Salary"
              value={salary}
              type="number"
              onChange={salaryChange}
              fullWidth
              sx={{ marginBottom: "15px" }}
              variant="outlined"
            />
            <div className="set__button">
              <Button
                variant="contained"
                color="warning"
                sx={{
                  color: "white",
                }}
                onClick={updateHandler}
                startIcon={<DeleteForeverIcon />}
              >
                Update
              </Button>
            </div>
          </>
        ) : (
          <div>
            {button === "add" && (
              <>
                <Typography
                  className="set__headings"
                  sx={{ color: "#5E5873" }}
                  variant="h6"
                >
                  Add New Designation :
                </Typography>
                <TextField
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                  sx={{ marginBottom: "15px" }}
                />
                <TextField
                  id="standard-multiline-static"
                  label="Description"
                  variant="outlined"
                  value={description}
                  multiline
                  rows={2}
                  onChange={(e) => setDescription(e.target.value)}
                  fullWidth
                  sx={{ marginBottom: "15px" }}
                />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={departmentValue}
                  onChange={(e, value) => setDepartmentValue(value)}
                  getOptionLabel={(option) => option.name}
                  options={store?.settings?.newDepartments || ""}
                  className="set_autocomplete"
                  renderInput={(params) => (
                    <TextField {...params} label="Select Department" />
                  )}
                  fullWidth
                />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={genericDesValue}
                  onChange={(e, value) => setGenericDesValue(value)}
                  getOptionLabel={(option) => option.name}
                  options={
                    store?.settings?.newGenericDesignation.filter(
                      (item) =>
                        departmentValue &&
                        item.departmentId === departmentValue._id
                    ) || ""
                  }
                  className="set_autocomplete"
                  renderInput={(params) => (
                    <TextField {...params} label="Select Generic Designation" />
                  )}
                  fullWidth
                />
                <TextField
                  id="outlined-basic"
                  label="Salary"
                  value={salary === 0 ? "" : salary}
                  type="number"
                  onChange={salaryChange}
                  fullWidth
                  sx={{ marginBottom: "15px" }}
                  variant="outlined"
                />

                <div className="set__button">
                  <Button
                    variant="contained"
                    color="success"
                    sx={{
                      color: "white",
                    }}
                    onClick={addDesignationhandler}
                    startIcon={<AddCircleOutlinedIcon />}
                  >
                    Add
                  </Button>
                </div>
              </>
            )}

            {button === "delete" && (
              <>
                <Typography
                  className="set__headings"
                  sx={{ color: "#5E5873" }}
                  variant="h6"
                >
                  Delete Designation :
                </Typography>

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={deleteDept}
                  onChange={(e, value) => setDeleteDept(value)}
                  getOptionLabel={(option) => option.name}
                  options={store?.settings?.newDesignation}
                  className="set_autocomplete"
                  renderInput={(params) => (
                    <TextField {...params} label="Select Designation" />
                  )}
                  fullWidth
                />

                <div className="set__button">
                  <Button
                    variant="contained"
                    color="danger"
                    sx={{
                      color: "white",
                    }}
                    onClick={deleteHandler}
                    startIcon={<DeleteForeverIcon />}
                  >
                    Delete
                  </Button>
                </div>
              </>
            )}
            {button === "bulkadd" && (
              <>
                <Typography
                  className="set__headings"
                  sx={{ color: "#5E5873" }}
                  variant="h6"
                >
                  Bulk Import Designation :
                </Typography>

                <div className="file__bg">
                  <div className="file-upload-container">
                    <form>
                      <input
                        type="file"
                        id="file-upload"
                        onChange={excelHandler}
                        accept=".xls,.xlsx"
                        style={{ display: "none" }}
                      />
                      <label for="file-upload" className="file-upload-button">
                        <i className="fa fa-upload"></i>
                        Choose File
                      </label>
                      <span className="file-name"></span>
                    </form>
                  </div>
                  <div>
                    {excel ? <p>{excel.name}</p> : <p>No File Selected </p>}
                  </div>
                </div>
                <div className="set__button">
                  <Button
                    variant="contained"
                    color="success"
                    sx={{
                      color: "white",
                    }}
                    onClick={importHandler}
                    startIcon={<AddCircleOutlinedIcon />}
                  >
                    Import
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};
