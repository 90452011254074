import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "../Collaborators/Collaborators.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { Notify } from "../../UI/Notify";
import { addTeam } from "../../../Redux/Settings/Action";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 620,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const role = ["Edit", "Edit"];

export const AddTeam = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [role, setRole] = React.useState("view");
  const [users, setUsers] = React.useState([]);
  const [allUsers, setAllUsers] = React.useState([]);
  const [teamName, setTeamName] = React.useState("");
  const [notification, setNotification] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const store = useSelector((store) => store.settings.allUsers);
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const userHandler = () => {
    const collaborators = [];
    users.map((user) => {
      collaborators.push({
        collaboratorName: user,
        collaboratorRole: role,
      });
    });
    const team = {
      teamName: teamName,
      collaborators: collaborators,
    };
    if (users.length > 0 && teamName) {
      dispatch(addTeam(team));
      setNotification({
        isOpen: true,
        message: "Successfully Added Team",
        type: "success",
      });
    } else {
      setNotification({
        isOpen: true,
        message: "Collaborators or Team Name is Empty",
        type: "error",
      });
    }
  };

  React.useEffect(() => {
    if (Cookies.get("userName")) {
      const data = store.filter(
        (option) => Cookies.get("userName") !== option.name
      );

      setAllUsers(data);
    }
  }, [Cookies.get("userName")]);

  return (
    <>
      <Notify notification={notification} setNotification={setNotification} />
      <div>
        <Box sx={{ padding: "30px" }}>
          <Typography
            id="modal-modal-title"
            sx={{ m: 1, color: "#5E5873" }}
            variant="h6"
            component="h2"
          >
            Add Collaborators:
          </Typography>
          <div className="modal">
            <TextField
              variant="outlined"
              label="Team Name"
              onChange={(e) => setTeamName(e.target.value)}
              sx={{ m: 1, width: "700px" }}
            />

            <Autocomplete
              multiple
              id="tags-readOnly"
              options={allUsers.map((option) => option.name)}
              //   readOnly
              value={users}
              onChange={(event, value) => setUsers(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Add Users"
                  sx={{ m: 1, width: "700px" }}
                  placeholder="Favorites"
                />
              )}
            />
            <FormControl sx={{ m: 1, width: 700 }}>
              <Select defaultValue="view" onChange={handleChange} name="role">
                <MenuItem value={"edit"}>Edit</MenuItem>
                <MenuItem value={"view"}>View</MenuItem>
              </Select>
            </FormControl>

            <Button
              variant="contained"
              sx={{ color: "white", m: 1, marginLeft: "auto" }}
              onClick={userHandler}
            >
              Add
            </Button>
          </div>
        </Box>
      </div>
    </>
  );
};
