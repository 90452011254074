import DataActionTypes from "./DataActionType";
import axios from "axios";
import Cookies from "js-cookie";

export const requestDepartment = () => async (dispatch) => {
  dispatch({
    type: DataActionTypes.FETCH_DEPARTMENT_START,
  });
  try {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/api/department/get-department`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });
    dispatch({
      type: DataActionTypes.FETCH_DEPARTMENT_SUCCESS,
      departmentData: response.data,
      isError: false,
    });
  } catch (e) {
    dispatch({
      type: DataActionTypes.FETCH_DEPARTMENT_FAIL,
      departmentData: [],
      isError: true,
    });
  }
};

export const requestService = () => async (dispatch) => {
  dispatch({
    type: DataActionTypes.FETCH_SERVICE_START,
  });
  try {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/api/service/get-service`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: DataActionTypes.FETCH_SERVICE_SUCCESS,
      departmentData: response.data,
      isError: false,
    });
  } catch (e) {
    dispatch({
      type: DataActionTypes.FETCH_SERVICE_FAIL,
      departmentData: [],
      isError: true,
    });
  }
};

export const allDepartment = (departments) => ({
  type: DataActionTypes.SET_DEPARTMENTS_DATA,
  payload: departments,
});

export const allServices = (services) => ({
  type: DataActionTypes.SET_SERVICES_DATA,
  payload: services,
});

export const addProjectInfo = (projectInfo) => ({
  type: DataActionTypes.SET_PROJECT_INFO,
  payload: projectInfo,
});

export const addDepartment = (departments) => ({
  type: DataActionTypes.SET_DEPARTMENTS,
  payload: departments,
});

export const addServices = (services) => ({
  type: DataActionTypes.SET_DEPARTMENT_SERVICES,
  payload: services,
});

export const addProjectId = (id) => ({
  type: DataActionTypes.SET_PROJECT_ID,
  payload: id,
});
export const addNote = (note) => ({
  type: DataActionTypes.ADD_NOTE,
  payload: note,
});

export const addProjectName = (projectName) => ({
  type: DataActionTypes.SET_PROJECT_NAME,
  payload: projectName,
});

export const addClientName = (clientName) => ({
  type: DataActionTypes.SET_CLIENT_NAME,
  payload: clientName,
});

export const addProjectType = (projectTypeName) => ({
  type: DataActionTypes.ADD_PROJECT_TYPE,
  payload: projectTypeName,
});

export const addUserName = (userName) => ({
  type: DataActionTypes.ADD_USERNAME,
  payload: userName,
});

export const addSlug = (slug) => ({
  type: DataActionTypes.ADD_SLUG,
  payload: slug,
});

export const clearDepartment = () => ({
  type: DataActionTypes.CLEAR_DEPARTMENT,
  payload: null,
});

export const setSearchData = (searchData) => ({
  type: DataActionTypes.SET_SEARCH_DATA,
  payload: searchData.data,
});

export const finalUpdateProject = (Data) => ({
  type: DataActionTypes.SET_FINAL_UPDATE_PROJECT_DATA,
  payload: Data,
});
export const cloneProject = (_id) => async (dispatch) => {
  dispatch({
    type: DataActionTypes.CLONE_PROJECT_START,
  });
  try {
    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/project/clone-project/${_id}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });
    dispatch({
      type: DataActionTypes.CLONE_PROJECT_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: DataActionTypes.CLONE_PROJECT_FAIL,
      payload: e,
      isError: true,
    });
  }
};

export const setToken = (Data) => ({
  type: DataActionTypes.SET_TOKEN_DATA,
  payload: Data,
});

// Deleted Department Data
export const deletedDepartmentData = (data) => ({
  type: DataActionTypes.DELETED_DEPARTMENT_DATA,
  payload: data,
}) 
