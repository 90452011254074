import * as React from "react";
import { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import logo from "../../assets/logo.png";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { clearDepartment } from "../../Redux/Data/Action";
import {
  clearProject,
  requestAllSharedProject,
  getOwnerProjectByPagination,
  fetchSearchData,
  setSearchNewQuery,
  fetchStarredProjects,
  fetchArchivedProjects,
  fetchTrashedProjects,
} from "../../Redux/Projects/Action";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import useFetch from "../../Hooks/useFetch";
import {
  requestAllDepartment,
  requestAllDesignation,
  requestAllGenericDesignation,
  requestAllServices,
  requestAllUsers,
  requestOverHead,
} from "../../Redux/Settings/Action";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

export const Navbar = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [userName, setUserName] = React.useState("");
  const history = useHistory();
  const store = useSelector((store) => store);
  const { isLoading, apiData, serverError } = useFetch(
    "POST",
    `${process.env.REACT_APP_API_URL}/api/project/get-projects`,
    {}
  );

  const [searchState, setSearchState] = React.useState("");
  const searchQueryString = useSelector((store) => store.project?.searchQuery);

  // clears search state if no search query available
  useEffect(() => {
    if (!searchQueryString) {
      setSearchState("");
    }
  }, [searchQueryString]);

  const [debounceTimer, setDebounceTimer] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  if (userName) {
    const splitName = userName.split(" ");
    const firstNameInitial = splitName[0].charAt(0);
    const lastNameInitial = splitName[1].charAt(0);
    var initials = firstNameInitial + lastNameInitial;
  }

  useEffect(() => {
    dispatch(clearDepartment());
    dispatch(clearProject());
    dispatch(
      getOwnerProjectByPagination(props.itemsPerPage, props.currentPage)
    );
    dispatch(requestAllDepartment());
    dispatch(requestAllServices());
    dispatch(requestAllSharedProject());
    dispatch(requestAllDesignation());
    dispatch(requestAllGenericDesignation());
    dispatch(requestAllUsers());
    dispatch(requestOverHead());
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    dispatch(clearProject());
    dispatch(clearDepartment());
    Cookies.remove("token");
    Cookies.remove("code");
    Cookies.remove("userName");
    Cookies.remove("_id");
    Cookies.remove("role");
    Cookies.remove("isFirstLogin");
    history.push("/login");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const itemsPerPage = useSelector((store) => store.project.itemsPerPage);

  useEffect(() => {}, [itemsPerPage]);

  const searchQuery = useSelector((store) => store.project.searchQuery);

  /**
   *
   * @param {boolean} starredButton
   * @param {boolean} pathName.includes('starred')
   * @param {boolean} trashButton
   * @returns void
   */
  const searchHandler = (event) => {
    let pathName = location?.pathname?.match(/[^/]/gm).join("");
    const data = event.target.value.trim();
    dispatch(setSearchNewQuery(data));

    let numOfItems = localStorage.getItem("item-per-page") || 10; // gets num of items per page

    if (data && pathName !== "shared-with-me") {
      dispatch(fetchSearchData(data, numOfItems, 1, pathName));
    } else if (pathName.includes("starred")) {
      dispatch(fetchStarredProjects(numOfItems, 1));
    } else if (pathName.includes("archived")) {
      dispatch(fetchArchivedProjects(numOfItems, 1));
    } else if (pathName.includes("trash")) {
      dispatch(fetchTrashedProjects(numOfItems, 1));
    } else {
      dispatch(getOwnerProjectByPagination(numOfItems, 1));
    }
  };

  // search | debounce handler
  const debounce = (callback, event) => {
    let current_timer;

    setSearchState(event.target.value);

    current_timer = setTimeout(() => callback(event), 300);
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    setDebounceTimer(current_timer);
  };

  const noToShowSearchBar = ["/shared-with-me", "/settings"];

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  React.useEffect(() => {
    if (!userName) {
      setUserName(Cookies.get("userName"));
    }
  }, [Cookies.get("userName")]);

  return (
    <AppBar
      position="fixed"
      sx={{ backgroundColor: "white", padding: "0 20px 0 10px" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            src={logo}
            style={{ width: "120px", padding: "0 100px 0 20px " }}
            sx={{ display: { xs: "none", lg: "flex" } }}
            alt="Schbang Logo"
          />

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            ></Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "flex" },
            }}
            width={"100%"}
          >
            {noToShowSearchBar.includes(
              location?.pathname?.match(/[^]/gm).join("").toLowerCase()
            ) ? null : (
              <TextField
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                label="search"
                onChange={(e) => debounce(searchHandler, e)}
                value={searchState}
                sx={{ margin: "10px 0", width: "60%" }}
              />
            )}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              onClick={handleClick}
              color="inherit"
            >
              <Avatar>{initials}</Avatar>
            </IconButton>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <MenuItem sx={{ width: "100px" }} onClick={handleLogout}>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
