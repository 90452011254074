import React, { useEffect, useState } from "react";
import "./Home.css";
import { Typography } from "@mui/material";
import { ProjectType } from "../../Main/ProjectType";
import { useHistory } from "react-router-dom";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { Notify } from "../../UI/Notify";
import Cookies from "js-cookie";
import {
  CircularProgress,
  Stack,
  InputLabel,
  MenuItem,
  FormControl,
} from "@mui/material";
import Select from "@mui/material/Select";
import useFetch from "../../../Hooks/useFetch";
import { RecentProjectGrid } from "../../Main/RecentProjectGrid";
import { RecentProjectList } from "../../Main/RecentProjectList";
import {
  fetchSearchData,
  getOwnerProjectByPagination,
  setCurrentPageHandler,
  setSearchNewQuery,
} from "../../../Redux/Projects/Action";
import { useDispatch, useSelector } from "react-redux";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";

export const Home = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const allProjects = useSelector((store) => store?.project);

  const [limit, setlimit] = useState(10);
  const currentPage = useSelector((store) => store.project.homeCurrentPage);

  const searchQuery = useSelector((store) => store.project?.searchQuery);

  /**
   * <-- make the curret page being veiwed = 1 -->
   * 1. initally the current page is 1
   * 2. on pagination limit change , then current is page is 1
   * 3. when search handler is called again the current page is 1
   */
  useEffect(() => {
    dispatch(setCurrentPageHandler(1, "home"));
  }, [searchQuery, limit]);

  //Initially clears out the search Box
  useEffect(() => {
    dispatch(setSearchNewQuery(""));
  }, []);

  function handlePageChange(newPage) {
    dispatch(setCurrentPageHandler(newPage, "home"));
    if (searchQuery) {
      dispatch(fetchSearchData(searchQuery, limit, newPage, "dashboard"));
    } else {
      dispatch(getOwnerProjectByPagination(limit, newPage));
    }
  }

  // changes display limit
  function handleItemsPerPageChange(newItemsPerPage) {
    if (searchQuery) {
      dispatch(fetchSearchData(searchQuery, newItemsPerPage, 1, "dashboard"));
    } else {
      dispatch(getOwnerProjectByPagination(newItemsPerPage, 1));
    }
    setlimit(newItemsPerPage);
    dispatch(setCurrentPageHandler(1, "home"));

    localStorage.setItem("item-per-page", newItemsPerPage);
  }

  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const { isLoading, apiData, serverError } = useFetch(
    "POST",
    `${process.env.REACT_APP_API_URL}/api/project/get-projects`,
    {}
  );

  const [view, setView] = useState(
    localStorage.getItem("content-view-type") || "grid"
  );

  if (!Cookies.get("code") && !Cookies.get("token")) {
    history.push("/login");
  }

  useEffect(() => {
    if (Cookies.get("isFirstLogin") === true) {
      setNotification({
        isOpen: true,
        message: "Successfully logged in",
        type: "success",
      });
      Cookies.set("isFirstLogin", false);
    }
  }, []);

  const gridHandler = () => {
    setView("grid");
    localStorage.setItem("content-view-type", "grid");
  };

  const listHandler = () => {
    setView("list");
    localStorage.setItem("content-view-type", "list");
  };

  if (isLoading) {
    return (
      <span>
        <Stack sx={{ margin: "250px 500px" }}>
          <CircularProgress disableShrink />
        </Stack>
      </span>
    );
  }
  if (!isLoading && serverError) {
    return <span>Error!</span>;
  }

  return (
    <>
      <Notify notification={notification} setNotification={setNotification} />
      <Typography
        variant="h6"
        sx={{ color: "#0F172A" }}
        mb="30px"
        fontWeight="500"
      >
        Create New Project
      </Typography>
      <ProjectType />

      <div className="recentviewed">
        <div className="topbar">
          <div>
            <Typography
              variant="h6"
              sx={{ color: "#0F172A" }}
              mb="30px"
              fontWeight="500"
            >
              Recently Viewed
            </Typography>
          </div>
          {/* <MoreHorizIcon sx={{ verticalAlign: "middle", color: "#c4c4c4" }} /> */}
          <div>
            <FormatListBulletedIcon
              onClick={listHandler}
              sx={{
                verticalAlign: "middle",
                mr: "10px",
                cursor: "pointer",
              }}
            />
            <GridViewIcon
              onClick={gridHandler}
              sx={{
                verticalAlign: "middle",
                align: "right",
                cursor: "pointer",
              }}
            />
          </div>
        </div>

        {allProjects?.allHome.length > 0 && (
          <div className="pagination-container">
            <FormControl className="select">
              <InputLabel>Items</InputLabel>
              <Select
                value={limit}
                defaultValue={"10"}
                label="limit"
                onChange={(e) => handleItemsPerPageChange(e.target.value)}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>

            <ResponsivePagination
              total={allProjects?.pageCount}
              current={currentPage}
              onPageChange={(page) => handlePageChange(page)}
            />
          </div>
        )}
        {allProjects?.allHome.length === 0 ? (
          <div>No Items Found!</div>
        ) : view === "grid" ? (
          <RecentProjectGrid
            limit={limit}
            currentPage={currentPage}
            data={allProjects.allHome}
            action={true}
          />
        ) : (
          <RecentProjectList
            limit={limit}
            currentPage={currentPage}
            data={allProjects.allHome}
            action={true}
          />
        )}
      </div>
    </>
  );
};
