import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import { Button, MenuItem, Select, FormControl } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteUsers,
  requestAllUsers,
  updateUsers,
} from "../../../Redux/Settings/Action";
import { Notify } from "../../UI/Notify";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0f172a",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const DesignationView = () => {
  const newDesignation = useSelector((store) => store.settings.newDesignation);
  const [input, setInput] = React.useState("");
  const dispatch = useDispatch();
  const [notification, setNotification] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleOnChange = (e, data) => {
    setInput({ role: e.target.value, _id: data._id });
  };

  const updateHandler = () => {
    if (input) {
      dispatch(updateUsers(input));
      setNotification({
        isOpen: true,
        message: "Successfully Updated User Role",
        type: "success",
      });
      setInput("");
    }
  };

  const deleteHandler = (data) => {
    dispatch(deleteUsers(data));
    setTimeout(() => {
      dispatch(requestAllUsers());
      setNotification({
        isOpen: true,
        message: "Permanently Deleted User",
        type: "error",
      });
    }, 1000);
  };
  React.useEffect(() => {}, [newDesignation]);
  return (
    <>
      <Notify notification={notification} setNotification={setNotification} />

      <TableContainer
        sx={{
          maxWidth: 1200,
          marginBottom: "50px",
          boxShadow:
            " 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.1)",
        }}
        component={Paper}
      >
        <Table sx={{ maxWidth: 1200 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Sl No</StyledTableCell>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Description</StyledTableCell>
              <StyledTableCell align="center">
                Generic Designation
              </StyledTableCell>
              <StyledTableCell align="center">Department </StyledTableCell>
              <StyledTableCell align="center">Monthly Salary</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newDesignation
              ? newDesignation?.map((row, index) => (
                  <StyledTableRow key={row._id}>
                    <StyledTableCell align="center">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.name}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row.description}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.genericDesignationName}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.departmentName}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      ₹ {row?.salary?.monthly.toLocaleString("en-IN")}
                    </StyledTableCell>

                    {/* <StyledTableCell align="center">
                      {row.salary.perDayCost}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.salary.perHourCost}
                    </StyledTableCell> */}
                  </StyledTableRow>
                ))
              : ""}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
