import React, { useEffect, useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import "./Services.css";
import Checkbox from "@mui/material/Checkbox";
import { addServices } from "../../../Redux/Data/Action";
import useFetch from "../../../Hooks/useFetch";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { CircularProgress, Stack } from "@mui/material";

export const Services = () => {
  const location = useLocation();
  const [filService, setFilService] = useState([]);

  const dispatch = useDispatch();
  const store = useSelector((store) => store);

  const { isLoading, apiData, serverError } = useFetch(
    "GET",
    `${process.env.REACT_APP_API_URL}/api/service/get-service`,
    {}
  );

  useEffect(() => {
    if (apiData) {
      if (store.department.departmentServices) {
        const selectedDept = store.department.departments.map(
          (dept) => dept._id
        );
        if (selectedDept) {
          let preFilteredServices = apiData.filter((item) => {
            return selectedDept.includes(item.departmentId);
          });
          setFilService(preFilteredServices);
        }
      } else {
        const selectedDeptNames = store.department.departments.map(
          (dept) => dept._id
        );

        const filteredServices = apiData.filter((service) => {
          return selectedDeptNames.includes(service.departmentId);
        });
        setFilService(filteredServices);
      }
    }
  }, [apiData]);

  const checkHandler = (e, selectedService) => {
    let checked = e.target.checked;

    // storing previous departments to temp array
    selectedService._id = selectedService._id;
    const newDepartmentServices = [...store.department.departmentServices];

    // When user selects a check box
    if (checked) {
      newDepartmentServices.push(selectedService);
      dispatch(addServices(newDepartmentServices));
    } else {
      const neededDepartments = newDepartmentServices.filter(
        (service) => service._id !== selectedService._id
      );
      dispatch(addServices(neededDepartments));
    }
  };

  if (isLoading) {
    return (
      <span>
        <Stack sx={{ margin: "250px 500px" }}>
          <CircularProgress disableShrink />
        </Stack>
      </span>
    );
  }
  if (!isLoading && serverError) {
    return <span>Error!</span>;
  }
  return (
    <>
      <div className="dep">
        <Grid container>
          <Grid item lg={1}>
            <Typography variant="h5">2/3</Typography>
          </Grid>
          <Grid item lg={11}>
            <Typography className="selectservice" variant="h5">
              Select Services
              <Link
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "20px",
                }}
                to="/home/department"
              >
                <span style={{ fontSize: "80%" }}>
                  <u> Add Another Department </u>
                </span>
              </Link>
            </Typography>
            <Grid container mt="40px">
              {filService.length > 0 // new code for checking if there is any services present
                ? filService.map((service, index) => (
                    <Grid
                      key={index}
                      item
                      sx={{
                        marginBottom: "25px",
                      }}
                      lg={3}
                    >
                      <div className="servback">
                        <div className="viewcardserv"></div>
                        <Typography
                          key={index}
                          sx={{
                            display: "flex",
                            padding: "2px 8px",
                            justifyContent: "space-between",
                            verticalAlign: "middle",
                            alignItems: "center",
                          }}
                          variant="subtitle2"
                        >
                          {service.serviceName}
                          <Checkbox
                            sx={{ fontSize: "10" }}
                            checked={store?.department?.departmentServices
                              .map((elm) => elm._id)
                              .includes(service._id)}
                            name={service._id}
                            value={service._id}
                            onChange={(e) => checkHandler(e, service)}
                          />
                        </Typography>
                      </div>
                    </Grid>
                  ))
                : "No service"}
            </Grid>
            {store.department.projectId ? (
              <Button
                to={`/deliverables/update/id:${
                  store.department.projectId
                }?page=${location.pathname.substring(1)}`}
                component={Link}
                disabled={
                  store.department.departmentServices &&
                  store.department.departmentServices.length <= 0
                }
                sx={{
                  color: "#ffffff",
                  fontWeight: "500",
                  mt: "40px",
                  px: "40px",
                  backgroundColor: "#2d99fe",
                }}
                variant="contained"
              >
                Next
              </Button>
            ) : (
              <Button
                to="/home/deliverables"
                component={Link}
                disabled={
                  store.department.departmentServices &&
                  store.department.departmentServices.length <= 0
                }
                sx={{
                  color: "#ffffff",
                  fontWeight: "500",
                  mt: "40px",
                  px: "40px",
                  backgroundColor: "#2d99fe",
                }}
                variant="contained"
              >
                Next
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};
