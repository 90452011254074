import React, { useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Notify } from "../../UI/Notify";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteCollaborator,
  requestAllSharedProject,
  updateCollaborator,
} from "../../../Redux/Projects/Action";
import { useEffect } from "react";

import CollaboratorRow from "./CollaboratorRow";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0f172a",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const ViewCollaborator = ({ projectId, collaborators }) => {
  const versionRef = useRef(null);

  const roleRef = useRef(null);

  const [collabId, setCollabId] = React.useState("");
  const collabs = useSelector((state) => state.project.projectCollaborators);

  useEffect(() => {}, [collabs]);

  const allVersions = useSelector((store) => store.project?.allVersions);

  const dispatch = useDispatch();

  const [notification, setNotification] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [inputRole, setInputRole] = React.useState(null);
  const [inputVersion, setInputVersion] = React.useState(null);

  // Inital value filler for role and version
  // useEffect(() => {
  //   if (allVersions && allVersions[0]) {
  //     setInputVersion(allVersions[0].version);
  //   }
  //   if (collabs?.collaborators && collabs?.collaborators[0]) {
  //     setInputRole(collabs.collaborators[0].collaboratorRole);
  //   }
  // }, [inputVersion, inputRole]);

  //Role handler
  const handleRoleOnChange = (e, data) => {
    setInputRole(e.target.value);
    setCollabId(data._id);

    setInputVersion(data?.versionAccess);
  };

  //Version handler
  const handleVersionOnChange = (event, data) => {
    setInputVersion(event.target.value);
    setCollabId(data._id);

    setInputRole(data?.collaboratorRole);
  };

  //Common handler for role and version
  const handleChange = (event, data, type) => {
    if (type === "role") {
      setInputRole(event.target.value);

      setInputVersion(inputVersion || data?.versionAccess);
    } else {
      setInputVersion(event.target.value);

      setInputRole(inputRole || data?.collaboratorRole);
    }
    setCollabId(data._id);
  };

  const updateHandler = (collabId) => {
    if (inputRole || inputVersion) {
      const data = {
        role: inputRole,
        versionAccess: inputVersion,
      };
      dispatch(updateCollaborator(data, projectId, collabId));

      setNotification({
        isOpen: true,
        message: "Successfully Updated User Role and/or Version Access",
        type: "success",
      });

      setInputRole(inputRole);
      setInputVersion(inputVersion);
      setCollabId("");
      dispatch(requestAllSharedProject());
    }
  };

  const deleteHandler = (collabId) => {
    dispatch(deleteCollaborator(projectId, collabId));

    setNotification({
      isOpen: true,
      message: "Successfully Deleted Collaborator",
      type: "error",
    });
    dispatch(requestAllSharedProject());
    setInputRole(inputRole);
    setInputVersion(inputVersion);
    setCollabId("");
  };

  return (
    <div>
      {" "}
      <>
        <Notify notification={notification} setNotification={setNotification} />

        {collabs !== "" && collabs?.collaborators?.length !== 0 ? (
          <TableContainer
            sx={{
              maxWidth: 1100,
              maxHeight: "60vh",
              boxShadow:
                " 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.1)",
            }}
            component={Paper}
          >
            <Table sx={{ maxWidth: 1100 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Sr.No</StyledTableCell>
                  <StyledTableCell align="center">Name</StyledTableCell>
                  <StyledTableCell align="center">Role</StyledTableCell>
                  <StyledTableCell align="center">Version</StyledTableCell>
                  <StyledTableCell align="center">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {collabs?.collaborators
                  ? collabs?.collaborators.map((row, index) => (
                      <CollaboratorRow
                        row={row}
                        index={index}
                        key={index}
                        allVersions={allVersions}
                        projectId={projectId}
                      />
                    ))
                  : ""}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h5>No Collaborators Added</h5>
          </div>
        )}
      </>
    </div>
  );
};
