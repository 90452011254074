import React, { useState } from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import SystemUpdateAltOutlinedIcon from "@mui/icons-material/SystemUpdateAltOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import "./RespSidebar.css";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearDepartment } from "../../Redux/Data/Action";
import { clearProject } from "../../Redux/Projects/Action";
import Cookies from "js-cookie";
import ShareIcon from "@mui/icons-material/Share";

export const RespSidebar = () => {
  const [role, setRole] = useState(Cookies.get("role"));

  const location = useLocation();
  const dispatch = useDispatch();

  const clearDataHandler = () => {
    dispatch(clearDepartment());
    dispatch(clearProject());
  };

  return (
    <>
      <div className="container">
        <div className="respsidebar">
          <ul>
            <li
              className={
                location.pathname.includes("/home") ? "active_linkresp" : ""
              }
            >
              <Typography
                variant="body1"
                fontWeight="300"
                onClick={clearDataHandler}
              >
                <Link to="/dashboard">
                  <span>
                    <HomeOutlinedIcon
                      fontSize="medium"
                      sx={{ color: "white" }}
                    />
                  </span>
                  {/* Home */}
                </Link>
              </Typography>
            </li>
            <li
              className={
                location.pathname.includes("/shared-with-me")
                  ? "active_linkresp"
                  : ""
              }
            >
              <Typography variant="body1">
                <Link to="/shared-with-me">
                  <span>
                    <ShareIcon fontSize="medium" sx={{ color: "white" }} />
                  </span>
                </Link>
              </Typography>
            </li>
            <li
              className={
                location.pathname.includes("/starred") ? "active_linkresp" : ""
              }
            >
              <Typography variant="body1">
                <Link to="/starred">
                  <span>
                    <StarBorderOutlinedIcon
                      fontSize="medium"
                      sx={{ color: "white" }}
                    />
                  </span>
                  {/* Starred */}
                </Link>
              </Typography>
            </li>

            {/* <li
              className={
                location.pathname.includes("/draft") ? "active_linkresp" : ""
              }
            >
              <Typography variant="body1">
                <Link to="/draft">
                  <span>
                    <InsertDriveFileOutlinedIcon
                      fontSize="medium"
                      sx={{ color: "white" }}
                    />
                  </span>
                 
                </Link>
              </Typography>
            </li> */}
            <li
              className={
                location.pathname.includes("/archived") ? "active_linkresp" : ""
              }
            >
              <Typography variant="body1">
                <Link to="/archived">
                  <span>
                    <SystemUpdateAltOutlinedIcon
                      fontSize="medium"
                      sx={{ color: "white" }}
                    />
                  </span>
                  {/* Archived */}
                </Link>
              </Typography>
            </li>

            <li
              className={
                location.pathname.includes("/trash") ? "active_linkresp" : ""
              }
            >
              <Typography variant="body1">
                <Link to="/trash">
                  <span>
                    <DeleteOutlinedIcon
                      fontSize="medium"
                      sx={{ color: "white" }}
                    />
                  </span>
                  {/* Trash */}
                </Link>
              </Typography>
            </li>
            {role === "Admin" ? (
              <li
                className={
                  location.pathname.includes("/settings")
                    ? "active_linkresp"
                    : ""
                }
              >
                <Typography variant="body1">
                  <Link to="/settings">
                    <span>
                      <SettingsOutlinedIcon
                        fontSize="medium"
                        sx={{ color: "white" }}
                      />
                    </span>
                  </Link>
                </Typography>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      </div>
    </>
  );
};
