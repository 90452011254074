import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0f172a",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const ViewModeCal = (props) => {
  return (
    <>
      <TableContainer
        sx={{
          maxWidth: 1000,
          boxShadow:
            " 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.1)",
        }}
        component={Paper}
      >
        {props.action ? (
          <Table sx={{ maxWidth: 1000 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Sr.No</StyledTableCell>
                <StyledTableCell align="center">
                  Designation Name
                </StyledTableCell>
                <StyledTableCell align="center">
                  Generic Designation Name
                </StyledTableCell>
                <StyledTableCell align="center">
                  Calculation Factor
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.resources
                ? props.resources.map((row, index) => (
                    <StyledTableRow key={row._id}>
                      <StyledTableCell align="center">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.designationName}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.genericDesignationName}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.calculationFactor}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                : ""}
            </TableBody>
          </Table>
        ) : (
          <Table sx={{ maxWidth: 1000 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Sr.No</StyledTableCell>
                <StyledTableCell align="center">
                  Designation Name
                </StyledTableCell>
                <StyledTableCell align="center">
                  Generic Designation Name
                </StyledTableCell>
                <StyledTableCell align="center">Add On Cost </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.addOns
                ? props.addOns.map((row, index) => (
                    <StyledTableRow key={row._id}>
                      <StyledTableCell align="center">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.designationName}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.genericDesignationName}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.addOnCost}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                : ""}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </>
  );
};
