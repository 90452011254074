import ProjectActionTypes from "./ProjectActionType";
import axios from "axios";
import Cookies from "js-cookie";
import { version } from "react-dom";

export const setAllProject = () => async (dispatch) => {
  dispatch({
    type: ProjectActionTypes.FETCH_PROJECT_START,
  });

  try {
    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/project/get-projects`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });
    dispatch({
      type: ProjectActionTypes.FETCH_PROJECT_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ProjectActionTypes.FETCH_PROJECT_FAIL,
      payload: e,
      isError: true,
    });
  }
};

export const addProject = (data) => async (dispatch) => {
  dispatch({
    type: ProjectActionTypes.ADD_PROJECT_START,
  });

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/project/create-project`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: ProjectActionTypes.ADD_PROJECT_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ProjectActionTypes.ADD_PROJECT_FAIL,
      payload: e,
      isError: true,
    });
  }
};

export const updateProject = (data) => async (dispatch) => {
  dispatch({
    type: ProjectActionTypes.UPDATE_PROJECT_START,
  });

  try {
    const response = await axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/api/project/edit-project/${data._id}`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: ProjectActionTypes.UPDATE_PROJECT_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ProjectActionTypes.UPDATE_PROJECT_FAIL,
      payload: e,
      isError: true,
    });
  }
};

export const deleteProject = (data) => async (dispatch) => {
  dispatch({
    type: ProjectActionTypes.DELETE_PROJECT_START,
  });
  try {
    const response = await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/api/project/delete-project/${data._id}`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: ProjectActionTypes.DELETE_PROJECT_SUCCESS,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: ProjectActionTypes.DELETE_PROJECT_FAIL,
      payload: e,
      isError: true,
    });
  }
};

export const addPricing = (data) => async (dispatch) => {
  dispatch({
    type: ProjectActionTypes.ADD_PRICING_START,
  });
  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/project/pricing/create-pricing`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });
    dispatch({
      type: ProjectActionTypes.ALL_VERSIONS_OF_PROJECTS,
      payload: response.data,
    });
    dispatch({
      type: ProjectActionTypes.ADD_PRICING_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ProjectActionTypes.ADD_PRICING_FAIL,
      payload: e,
      isError: true,
    });
  }
};

export const updatePricing = (data) => async (dispatch) => {
  dispatch({
    type: ProjectActionTypes.UPDATE_PRICING_START,
  });

  try {
    const response = await axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/api/project/pricing/update-pricing-projectId/${data.projectId}`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: ProjectActionTypes.UPDATE_PRICING_SUCCESS,
      payload: response.data,
    });

    dispatch({
      type: ProjectActionTypes.ALL_PRICING_OF_PROJECT,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ProjectActionTypes.UPDATE_PRICING_FAIL,
      payload: e,
      isError: true,
    });
  }
};

export const updatePricingByProject = (_id) => async (dispatch) => {
  dispatch({
    type: ProjectActionTypes.UPDATE_PROJECT_PRICING_START,
  });
  try {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/api/project/pricing/get-pricing-on-edit-project-in-version/${_id}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });
    dispatch({
      type: ProjectActionTypes.UPDATE_PROJECT_PRICING_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: ProjectActionTypes.ALL_COMMENTS_OF_PROJECTS,
      payload: response.data.versions,
    });
    dispatch({
      type: ProjectActionTypes.ALL_VERSIONS_OF_PROJECTS,
      payload: response.data,
    });
    dispatch({
      type: ProjectActionTypes.ALL_PRICING_OF_PROJECT,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ProjectActionTypes.UPDATE_PROJECT_PRICING_FAIL,
      payload: e,
      isError: true,
    });
  }
};

//Clearing All Versions of a particular project state
export const clearAllVersionsOfProject = () => ({
  type: ProjectActionTypes.ALL_VERSIONS_OF_PROJECTS,
  payload: [],
});

export const editVersionNotes = (versionNumber, data = "", allComments) => {
  return {
    type: ProjectActionTypes.SET_EDIT_VERSION_NOTES,
    payload: { versionNumber, data, allComments },
  };
};

export const setProjectPricingData = (pricingData) => ({
  type: ProjectActionTypes.SET_PRICING_DATA,
  payload: pricingData,
});

export const setProjectPricingDepartmentData = (departments) => ({
  type: ProjectActionTypes.SET_PRICING_DATA_DEPARTMENTS,
  payload: departments,
});

export const setProjectPricingAdOnsData = (adOns) => ({
  type: ProjectActionTypes.SET_PRICING_DATA_AD_ONS,
  payload: adOns,
});

export const clearProject = () => ({
  type: ProjectActionTypes.CLEAR_PROJECT,
  payload: null,
});

export const viewMode = (data) => ({
  type: ProjectActionTypes.SET_VIEW_MODE,
  payload: data,
});

export const addAllProjects = (allProjects) => ({
  type: ProjectActionTypes.SET_ALL_PROJECTS,
  payload: allProjects,
});

export const setSearchData = (searchData) => ({
  type: ProjectActionTypes.SET_SEARCH_DATA,
  payload: searchData,
});

// Profit margin

export const updateProfitMargin = (data, _id, vers) => async (dispatch) => {
  dispatch({
    type: ProjectActionTypes.UPDATE_PROFIT_START,
  });

  try {
    const response = await axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/api/project/pricing/update-profit-margin/${_id}/${vers}`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: ProjectActionTypes.UPDATE_PROFIT_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ProjectActionTypes.UPDATE_PROFIT_FAIL,
      payload: e,
      isError: true,
    });
  }
};

// RESOURCES

export const updateResources =
  (data, project_id, resource_id, vers) => async (dispatch) => {
    dispatch({
      type: ProjectActionTypes.UPDATE_RESOURCES_START,
    });

    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/api/project/pricing/update-pricing-resource/${project_id}/${vers}/${resource_id}`,
        data,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });

      dispatch({
        type: ProjectActionTypes.UPDATE_RESOURCES_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: ProjectActionTypes.UPDATE_RESOURCES_FAIL,
        payload: e,
        isError: true,
      });
    }
  };

export const deleteResources =
  (project_id, resource_id, vers) => async (dispatch) => {
    dispatch({
      type: ProjectActionTypes.DELETE_RESOURCES_START,
    });
    try {
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_API_URL}/api/project/pricing/delete-pricing-resource/${project_id}/${vers}/${resource_id}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });

      dispatch({
        type: ProjectActionTypes.DELETE_RESOURCES_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: ProjectActionTypes.DELETE_RESOURCES_FAIL,
        payload: e,
        isError: true,
      });
    }
  };

export const addResources = (data, project_id, vers) => async (dispatch) => {
  dispatch({
    type: ProjectActionTypes.ADD_RESOURCES_START,
  });

  try {
    const response = await axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/api/project/pricing/add-pricing-resource/${project_id}/${vers}`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: ProjectActionTypes.ADD_RESOURCES_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ProjectActionTypes.ADD_RESOURCES_FAIL,
      payload: e,
      isError: true,
    });
  }
};

// ADDONS

export const updateAddons =
  (data, project_id, resource_id, vers) => async (dispatch) => {
    dispatch({
      type: ProjectActionTypes.UPDATE_ADDONS_START,
    });

    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/api/project/pricing/update-pricing-addon/${project_id}/${vers}/${resource_id}`,
        data,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });

      dispatch({
        type: ProjectActionTypes.UPDATE_ADDONS_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: ProjectActionTypes.UPDATE_ADDONS_FAIL,
        payload: e,
        isError: true,
      });
    }
  };

export const deleteAddons =
  (project_id, addOns_id, vers) => async (dispatch) => {
    dispatch({
      type: ProjectActionTypes.DELETE_ADDONS_START,
    });
    try {
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_API_URL}/api/project/pricing/delete-pricing-addon/${project_id}/${vers}/${addOns_id}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });

      dispatch({
        type: ProjectActionTypes.DELETE_ADDONS_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: ProjectActionTypes.DELETE_ADDONS_FAIL,
        payload: e,
        isError: true,
      });
    }
  };

export const addAddons = (data, project_id, vers) => async (dispatch) => {
  dispatch({
    type: ProjectActionTypes.ADD_ADDONS_START,
  });

  try {
    const response = await axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/api/project/pricing/add-pricing-addon/${project_id}/${vers}`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: ProjectActionTypes.ADD_ADDONS_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ProjectActionTypes.ADD_ADDONS_FAIL,
      payload: e,
      isError: true,
    });
  }
};

export const generatePdf = (project_id) => async (dispatch) => {
  dispatch({
    type: ProjectActionTypes.GENERATE_PDF_START,
  });

  try {
    const response = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/project/pricing/get-download-data/${project_id}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: ProjectActionTypes.GENERATE_PDF_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ProjectActionTypes.GENERATE_PDF_FAIL,
      payload: e,
      isError: true,
    });
  }
};

export const projectLogo = (_id, data) => async (dispatch) => {
  dispatch({
    type: ProjectActionTypes.ADD_PROJECT_LOGO_START,
  });

  try {
    const response = await axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/api/project/add-project-logo/${_id}`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: ProjectActionTypes.ADD_PROJECT_LOGO_SUCCESS,
      payload: response.data,
    });

    return Promise.resolve(response);
  } catch (e) {
    dispatch({
      type: ProjectActionTypes.ADD_PROJECT_LOGO_FAIL,
      payload: e.response.data.error,
      isError: true,
    });

    return Promise.reject(e);
  }
};

export const addDiscount = (_id, data, vers) => async (dispatch) => {
  dispatch({
    type: ProjectActionTypes.ADD_DISCOUNT_START,
  });

  try {
    const response = await axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/api/project/pricing/update-discount/${_id}/${vers}`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: ProjectActionTypes.ADD_DISCOUNT_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ProjectActionTypes.ADD_DISCOUNT_FAIL,
      payload: e,
      isError: true,
    });
  }
};

export const clonePricing = (cloneId, _id) => async (dispatch) => {
  dispatch({
    type: ProjectActionTypes.CLONE_PRICING_START,
  });

  try {
    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/project/pricing/clone-pricing/${cloneId}/${_id}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });
    dispatch({
      type: ProjectActionTypes.CLONE_PRICING_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: ProjectActionTypes.ALL_VERSIONS_OF_PROJECTS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ProjectActionTypes.CLONE_PRICING_FAIL,
      payload: e,
      isError: true,
    });
  }
};

export const addCollaborators = (_id, data) => async (dispatch) => {
  dispatch({
    type: ProjectActionTypes.ADD_COLLABORATORS_START,
  });
  try {
    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/project/add-collaborators/${_id}`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });
    dispatch({
      type: ProjectActionTypes.ADD_COLLABORATORS_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ProjectActionTypes.ADD_COLLABORATORS_FAIL,
      payload: e,
      isError: true,
    });
  }
};

export const setSelectedVersionAction = (version) => ({
  type: ProjectActionTypes.SET_SELECTED_VERSION,
  payload: version,
});

export const addCollaboratorsToTeam =
  (project_id, data) => async (dispatch) => {
    dispatch({
      type: ProjectActionTypes.UPDATE_TEAM_COLLABORATORS_START,
    });
    try {
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_API_URL}/api/project/add-collaborators-to-team/${project_id}`,
        data,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });
      dispatch({
        type: ProjectActionTypes.UPDATE_TEAM_COLLABORATORS_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: ProjectActionTypes.UPDATE_TEAM_COLLABORATORS_FAIL,
        payload: e,
        isError: true,
      });
    }
  };

export const updateCollaborator =
  (data, project_id, collab_id) => async (dispatch) => {
    dispatch({
      type: ProjectActionTypes.UPDATE_COLLABORATORS_START,
    });
    try {
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_API_URL}/api/project/edit-collaborator-role/${project_id}/${collab_id}`,
        data,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });
      dispatch({
        type: ProjectActionTypes.UPDATE_COLLABORATORS_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: ProjectActionTypes.UPDATE_COLLABORATORS_FAIL,
        payload: e,
        isError: true,
      });
    }
  };

// delete collab

export const deleteCollaborator =
  (project_id, collab_id) => async (dispatch) => {
    dispatch({
      type: ProjectActionTypes.DELETE_COLLABORATORS_START,
    });
    try {
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_API_URL}/api/project/delete-collaborator/${project_id}/${collab_id}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });
      dispatch({
        type: ProjectActionTypes.DELETE_COLLABORATORS_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: ProjectActionTypes.DELETE_COLLABORATORS_FAIL,
        payload: e,
        isError: true,
      });
    }
  };

export const getProjectCollaborators = (_id) => async (dispatch) => {
  dispatch({
    type: ProjectActionTypes.FETCH_COLLABORATORS_START,
  });
  try {
    const response = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/project/get-collaborators/${_id}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: ProjectActionTypes.FETCH_COLLABORATORS_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ProjectActionTypes.FETCH_COLLABORATORS_FAIL,
      payload: e,
      isError: true,
    });
  }
};

export const clearCollaborators = () => ({
  type: ProjectActionTypes.CLEAR_COLLABORATORS,
  payload: [],
});

export const getPricingVersions = (projectId) => async (dispatch) => {
  dispatch({
    type: ProjectActionTypes.FETCH_VERSIONS_START,
  });
  try {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/api/project/pricing/get-pricing-version/${projectId}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: ProjectActionTypes.FETCH_VERSIONS_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ProjectActionTypes.FETCH_VERSIONS_FAIL,
      payload: e,
      isError: true,
    });
  }
};

export const selectVersion = (version) => (dispatch) => {
  dispatch({
    type: ProjectActionTypes.SELECT_VERSIONS,
    payload: version,
  });
};

// sHARED Project
export const requestAllSharedProject = () => async (dispatch) => {
  dispatch({
    type: ProjectActionTypes.FETCH_SHARED_PROJECT_START,
  });
  try {
    const response = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/project/get-projects-by-collaborator`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });
    dispatch({
      type: ProjectActionTypes.FETCH_SHARED_PROJECT_SUCCESS,
      payload: response.data,
      isError: false,
    });
  } catch (e) {
    dispatch({
      type: ProjectActionTypes.FETCH_SHARED_PROJECT_FAIL,
      payload: [],
      isError: true,
    });
  }
};

// Detailed Project by Owner

export const getProjectByOwner = (projectId) => async (dispatch) => {
  dispatch({
    type: ProjectActionTypes.GET_PROJECT_BY_OWNER_START,
  });
  try {
    const response = await axios({
      method: "POST",
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });
    dispatch({
      type: ProjectActionTypes.GET_PROJECT_BY_OWNER_SUCCESS,
      payload: response.data,
      isError: false,
    });
  } catch (e) {
    dispatch({
      type: ProjectActionTypes.GET_PROJECT_BY_OWNER_FAIL,
      payload: [],
      isError: true,
    });
  }
};

//Pagination for Owner Projects
export const getOwnerProjectByPagination =
  (limit, page) => async (dispatch) => {
    if (limit === undefined && page === undefined) {
      limit = 10;
      page = 1;
    }
    dispatch({
      type: ProjectActionTypes.GET_PROJECT_BY_OWNER_PAGINATION_START,
    });
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/project/get-projects-pagination?limit=${limit}&page=${page}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });

      if (response.data.data?.length === 0 && response?.data.totalRecords > 1) {
        const totalPages = Math.ceil(response?.data.totalRecords / limit);
        const newPage = Math.min(page, totalPages);
        const secondResponse = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/api/project/get-projects-pagination?limit=${limit}&page=${newPage}`,
          headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        });
        dispatch({
          type: ProjectActionTypes.GET_PROJECT_BY_OWNER_PAGINATION_SUCCESS,
          payload: secondResponse.data,
          isError: false,
        });
      } else {
        dispatch({
          type: ProjectActionTypes.GET_PROJECT_BY_OWNER_PAGINATION_SUCCESS,
          payload: response.data,
          isError: false,
        });
      }
    } catch (e) {
      dispatch({
        type: ProjectActionTypes.GET_PROJECT_BY_OWNER_PAGINATION_FAIL,
        payload: [],
        isError: true,
      });
    }
  };

// Archived Projects
export const fetchArchivedProjects = (limit, page) => async (dispatch) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/project/get-archived-projects?limit=${limit}&page=${page}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    if (response.data.data?.length === 0 && response?.data.totalRecords > 1) {
      const totalPages = Math.ceil(response?.data.totalRecords / limit);
      const newPage = Math.min(page, totalPages);

      const secondResponse = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/api/project/get-archived-projects?limit=${limit}&page=${newPage}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });

      dispatch({
        type: ProjectActionTypes.FETCH_ARCHIVED_PROJECTS_SUCCESS,
        payload: secondResponse,
        isError: false,
      });
    } else {
      dispatch({
        type: ProjectActionTypes.FETCH_ARCHIVED_PROJECTS_SUCCESS,
        payload: response,
        isError: false,
      });
    }
  } catch (e) {
    dispatch({
      type: ProjectActionTypes.FETCH_ARCHIVED_PROJECTS_FAILURE,
      payload: [],
      isError: true,
    });
  }
};

// Trashed Projects
export const fetchTrashedProjects = (limit, page) => async (dispatch) => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/api/project/get-trashed-projects?limit=${limit}&page=${page}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });
    if (response.data.data?.length === 0 && response?.data.totalRecords > 1) {
      const totalPages = Math.ceil(response?.data.totalRecords / limit);
      const newPage = Math.min(page, totalPages);
      const secondResponse = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/api/project/get-trashed-projects?limit=${limit}&page=${newPage}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });
      dispatch({
        type: ProjectActionTypes.FETCH_TRASHED_PROJECTS_SUCCESS,
        payload: secondResponse,
        isError: false,
      });
    } else {
      dispatch({
        type: ProjectActionTypes.FETCH_TRASHED_PROJECTS_SUCCESS,
        payload: response,
        isError: false,
      });
    }
  } catch (e) {
    dispatch({
      type: ProjectActionTypes.FETCH_TRASHED_PROJECTS_FAILURE,
      payload: [],
      isError: true,
    });
  }
};

//Starred Projects
export const fetchStarredProjects = (limit, page) => async (dispatch) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/project/get-starred-projects?limit=${limit}&page=${page}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });
    if (response.data.data?.length === 0 && response?.data.totalRecords > 1) {
      const totalPages = Math.ceil(response?.data.totalRecords / limit);
      const newPage = Math.min(page, totalPages);

      const secondResponse = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/api/project/get-starred-projects?limit=${limit}&page=${newPage}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });
      dispatch({
        type: ProjectActionTypes.FETCH_STARRED_PROJECTS_SUCCESS,
        payload: secondResponse,
        isError: false,
      });
    } else {
      dispatch({
        type: ProjectActionTypes.FETCH_STARRED_PROJECTS_SUCCESS,
        payload: response,
        isError: false,
      });
    }
  } catch (e) {
    dispatch({
      type: ProjectActionTypes.FETCH_STARRED_PROJECTS_FAILURE,
      payload: [],
      isError: true,
    });
  }
};

//Search Data
export const fetchSearchData = (query, limit, page, key) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/project/get-projects-pagination?search=${query}&limit=${limit}&page=${page}&key=${key}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });
      dispatch({
        type: ProjectActionTypes.FETCH_SEARCH_DATA_SUCCESS,
        payload: response.data,
        isError: false,
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        dispatch({
          type: ProjectActionTypes.NO_SEARCH_RESULTS,
          isError: true,
          message: "No items found",
        });
      } else {
        dispatch({
          type: ProjectActionTypes.FETCH_SEARCH_DATA_FAILURE,
          payload: [],
          isError: true,
        });
      }
    }
  };
};

export const setSearchNewQuery = (query) => ({
  type: ProjectActionTypes.SET_SEARCH_QUERY,
  payload: query,
});

//To get total version of an project
export const getTotalVersion = (projectId) => async (dispatch) => {
  dispatch({
    type: ProjectActionTypes.FETCH_TOTAL_VERSIONS_START,
  });
  try {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/api/project/pricing/get-pricing-version/${projectId}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });
    dispatch({
      type: ProjectActionTypes.FETCH_TOTAL_VERSIONS_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ProjectActionTypes.FETCH_TOTAL_VERSIONS_FAIL,
      payload: e,
      isError: true,
    });
  }
};

export const clearAllVersions = () => ({
  type: ProjectActionTypes.CLEAR_ALL_VERSIONS,
});

// All users for "Add Collaborators"
export const usersForCollab = (project_id) => async (dispatch) => {
  dispatch({
    type: ProjectActionTypes.FETCH_USERS_To_COLLAB_START,
  });
  try {
    const response = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/user/public/all-user/${project_id}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });
    dispatch({
      type: ProjectActionTypes.FETCH_USERS_To_COLLAB_SUCCESS,
      payload: response.data,
      isError: false,
    });
  } catch (e) {
    dispatch({
      type: ProjectActionTypes.FETCH_USERS_To_COLLAB_FAIL,
      payload: [],
      isError: true,
    });
  }
};

//Mark As Sold
export const markAsSold =
  (data, projectId, versionNumber) => async (dispatch) => {
    dispatch({
      type: ProjectActionTypes.MARK_AS_SOLD_START,
    });
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/project/pricing/mark-sold-status/${projectId}/${versionNumber}`,
        data,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });
      dispatch({
        type: ProjectActionTypes.MARK_AS_SOLD_SUCCESS,
        payload: response.data,
        isError: false,
      });
      dispatch({
        type: ProjectActionTypes.ALL_VERSIONS_OF_PROJECTS,
        payload: response.data,
        isError: false,
      });
    } catch (e) {
      dispatch({
        type: ProjectActionTypes.MARK_AS_SOLD_FAIL,
        payload: [],
        isError: true,
      });
    }
  };

//Versioning Notes to a particular version
export const versionComment =
  (data, projectId, versionNumber) => async (dispatch) => {
    dispatch({
      type: ProjectActionTypes.UPDATE_VERSION_COMMENT_START,
    });
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/project/pricing/version-comment/${projectId}/${versionNumber}`,
        data,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });
      dispatch({
        type: ProjectActionTypes.UPDATE_VERSION_COMMENT_SUCCESS,
        payload: response.data,
        isError: false,
      });
      dispatch({
        type: ProjectActionTypes.ALL_COMMENTS_OF_PROJECTS,
        payload: response.data.versions,
        isError: false,
      });
    } catch (e) {
      dispatch({
        type: ProjectActionTypes.UPDATE_VERSION_COMMENT_FAIL,
        payload: [],
        isError: true,
      });
    }
  };

//Empty Version Comment
export const EmptyVersionComment = () => ({
  type: ProjectActionTypes.ALL_COMMENTS_OF_PROJECTS,
  payload: [],
});

//Current Page handler

export const setCurrentPageHandler = (pageNum, type) => {
  return (dispatch) => {
    if (type === "starred") {
      dispatch({
        type: ProjectActionTypes.SET_CURRENT_PAGE_HANDLER,
        payload: {
          starredCurrentPage: pageNum || 1,
        },
      });
    } else if (type === "archived") {
      dispatch({
        type: ProjectActionTypes.SET_CURRENT_PAGE_HANDLER,
        payload: {
          archivedCurrentPage: pageNum || 1,
        },
      });
    } else if (type === "trash") {
      dispatch({
        type: ProjectActionTypes.SET_CURRENT_PAGE_HANDLER,
        payload: {
          trashCurrentPage: pageNum || 1,
        },
      });
    } else if (type === "home") {
      dispatch({
        type: ProjectActionTypes.SET_CURRENT_PAGE_HANDLER,
        payload: {
          homeCurrentPage: pageNum || 1,
        },
      });
    }
  };
};
