import React from "react";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import "./Collaborators.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import {
  addCollaborators,
  addCollaboratorsToTeam,
  clearCollaborators,
  clearProject,
  fetchArchivedProjects,
  fetchStarredProjects,
  fetchTrashedProjects,
  getOwnerProjectByPagination,
  getProjectCollaborators,
  getTotalVersion,
  requestAllSharedProject,
  usersForCollab,
} from "../../../Redux/Projects/Action";
import Cookies from "js-cookie";
import { Notify } from "../../UI/Notify";

import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import { ViewCollaborator } from "./ViewCollaborator";
import { useEffect } from "react";
import { clearDepartment } from "../../../Redux/Data/Action";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const role = ["Edit", "Edit"];

export const Collaborators = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [selectedVersion, setSelectedVersion] = React.useState("");

  const [role, setRole] = React.useState("");
  const [users, setUsers] = React.useState([]);
  const [tab, setTab] = React.useState(true);
  const collabs = props._id;

  const allVersions = props.allVersions;

  const projectId = useSelector((store) => store.project?.projectIdVersion);

  const handleVersionChange = (event) => {
    setSelectedVersion(event.target.value);
  };

  const [notification, setNotification] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const store = useSelector((store) => store.settings.allUsers);
  const [allUsers, setAllUsers] = React.useState(store);

  //Remaning users to add as collaborators
  const filteredUsers = useSelector((store) => store.project?.usersForCollab);

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const userHandler = () => {
    const collaborators = [];
    dispatch(usersForCollab(props._id));
    users.map((user) => {
      const collabId = filteredUsers.filter((i) => i.name === user);
      collaborators.push({
        collaboratorName: user,
        collaboratorId: collabId[0]._id,
        collaboratorRole: role,
        versionAccess: selectedVersion,
      });
    });
    if (users.length > 0) {
      const team = {
        teamName: props._id,
        collaborators: collaborators,
      };
      setSelectedVersion("");
      if (collabs) {
        dispatch(addCollaboratorsToTeam(props._id, team));
        setNotification({
          isOpen: true,
          message: "Successfully Added Collaborators",
          type: "success",
        });
        dispatch(getProjectCollaborators(props._id));
        setUsers([]);
        setSelectedVersion("");
        setRole("");
      } else {
        dispatch(addCollaborators(props._id, team));
        setNotification({
          isOpen: true,
          message: "Successfully Updated Collaborators",
          type: "success",
        });
        dispatch(getProjectCollaborators(props._id));
        setSelectedVersion("");
        setUsers([]);
      }
    } else {
      setNotification({
        isOpen: true,
        message: "Collaborators or Team Name is Empty",
        type: "error",
      });
    }
    dispatch(getProjectCollaborators(props._id));
    dispatch(requestAllSharedProject());
    dispatch(usersForCollab(props._id));
  };

  const handleOpen = () => {
    dispatch(getProjectCollaborators(props._id));
    dispatch(usersForCollab(props._id));
    setSelectedVersion("");
    dispatch(getTotalVersion(props._id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(clearCollaborators());
    dispatch(clearProject());
    setTab(true);
    setSelectedVersion("");
    dispatch(getOwnerProjectByPagination(props.limit, props.currentPage));
    dispatch(fetchTrashedProjects(props.limit, props.currentPage));
    dispatch(fetchArchivedProjects(props.limit, props.currentPage));
    dispatch(fetchStarredProjects(props.limit, props.currentPage));

    setUsers([]);
  };

  const collabDispatch = () => {
    dispatch(usersForCollab(props._id));
  };

  React.useEffect(() => {
    if (collabs) {
      if (collabs?.collaborators) {
        const result = store.filter(
          (item1) =>
            !collabs.collaborators.some(
              (item2) => item2.collaboratorName === item1.name
            )
        );
        if (Cookies.get("userName")) {
          const data = result.filter(
            (option) => Cookies.get("userName") !== option.name
          );
          setAllUsers(data);
        }
      }
    }
  }, [collabs]);

  return (
    <>
      <Notify notification={notification} setNotification={setNotification} />

      <div>
        <div className="myDIV">
          <GroupAddOutlinedIcon
            sx={{ cursor: "pointer", fontSize: "27px", marginTop: "5px" }}
            onClick={handleOpen}
          />
        </div>
        {/* <p className="hide">Add Collaborators</p> */}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <CloseIcon
              onClick={handleClose}
              sx={{
                marginLeft: "auto",
                display: "flex",
                color: "red",
                cursor: "pointer",
              }}
            />
            <div className="collab__head">
              <Typography
                id="modal-modal-title"
                sx={{
                  m: "12px ",
                  cursor: "pointer",
                  color: tab ? "#2196f3" : "#5E5873",
                }}
                variant="h6"
                component="h2"
                onClick={() => {
                  collabDispatch();
                  setTab(true);
                  setUsers([]);
                }}
              >
                Add Collaborators
              </Typography>

              <Typography
                id="modal-modal-title"
                sx={{
                  m: "12px ",
                  cursor: "pointer",
                  color: !tab ? "#2196f3" : "#5E5873",
                }}
                variant="h6"
                component="h2"
                onClick={() => {
                  collabDispatch();
                  setTab(false);
                }}
              >
                Existing Collaborators
              </Typography>
            </div>
            {tab && (
              <div className="modal">
                <Autocomplete
                  multiple
                  id="tags-readOnly"
                  options={
                    filteredUsers &&
                    filteredUsers?.map((option) => option?.name)
                  }
                  //   readOnly
                  value={users}
                  onChange={(event, value) => setUsers(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Add Users"
                      sx={{ m: 1, width: "700px" }}
                      placeholder="Add Users"
                    />
                  )}
                />
                <FormControl sx={{ m: 1, width: "700px", color: "black" }}>
                  <InputLabel>User Role Access</InputLabel>
                  <Select
                    id="outlined-textarea"
                    label="Multiline Placeholder"
                    onChange={(e) => handleChange(e)}
                    value={role}
                    name="role"
                  >
                    <MenuItem value={"edit"}>Edit</MenuItem>
                    <MenuItem value={"view"}>View</MenuItem>
                  </Select>
                </FormControl>

                <FormControl sx={{ m: 1, width: "700px", color: "black" }}>
                  <InputLabel>User Version Access</InputLabel>
                  {allVersions && allVersions.length > 0 ? (
                    <Select
                      value={selectedVersion}
                      onChange={handleVersionChange}
                      id="outlined-textarea"
                      label="Multiline Placeholder"
                      sx={{ color: "black" }}
                    >
                      {allVersions.map((version) => (
                        <MenuItem key={version.version} value={version.version}>
                          {version.version}
                        </MenuItem>
                      ))}
                      <MenuItem value={"all"}>All</MenuItem>
                    </Select>
                  ) : null}
                </FormControl>

                <Button
                  variant="contained"
                  sx={{ color: "white", m: 1, marginLeft: "auto" }}
                  onClick={userHandler}
                  disabled={
                    users.length === 0 ||
                    role.length === 0 ||
                    selectedVersion.length === 0
                  }
                >
                  Add
                </Button>
              </div>
            )}
            {!tab && (
              <ViewCollaborator
                projectId={props._id}
                selectedVersion={selectedVersion}
                users={users}
              />
            )}
          </Box>
        </Modal>
      </div>
    </>
  );
};
