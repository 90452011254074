import React, { useState, useEffect } from "react";
import App from "../App";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { Auth } from "./Auth/Auth";
import { Login } from "./Auth/Login";
import { Redirect } from "react-router-dom";
import { Department } from "./Pages/Department/Department";
import { Services } from "./Pages/Services/Services";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

export const RoutesComp = () => {
  const { state } = useLocation();
  const history = useHistory();
  const store = useSelector((store) => store);
  const [code, setCode] = useState(Cookies.get("code"));
  const [token, setToken] = useState(Cookies.get("token"));
  useEffect(() => {
    if (!code) {
      setCode(Cookies.get("code"));
    }
  }, [Cookies.get("code")]);
  useEffect(() => {
    if (!token) {
      setToken(Cookies.get("token"));
    }
  }, [Cookies.get("token")]);

  return (
    <>
      <Switch>
        <Route path="/auth/callback" component={Auth} />

        <Route path="/login" component={Login} />
        {!token ? <Redirect from="/" to="/login" /> : ""}
        {token ? <Route path="" component={App} /> : console.log("treu")}
      </Switch>
    </>
  );
};
