import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Slider,
  Box,
  Button,
  AccordionDetails,
} from "@mui/material";
import "./Calculate.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import axios from "axios";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useSelector, useDispatch } from "react-redux";
import {
  setProjectPricingData,
  setProjectPricingDepartmentData,
  setProjectPricingAdOnsData,
  addPricing,
  updatePricing,
  updateProfitMargin,
  addDiscount,
  getPricingVersions,
} from "../../../Redux/Projects/Action";
import { NavbarCal } from "../../Layouts/NavbarCal";
import { CircularProgress, Stack } from "@mui/material";
import Cookies from "js-cookie";
import { ViewModeCal } from "./ViewModeCal";
import { DepartmentCosting } from "./DepartmentCosting";
import Notes from "./Notes";
import VersionNotes from "./VersionNotes";
import Resources from "./Resources";
import Addons from "./Addons";
import { Notify } from "../../UI/Notify";

import {
  TextField,
  InputAdornment,
  FormControl,
  FormHelperText,
  OutlinedInput,
  InputLabel,
} from "@mui/material";

export const Calculate = () => {
  const [tabs, setTabs] = useState([]);
  const [adOnsExpand, setAdOnsExpand] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const [profitMargin, setProfitMargin] = useState(store.project.profitMargin);
  const [update, setUpdate] = useState(true);
  const [save, setSave] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [discount, setDiscount] = useState(0);
  const selectedVersion = useSelector((store) => store.project.selectedVersion);
  const collabs = useSelector((store) => store.project);
  const [activeCollab, setactiveCollab] = React.useState(null);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = params.get("page");

  const [notification, setNotification] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [preResource, setPreResource] = useState([]);

  //Getting all selected departments by user
  const allSelectedDepartments = useSelector(
    (store) => store?.department?.departments
  );

  //Deleted Department Data
  const deletedDeptContentData = useSelector(
    (store) => store.department?.deletedDepartmentData
  );

  //All deleted department not available on calculation screen
  const notAvailableDepartments = deletedDeptContentData?.reduce(
    (result, item) => {
      const notFound = store.project?.departments?.every(
        (dep) => dep.departmentName !== item.departmentName
      );
      if (notFound) {
        result.push(item);
      }
      return result;
    },
    []
  );

  //Type of Project
  const projectTypeName = useSelector(
    (store) => store.department.projectTypeName
  );
  const [projectType, setProjectType] = useState();

  useEffect(() => {
    if (projectTypeName.toLowerCase() === "retainer project") {
      setProjectType(true);
    } else {
      setProjectType(false);
    }
  }, []);

  const collabVersions = useSelector(
    (store) => store.project.projectData.collaborator
  );

  //Getting the Logged In user / Getting the version access
  useEffect(() => {
    if (page !== "shared-with-me") return;
    let allCollabs =
      collabVersions && collabVersions.length > 0 ? collabVersions[0] : null;
    if (allCollabs && allCollabs.collaborators) {
      let currentCollab = allCollabs.collaborators.filter(
        (item) => item.collaboratorId === Cookies.get("_id")
      )[0];
      setactiveCollab(currentCollab.versionAccess);
    }
  }, []);

  //If page === shared-with-me version changer = all / null / number
  //If page !== shared-with-me ; version changer = selected version
  const [versionChanger, setversionChanger] = useState(0);

  useEffect(() => {
    if (page === "shared-with-me" && activeCollab && activeCollab !== "all") {
      setversionChanger(Number(activeCollab));
      return;
    }
    setversionChanger(selectedVersion);
  }, [selectedVersion, page, activeCollab]);

  const downloadPdfHandler = async () => {
    if (
      store.project.updateProjectData._id &&
      page === "shared-with-me" &&
      activeCollab !== "all"
    ) {
      // dispatch(generatePdf(store.project.updateProjectData._id));
      const project_id = store.project.updateProjectData._id;
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/api/project/pricing/get-download-data/${project_id}/${versionChanger}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });
      const data = await response.data;
      window.open(data, "_blank");
      return response;
    } else if (
      store.project.updateProjectData._id &&
      page === "shared-with-me" &&
      activeCollab === "all"
    ) {
      const version = selectedVersion;
      // dispatch(generatePdf(store.project.updateProjectData._id));
      const project_id = store.project.updateProjectData._id;
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/api/project/pricing/get-download-data/${project_id}/${version}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });
      const data = await response.data;
      window.open(data, "_blank");
      return response;
    } else {
      // dispatch(generatePdf(store.project.updateProjectData._id));
      const project_id = store.project.updateProjectData._id;
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/api/project/pricing/get-download-data/${project_id}/${versionChanger}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });
      const data = await response.data;
      window.open(data, "_blank");
      return response;
    }
  };

  const downloadClientPdfHandler = async () => {
    if (
      store.project.updateProjectData._id &&
      page === "shared-with-me" &&
      activeCollab !== "all"
    ) {
      // dispatch(generatePdf(store.project.updateProjectData._id));
      const project_id = store.project.updateProjectData._id;
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/api/project/pricing/get-client-download-data/${project_id}/${versionChanger}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });
      const data = await response.data;
      window.open(data, "_blank");
      return response;
    } else if (
      store.project.updateProjectData._id &&
      page === "shared-with-me" &&
      activeCollab === "all"
    ) {
      const version = selectedVersion;
      const project_id = store.project.updateProjectData._id;
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/api/project/pricing/get-client-download-data/${project_id}/${version}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });
      const data = await response.data;
      window.open(data, "_blank");
      return response;
    } else {
      // dispatch(generatePdf(store.project.updateProjectData._id));
      const project_id = store.project.updateProjectData._id;
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/api/project/pricing/get-client-download-data/${project_id}/${versionChanger}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });
      const data = await response.data;
      window.open(data, "_blank");
      return response;
    }
  };

  useEffect(() => {
    if (store.project.pricingData) {
      dispatch(getPricingVersions(store.project?.projectData._id));
    }
  }, [store.project.pricingData]);

  const getAllProjects = () =>
    new Promise(async (resolve, reject) => {
      try {
        const resp = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/api/project/get-projects`,
          headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        });
        const currentProject = resp.data.filter((e) => e._id === id)[0];
        resolve(currentProject);
        setTabs(
          currentProject.departments.map((_, index) => {
            return { isExpanded: index === 0 };
          })
        );
      } catch (error) {
        reject("error");
      }
    });

  const getGenericDesignation = () =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/api/genericDesignation/get-generic-designation`,
          headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        });

        resolve(response.data);
      } catch (error) {
        reject("error");
      }
    });

  const getDesignationByGenericName = () =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/api/designation/get-designation-by-generic-name`,
          headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        });

        resolve(response.data);
      } catch (error) {
        reject("error");
      }
    });

  useEffect(() => {
    Promise.all([
      getAllProjects(),
      getGenericDesignation(),
      getDesignationByGenericName(),
    ])
      .then((results) => {
        const payload = {
          departments: results[0].departments,
          projectName: results[0].projectName,
          createdAt: results[0].createdAt,
          projectTypeName: results[0].projectTypeName,
          genericDesignation: results[1],
          designationByGenericName: results[2],
        };
        dispatch(setProjectPricingData(payload));
      })
      .then(() => {
        if (store.project.getMode) {
          let finalres = [];
          let deps = [];
          store.project.departments.map((item) => {
            let resources = [];
            resources = store.project.resources.filter(
              (e) => e.departmentId === item.departmentId
            );

            const newres = {
              genericDesignation: "",
              genericDesignationName: "",
              addOnCost: 0,
              checked: false,
              isAdded: false,
            };
            resources = [...resources, newres];
            deps = {
              ...item,
              resources,
            };
            finalres = [...finalres, deps];
            setPreResource(finalres);
          });
          dispatch(setProjectPricingDepartmentData(finalres, selectedVersion));
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleOnExpand = (index) => {
    const newTabs = [...tabs];
    newTabs[index].isExpanded = !newTabs[index].isExpanded;
    setTabs(newTabs);
  };

  const handleOnDelete = (
    _index,
    designationByGenericName,
    calculationFactor,
    _dept
  ) => {
    const prevDepartments = [...store.project.departments];
    const newDepartments = prevDepartments.map((dept) => {
      if (dept.departmentName === _dept) {
        const newRes = dept?.resources.filter(
          (item, index) => index !== _index
        );

        const newDept = {
          ...dept,
          resources: newRes,
        };

        return newDept;
      }

      return dept;
    });

    dispatch(setProjectPricingDepartmentData(newDepartments, selectedVersion));
    setSave(true);
  };

  const handleOnAdd = (
    _dept,
    _checked,
    _genericDesignation,
    _genericDesignationName,
    _calculationFactor
  ) => {
    const prevDepartments = [...store.project.departments];
    const _designationId = store.project.designationByGenericName.filter(
      (i) => _genericDesignationName === i.name
    );

    const newResource = {
      checked: _checked,
      genericDesignation: _genericDesignation,
      genericDesignationName: _genericDesignationName,
      designationId: _designationId[0]._id,
      calculationFactor: _calculationFactor,
      isAdded: true,
      isDeleted: false,
    };

    const newDepartments = prevDepartments.map((dept) => {
      if (dept.departmentName === _dept) {
        const prevRes = dept.resources || [];

        const newDept = {
          ...dept,
          resources: [...prevRes, newResource],
        };

        return newDept;
      }

      return dept;
    });

    dispatch(setProjectPricingDepartmentData(newDepartments));
    setSave(true);
  };

  const handleOnDeleteAdOn = (_index, _id, _genericDesignation) => {
    const prevAddons = [...store.project.addOns];
    const newAddons = prevAddons.filter((e, index) => _id !== e._id);
    dispatch(setProjectPricingAdOnsData(newAddons));
    setSave(true);
  };

  const handleOnAddAdOn = (
    _checked,
    _genericDesignation,
    _genericDesignationName,
    _addOnCost
  ) => {
    const prevAddons = [...store.project.addOns];
    prevAddons.pop();

    const newResource = {
      checked: _checked,
      genericDesignation: _genericDesignation,
      genericDesignationName: _genericDesignationName,
      addOnCost: _addOnCost,
      isAdded: true,
    };

    dispatch(
      setProjectPricingAdOnsData([
        ...prevAddons,
        newResource,
        {
          genericDesignation: "",
          genericDesignationName: "",
          addOnCost: 0,
          checked: false,
          isAdded: false,
        },
      ])
    );
    setSave(true);
  };

  const handleOnSlide = async () => {
    if (store.project.updateProjectData._id) {
      await dispatch(
        updateProfitMargin(
          {
            profitMargin: profitMargin,
          },
          store.project.updateProjectData._id,
          versionChanger
        )
      );
    }
  };

  const handleOnSave = async () => {
    if (store.project.departments.length <= 0) {
      return;
    }
    const mappedResources = [];
    store.project.departments.map((dept) => {
      dept.resources &&
        dept.resources.map((res) => {
          res.isAdded === true &&
            res.isDeleted === false &&
            res.genericDesignation != undefined &&
            res.genericDesignation != "" &&
            mappedResources.push({
              departmentName: dept.departmentName,
              departmentId: dept.departmentId,
              designationId: res.designationId,
              genericDesignationName: res.genericDesignation,
              designationName: res.genericDesignationName,
              calculationFactor: res.calculationFactor,
            });
        });
    });

    const mappedAddOns = [];
    store.project.addOns.map((addOn) => {
      addOn.isAdded === true &&
        addOn.genericDesignation !== undefined &&
        addOn.genericDesignation !== "" &&
        mappedAddOns.push({
          genericDesignationName: addOn.genericDesignation,
          designationName: addOn.genericDesignationName,
          addOnCost: addOn.addOnCost,
        });
    });

    const payload = {
      projectName: store.project.projectName,
      projectId: store.project.updateProjectData._id,
      projectTypeName: store.project.projectTypeName.toLowerCase(),
      profitMargin: profitMargin,
      resources: mappedResources,
      addOns: mappedAddOns,
    };
    let updatePayload;
    if (store.project.pricingData != null) {
      updatePayload = {
        _id: store.project.pricingId,
        projectId: store.project.updateProjectData._id,
        projectTypeName: store.project.projectTypeName.toLowerCase(),
        profitMargin: profitMargin,
        discount: discount,
        resources:
          store.project.pricingData.versions[selectedVersion].data[0].resources,
        addOns:
          store.project.pricingData.versions[selectedVersion].data[0].addOns,
      };
    }
    if (store.project.pricingData === null) {
      dispatch(addPricing(payload));
    } else {
      await dispatch(updatePricing(updatePayload));
      setNotification({
        isOpen: true,
        message: "New Successfully Version Created ",
        type: "success",
      });
    }
    setUpdate(false);
  };

  useEffect(() => {
    if (save) {
      handleOnSave();
      setSave(false);
    }
  }, [save]);
  useEffect(() => {
    if (store.project.getMode) {
      dispatch(
        setProjectPricingAdOnsData([
          ...store.project.preaddOns,
          {
            genericDesignation: "",
            genericDesignationName: "",
            addOnCost: 0,
            checked: false,
            isAdded: false,
          },
        ])
      );
    }
  }, []);

  /**
   * 1. calculates discount when have pricing info and selected version
   * 2. calculates profit margin
   */
  useEffect(() => {
    if (!store?.project?.pricingData || selectedVersion < 0) return;

    let currentVersion =
      activeCollab === "all"
        ? selectedVersion
        : activeCollab || selectedVersion;

    setDiscount(
      store?.project?.pricingData?.versions[currentVersion]?.data[0]?.discount
    );
    setProfitMargin(
      store?.project?.pricingData?.versions[currentVersion]?.data[0]
        ?.profitMargin
    );
  }, [selectedVersion, activeCollab, store?.project?.pricingData]);

  const discountHandler = (e) => {
    let enteredDiscount = parseFloat(e.target.value);

    let discountValue = isNaN(enteredDiscount) ? 0 : enteredDiscount;

    setDiscount(discountValue);

    if (
      store.project.updateProjectData._id ||
      discountValue >= 0 ||
      discountValue !== ""
    ) {
      dispatch(
        addDiscount(
          store.project.updateProjectData._id,
          {
            discount: discountValue,
          },
          selectedVersion
        )
      );
    }
  };

  return (
    <>
      <Notify notification={notification} setNotification={setNotification} />

      <div className="">
        <Grid container>
          <Grid item lg={12}>
            {store.project.departments.length > 0 && (
              <NavbarCal
                handleOnSave={handleOnSave}
                projectName={store.project.projectName}
                projectTypeName={store.project.projectTypeName}
                createdAt={store.project.createdAt}
                update={update}
              />
            )}
          </Grid>

          {!store.project.viewMode ? (
            <Grid item pt="63px" className="caldepts" lg={9}>
              <>
                <div className="cal__area">
                  <div className="addresourse">
                    {store.project.departments.length > 0 ? (
                      store.project.departments.map((item, index) => (
                        <Accordion
                          key={index}
                          expanded={tabs[index]?.isExpanded ?? false}
                          onChange={() => handleOnExpand(index)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography
                              variant="h6"
                              textTransform={"capitalize"}
                              sx={{ width: "33%", flexShrink: 0 }}
                            >
                              {item.departmentName}
                            </Typography>
                          </AccordionSummary>
                          <Resources
                            departmentId={item.departmentId}
                            defChecked={true}
                            genericDesignation={[
                              ...store.project.genericDesignation.filter(
                                (dept) =>
                                  dept.departmentName === item.departmentName
                              ),
                            ]}
                            designationByGenericName={
                              store.project.designationByGenericName
                            }
                            departmentName={item.departmentName}
                            isAdded={true}
                            handleOnAdd={handleOnAdd}
                            handleOnDelete={handleOnDelete}
                            activeCollab={activeCollab}
                            page={page}
                          />
                        </Accordion>
                      ))
                    ) : (
                      <span>
                        <Stack sx={{ margin: "0 500px" }}>
                          <CircularProgress disableShrink />
                        </Stack>
                      </span>
                    )}
                    <Accordion
                      expanded={adOnsExpand}
                      onChange={() => setAdOnsExpand(!adOnsExpand)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            width: "33%",
                            flexShrink: 0,
                            fontWeight: 500,
                            fontSize: "1.25rem",
                          }}
                        >
                          Add On
                        </Typography>
                      </AccordionSummary>
                      <Addons
                        action={false}
                        genericDesignation={store.project.genericDesignation}
                        designationByGenericName={
                          store.project.designationByGenericName
                        }
                        handleOnAddAdOn={handleOnAddAdOn}
                        handleOnDeleteAdOn={handleOnDeleteAdOn}
                        activeCollab={activeCollab}
                        page={page}
                      />
                    </Accordion>

                    {/* Deleted Department Accordion Starts here */}
                    {notAvailableDepartments &&
                      notAvailableDepartments?.length > 0 && (
                        <>
                          <h2 style={{ color: "red", marginLeft: "15px" }}>
                            Deleted Department
                          </h2>
                          {notAvailableDepartments.map((item, index) => (
                            <Accordion key={index} expanded={true}>
                              <AccordionSummary
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                              >
                                <Typography
                                  variant="h6"
                                  textTransform={"capitalize"}
                                  sx={{ width: "33%", flexShrink: 0 }}
                                >
                                  {item.departmentName}
                                </Typography>
                              </AccordionSummary>

                              <AccordionDetails
                                key={item._id}
                                sx={{ padding: "0", paddingBottom: "8px" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    alignItems: "center",
                                    padding: "0 20px",
                                  }}
                                >
                                  <TextField
                                    id="outlined-select-currency"
                                    defaultValue=""
                                    sx={{
                                      maxWidth: "290px",
                                      minWidth: "260px",
                                      backgroundColor: "#EFEFEF",
                                      mx: "20px",
                                    }}
                                    disabled={true}
                                    value={item?.genericDesignationName}
                                    size="small"
                                    fullWidth
                                  ></TextField>{" "}
                                  <TextField
                                    id="outlined-select-currency"
                                    defaultValue=""
                                    sx={{
                                      maxWidth: "290px",
                                      minWidth: "260px",
                                      backgroundColor: "#EFEFEF",
                                      mx: "20px",
                                    }}
                                    disabled={true}
                                    value={item?.designationName}
                                    size="small"
                                    fullWidth
                                  ></TextField>{" "}
                                  <FormControl
                                    sx={{
                                      maxWidth: "170px",
                                      minWidth: "120px",
                                      backgroundColor: "#EFEFEF",
                                      mx: "20px",
                                    }}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                  >
                                    <OutlinedInput
                                      id="outlined-adornment-weight"
                                      type="number"
                                      disabled={true}
                                      required={true}
                                      endAdornment={
                                        <InputAdornment position="end">
                                          {projectType ? "%" : "Days"}
                                        </InputAdornment>
                                      }
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        "aria-label": "weight",
                                      }}
                                      value={item?.calculationFactor}
                                    />
                                  </FormControl>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                        </>
                      )}
                  </div>
                  <div className="calpercentage">
                    <div className="calper__upperflex">
                      <Typography m={0} p={0} variant="body1" fontWeight="600">
                        Add Profit Margin
                      </Typography>
                      <Typography className="calperdisplay">
                        {profitMargin} %
                      </Typography>
                    </div>
                    <Typography
                      sx={{ color: "#0f172a" }}
                      fontWeight="regular"
                      variant="subtitle2"
                    >
                      Tip: Keep minimum of 33% as profit margin to calculate the
                      final cost final cost
                    </Typography>

                    <Box sx={{ maxWidth: "1000", color: "#0f172a" }}>
                      <Slider
                        aria-label="Restricted values"
                        sx={{
                          color: profitMargin < 11 ? "#ef5350" : "#202124",
                          marginRight: "20px",
                        }}
                        valueLabelDisplay="on"
                        value={profitMargin || 33}
                        defaultValue={profitMargin}
                        onChange={(e) => setProfitMargin(e.target.value)}
                        onChangeCommitted={handleOnSlide}
                        max={100}
                        min={1}
                      />
                    </Box>
                  </div>
                </div>
              </>
            </Grid>
          ) : (
            <>
              {/* <div className="scroll"> */}
              <Grid item pt="63px" className="scroll" lg={9}>
                {store.project?.pricingData ? (
                  <div className="viewMode">
                    {store.project?.pricingData
                      ? store.project?.pricingData?.versions[activeCollab]
                          ?.data[0].resources.length !== 0 && (
                          <>
                            <Typography
                              variant="h6"
                              mb="20px"
                              sx={{ color: "#0F172A" }}
                            >
                              Resources
                            </Typography>
                            <ViewModeCal
                              resources={
                                page === "shared-with-me" && activeCollab >= 0
                                  ? store.project?.pricingData?.versions[
                                      activeCollab
                                    ]?.data[0]?.resources
                                  : store.project?.pricingData?.versions[
                                      selectedVersion
                                    ]?.data[0]?.resources
                              }
                              action={true}
                            />
                          </>
                        )
                      : null}
                    {store.project?.pricingData
                      ? store.project?.pricingData?.versions[activeCollab]
                          ?.data[0].resources.length !== 0 && (
                          <>
                            <Typography
                              variant="h6"
                              my="20px"
                              sx={{ color: "#0F172A" }}
                            >
                              Add Ons
                            </Typography>
                            <ViewModeCal
                              addOns={
                                page === "shared-with-me" && activeCollab >= 0
                                  ? store.project?.pricingData?.versions[
                                      activeCollab
                                    ]?.data[0]?.addOns
                                  : store.project?.pricingData?.versions[
                                      selectedVersion
                                    ]?.data[0]?.addOns
                              }
                              action={false}
                            />
                          </>
                        )
                      : null}
                    <Typography
                      variant="h6"
                      my="20px"
                      sx={{ color: "#0F172A" }}
                    >
                      Departments Cost
                    </Typography>

                    <DepartmentCosting
                      departmentsCost={
                        page === "shared-with-me" && activeCollab >= 0
                          ? store.project?.pricingData?.versions[activeCollab]
                              ?.data[0]?.departmentsCost
                          : store.project?.pricingData?.versions[
                              selectedVersion
                            ]?.data[0]?.departmentsCost
                      }
                    />
                  </div>
                ) : (
                  <h1>Loading . . .</h1>
                )}
              </Grid>
              {/* </div> */}
            </>
          )}

          <Grid item pt="60px" className="calculation" lg={3}>
            <div className="calculation__display">
              <div className="calculation__item">
                <div>
                  <Typography
                    sx={{ color: "white" }}
                    fontFamily="Arial"
                    variant="caption"
                  >
                    Deliverables Notes
                  </Typography>
                  <br />
                  {store.project.projectData !== null ? (
                    <Notes notes={store.project?.projectData.notes} />
                  ) : (
                    <Notes notes="Empty" />
                  )}
                </div>
                <div>
                  <Typography
                    sx={{ color: "white" }}
                    fontFamily="Arial"
                    variant="caption"
                  >
                    Version Notes
                  </Typography>
                  <br />
                  {store.project.projectData !== null &&
                  page === "shared-with-me" &&
                  activeCollab >= 0 ? (
                    <VersionNotes
                      projectId={store.project.updateProjectData._id}
                      version={activeCollab}
                    />
                  ) : (
                    <VersionNotes
                      projectId={store.project.updateProjectData._id}
                      version={store.project.selectedVersion}
                    />
                  )}
                </div>
              </div>

              <div className="calculation__item">
                <div className="calpadding">
                  <Typography
                    sx={{ color: "white" }}
                    fontFamily="Arial"
                    variant="caption"
                  >
                    Total no of Resources
                  </Typography>
                  <Typography
                    sx={{ color: "white", fontSize: "22px" }}
                    fontWeight="regular"
                    variant="h6"
                  >
                    {page === "shared-with-me" && activeCollab >= 0
                      ? store.project?.pricingData?.versions[activeCollab]
                          ?.data[0]?.totalResources
                      : store.project?.pricingData?.versions[selectedVersion]
                          ?.data[0]?.totalResources}
                  </Typography>
                </div>
                <div className="calpadding">
                  <Typography
                    sx={{ color: "white" }}
                    fontFamily="Arial"
                    variant="caption"
                  >
                    Cost to Company
                  </Typography>
                  <Typography
                    sx={{ color: "white", fontSize: "22px" }}
                    fontWeight="regular"
                    variant="h6"
                  >
                    <span>₹</span>{" "}
                    {page === "shared-with-me" && activeCollab >= 0
                      ? store.project?.pricingData?.versions[
                          activeCollab
                        ]?.data[0]?.costToCompany.toLocaleString("en-IN")
                      : store.project?.pricingData?.versions[
                          selectedVersion
                        ]?.data[0]?.costToCompany.toLocaleString("en-IN")}
                  </Typography>
                </div>
              </div>
              <div className="calpadding">
                <div className="calculation__item">
                  <div>
                    <Typography
                      sx={{ color: "white" }}
                      fontFamily="Arial"
                      variant="caption"
                    >
                      Profit (
                      {page === "shared-with-me" && activeCollab >= 0
                        ? store.project?.pricingData?.versions[activeCollab]
                            ?.data[0]?.profitMargin
                        : store.project?.pricingData?.versions[selectedVersion]
                            ?.data[0]?.profitMargin}
                      %)
                    </Typography>
                    <br />
                    {discount > 0 && (
                      <Typography
                        sx={{ color: "white" }}
                        fontFamily="Arial"
                        variant="caption"
                      >
                        Before Discount
                      </Typography>
                    )}
                    <Typography
                      sx={{ color: "white", fontSize: "22px" }}
                      fontWeight="regular"
                      variant="h6"
                    >
                      ₹{" "}
                      {page === "shared-with-me" && activeCollab >= 0
                        ? store.project?.pricingData?.versions[
                            activeCollab
                          ]?.data[0]?.profitToCompany.toLocaleString("en-IN")
                        : store.project?.pricingData?.versions[
                            selectedVersion
                          ]?.data[0]?.profitToCompany.toLocaleString("en-IN")}
                    </Typography>
                  </div>
                  {discount > 0 && (
                    <div>
                      <br />
                      <Typography
                        sx={{ color: "white" }}
                        fontFamily="Arial"
                        variant="caption"
                      >
                        After Discount
                      </Typography>
                      <Typography
                        sx={{ color: "white", fontSize: "22px" }}
                        fontWeight="regular"
                        variant="h6"
                      >
                        ₹{" "}
                        {page === "shared-with-me" && activeCollab >= 0
                          ? store.project?.pricingData?.versions[
                              activeCollab
                            ]?.data[0]?.profitToCompanyAfterDiscount.toLocaleString(
                              "en-IN"
                            )
                          : store.project?.pricingData?.versions[
                              selectedVersion
                            ]?.data[0]?.profitToCompanyAfterDiscount.toLocaleString(
                              "en-IN"
                            )}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
              <div className="calpadding">
                <div className="calculation__item">
                  <div>
                    <Typography
                      sx={{ color: "white" }}
                      fontFamily="Arial"
                      variant="caption"
                    >
                      Cost to Client
                    </Typography>
                    <br />
                    {discount > 0 && (
                      <Typography
                        sx={{ color: "white" }}
                        fontFamily="Arial"
                        variant="caption"
                      >
                        Before Discount
                      </Typography>
                    )}
                    <Typography
                      sx={{ color: "white", fontSize: "22px" }}
                      fontWeight="regular"
                      variant="h5"
                    >
                      ₹{" "}
                      {page === "shared-with-me" && activeCollab >= 0
                        ? store.project?.pricingData?.versions[
                            activeCollab
                          ]?.data[0]?.costToClient.toLocaleString("en-IN")
                        : store.project?.pricingData?.versions[
                            selectedVersion
                          ]?.data[0]?.costToClient.toLocaleString("en-IN")}
                    </Typography>
                  </div>
                  {discount > 0 && (
                    <div>
                      <br />
                      <Typography
                        sx={{ color: "white" }}
                        fontFamily="Arial"
                        variant="caption"
                      >
                        After Discount
                      </Typography>
                      <Typography
                        sx={{ color: "white", fontSize: "22px" }}
                        fontWeight="regular"
                        variant="h5"
                      >
                        ₹{" "}
                        {page === "shared-with-me" && activeCollab >= 0
                          ? store.project?.pricingData?.versions[
                              activeCollab
                            ]?.data[0]?.costToClientAfterDiscount.toLocaleString(
                              "en-IN"
                            )
                          : store.project?.pricingData?.versions[
                              selectedVersion
                            ]?.data[0]?.costToClientAfterDiscount.toLocaleString(
                              "en-IN"
                            )}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
              <div className="calpadding">
                <div className="calculation__item">
                  <FormControl
                    sx={{
                      maxWidth: "100px",
                      minWidth: "70px",
                      borderRadius: "5px",
                    }}
                    variant="outlined"
                    type="number"
                    size="small"
                    label="Discount"
                  >
                    <FormHelperText
                      sx={{ color: "white" }}
                      id="outlined-weight-helper-text"
                    >
                      Discount
                    </FormHelperText>
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      disabled={store.project.viewMode}
                      sx={{ backgroundColor: "white" }}
                      min={0}
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                      value={discount}
                      onChange={discountHandler}
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="calpadding">
                <div className="calculation__item">
                  <div>
                    <Typography
                      sx={{ color: "white" }}
                      fontFamily="Arial"
                      variant="caption"
                    >
                      Download PDF
                    </Typography>
                    <br />
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "white",
                        color: "black",
                        height: "40px",
                      }}
                      startIcon={<FileDownloadOutlinedIcon />}
                      onClick={downloadClientPdfHandler}
                    >
                      Client
                    </Button>
                  </div>
                  <div>
                    <br />
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "white",
                        color: "black",
                        height: "40px",
                      }}
                      startIcon={<FileDownloadOutlinedIcon />}
                      onClick={downloadPdfHandler}
                    >
                      Company
                    </Button>
                  </div>
                </div>
              </div>
              {/* <div className="calpadding">
                <div className="calculation__item">
                  <div>
                    <Typography
                      sx={{ color: "white" }}
                      fontFamily="Arial"
                      variant="caption"
                    >
                      Version Notes
                    </Typography>
                    <br />
                    {store.project.projectData !== null ? (
                      <VersionNotes
                        projectId={store.project.updateProjectData._id}
                        version={store.project.selectedVersion}
                      />
                    ) : (
                      <VersionNotes versionNotes="Empty" />
                    )}
                  </div>
                </div>
              </div> */}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
