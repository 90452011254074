import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DescriptionIcon from "@mui/icons-material/Description";
import "./Calculate.css";

const drawerWidth = 390;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function Notes(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <CssBaseline />
      <Toolbar>
        <Button
          onClick={handleDrawerOpen}
          startIcon={<DescriptionIcon />}
          sx={{
            ...(open && {
              display: "none",
            }),
            backgroundColor: "white",
            fontSize: "10px",
            marginLeft: "-20px",
          }}
          variant="contained"
        >
          Notes
        </Button>
      </Toolbar>

      <Drawer
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            marginTop: "64px",
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
          <Typography variant="h6">Notes/Deliverables</Typography>
        </DrawerHeader>
        <Divider />
        <div
          style={{ padding: "15px", overflowY: "auto" }}
          className="notecontent"
        >
          {props.notes}
        </div>
      </Drawer>
    </Box>
  );
}
