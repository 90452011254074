import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  InputAdornment,
  FormControl,
  OutlinedInput,
  MenuItem,
  AccordionDetails,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addAddons,
  deleteAddons,
  updateAddons,
} from "../../../Redux/Projects/Action";

const AdOns = (props) => {
  const [selectedGenericDesignation, setSelectedGenericDesignation] =
    useState("");
  const store = useSelector((store) => store);

  const [designationByGenericName, setDesignationByGenericName] = useState("");
  const [addOnCost, setAddOnCost] = useState("");
  const [checked, setChecked] = useState(props.defChecked);
  const [id, setId] = useState("");
  const [update, setUpdate] = useState(false);
  const selectedVersion = useSelector((store) => store.project.selectedVersion);

  const dispatch = useDispatch();

  //If page === shared-with-me version changer = all / null / number
  //If page !== shared-with-me ; version changer = selected version
  const [versionChanger, setversionChanger] = useState(0);

  useEffect(() => {
    if (
      props.page === "shared-with-me" &&
      props.activeCollab &&
      props.activeCollab !== "all"
    ) {
      setversionChanger(Number(props.activeCollab));
      return;
    }
    setversionChanger(selectedVersion);
  }, [selectedVersion, props.page, props.activeCollab]);

  //Add On Cost handler
  const AddOnCostChange = (e) => {
    let enteredAddOn = e.target.value;

    // Remove non-numeric characters except '.'
    enteredAddOn = enteredAddOn.replace(/[^0-9.]/g, "");

    // Ensure the value is not empty and greater than 0
    let AddOnValue =
      enteredAddOn !== "" && parseFloat(enteredAddOn) > 0 ? enteredAddOn : "";

    setAddOnCost(AddOnValue);
  };

  const updateAddOn = (
    selectedGenericDesignation,
    designationByGenericName,
    addOnCost
  ) => {
    const data = {
      genericDesignationName: selectedGenericDesignation,
      designationName: designationByGenericName,
      addOnCost: addOnCost,
    };
    const projectId = store.project.updateProjectData._id;
    dispatch(updateAddons(data, projectId, id, selectedVersion));
    clearDataHandler();
    setUpdate(false);
  };
  const addAddOn = (
    selectedGenericDesignation,
    designationByGenericName,
    addOnCost
  ) => {
    const data = {
      genericDesignationName: selectedGenericDesignation,
      designationName: designationByGenericName,
      addOnCost: addOnCost,
    };
    const projectId = store.project.updateProjectData._id;
    dispatch(addAddons(data, projectId, selectedVersion));
    clearDataHandler();
  };

  const deleteAddOn = (elm) => {
    const projectId = store.project.updateProjectData._id;
    dispatch(deleteAddons(projectId, elm._id, selectedVersion));
    clearDataHandler();
    setUpdate(false);
  };

  const editAddOn = (elm) => {
    setSelectedGenericDesignation(elm.genericDesignationName);
    setDesignationByGenericName(elm.designationName);
    setAddOnCost(elm.addOnCost);
    setId(elm._id);
    setUpdate(true);
  };
  const clearDataHandler = () => {
    setSelectedGenericDesignation("");
    setDesignationByGenericName("");
    setAddOnCost("");
    setId("");
  };

  return (
    <>
      <AccordionDetails sx={{ padding: "0", paddingBottom: "8px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItem: "center",
            padding: "0 20px",
          }}
        >
          {/* <Checkbox
          checked={checked}
          onChange={() => setChecked(!checked)}
          sx={{ color: !checked ? "#333" : "#FFF" }}
        /> */}

          <TextField
            id="outlined-select-currency"
            select
            sx={{
              maxWidth: "330px",
              minWidth: "280px",
              mx: "20px",
            }}
            size="small"
            value={selectedGenericDesignation}
            onChange={(e) => setSelectedGenericDesignation(e.target.value)}
          >
            {props.genericDesignation.map((option) => (
              <MenuItem key={option._id} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            id="outlined-select-currency"
            // select
            sx={{
              maxWidth: "330px",
              minWidth: "280px",
              mx: "20px",
            }}
            size="small"
            value={designationByGenericName}
            onChange={(e) => setDesignationByGenericName(e.target.value)}
          />
          <FormControl
            sx={{
              maxWidth: "170px",
              minWidth: "120px",
              mx: "20px",
            }}
            variant="outlined"
            type="number"
            size="small"
          >
            <OutlinedInput
              id="outlined-adornment-weight"
              type="number"
              endAdornment={<InputAdornment position="end">Rs</InputAdornment>}
              inputProps={{
                "aria-label": "weight",
              }}
              value={addOnCost}
              onChange={AddOnCostChange}
            />
          </FormControl>
          {store.project.pricingData === null ? (
            <Button
              disabled={
                //   !checked ||
                selectedGenericDesignation === "" ||
                designationByGenericName === "" ||
                addOnCost === ""
              }
              variant="contained"
              sx={{
                backgroundColor: "#2C2C2C",
                color: "white",
                width: "80px",
                fontSize: "12px",
                fontFamily: "Arial",
              }}
              onClick={() => {
                props.handleOnAddAdOn(
                  checked,
                  selectedGenericDesignation,
                  designationByGenericName,
                  addOnCost
                );
                clearDataHandler();
              }}
            >
              Add1
            </Button>
          ) : (
            <Button
              disabled={
                //   !checked ||
                selectedGenericDesignation === "" ||
                designationByGenericName === "" ||
                addOnCost === ""
              }
              variant="contained"
              sx={{
                backgroundColor: "#2C2C2C",
                color: "white",
                width: "80px",
                fontSize: "12px",
                fontFamily: "Arial",
              }}
              onClick={() =>
                !update
                  ? addAddOn(
                      selectedGenericDesignation,
                      designationByGenericName,
                      addOnCost
                    )
                  : updateAddOn(
                      selectedGenericDesignation,
                      designationByGenericName,
                      addOnCost
                    )
              }
            >
              {!update ? "Add" : "Update"}
            </Button>
          )}
          {/* <Button
            disabled={
              props.action ||
              selectedGenericDesignation === "" ||
              designationByGenericName === "" ||
              addOnCost === ""
            }
            variant="contained"
            sx={{
              backgroundColor: "#2C2C2C",
              color: "white",
              width: "80px",
              marginLeft: "10px",
              fontSize: "12px",
              fontFamily: "Arial",
            }}
            onClick={() =>
              updateAddOn(
                props.full,
                selectedGenericDesignation,
                designationByGenericName,
                addOnCost
              )
            }
          >
            Update
          </Button> */}
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#2C2C2C",
              color: "white",
              width: "80px",
              fontSize: "12px",
              fontFamily: "Arial",
              marginLeft: "10px",

              visibility: "hidden",
            }}
          >
            edit
          </Button>
        </div>
      </AccordionDetails>
      {store.project.pricingData && versionChanger >= 0
        ? store.project.pricingData.versions[versionChanger].data[0].addOns.map(
            (elm) => (
              <AccordionDetails sx={{ padding: "0", paddingBottom: "8px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItem: "center",
                    padding: "0 20px",
                  }}
                >
                  {/* <Checkbox
         checked={checked}
         onChange={() => setChecked(!checked)}
         sx={{ color: !checked ? "#333" : "#FFF" }}
       /> */}
                  <TextField
                    id="outlined-select-currency"
                    defaultValue={""}
                    sx={{
                      maxWidth: "330px",
                      minWidth: "280px",
                      backgroundColor: "#EFEFEF",
                      mx: "20px",
                    }}
                    disabled="true"
                    size="small"
                    value={elm.genericDesignationName}
                  >
                    {props.genericDesignation.map((option) => (
                      <MenuItem key={option._id} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    id="outlined-select-currency"
                    // select
                    sx={{
                      maxWidth: "330px",
                      minWidth: "280px",
                      backgroundColor: "#EFEFEF",
                      mx: "20px",
                    }}
                    disabled="true"
                    size="small"
                    value={elm.designationName}
                    onChange={(e) =>
                      setDesignationByGenericName(e.target.value)
                    }
                  />
                  <FormControl
                    sx={{
                      maxWidth: "170px",
                      minWidth: "120px",
                      backgroundColor: "#EFEFEF",
                      mx: "20px",
                    }}
                    variant="outlined"
                    type="number"
                    size="small"
                  >
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      type="number"
                      endAdornment={
                        <InputAdornment position="end">Rs</InputAdornment>
                      }
                      disabled="true"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                      value={elm.addOnCost}
                    />
                  </FormControl>
                  {store.project.pricingData === null ? (
                    <Button
                      disabled={
                        //   !checked ||
                        selectedGenericDesignation === "" ||
                        designationByGenericName === "" ||
                        addOnCost === ""
                      }
                      variant="contained"
                      sx={{
                        backgroundColor: "#2C2C2C",
                        color: "white",
                        width: "80px",
                        fontSize: "12px",
                        fontFamily: "Arial",
                      }}
                      onClick={() =>
                        !props.isAdded
                          ? props.handleOnAddAdOn(
                              checked,
                              selectedGenericDesignation,
                              designationByGenericName,
                              addOnCost
                            )
                          : props.handleOnDeleteAdOn(
                              props.elmIndex,
                              props.full._id,
                              selectedGenericDesignation
                            )
                      }
                    >
                      {props.isAdded ? "Delete" : "Add1"}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#2C2C2C",
                        color: "white",
                        width: "80px",
                        fontSize: "12px",
                        fontFamily: "Arial",
                      }}
                      onClick={() => deleteAddOn(elm)}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#2C2C2C",
                      color: "white",
                      width: "80px",
                      marginLeft: "10px",
                      fontSize: "12px",
                      fontFamily: "Arial",
                    }}
                    onClick={() => editAddOn(elm)}
                  >
                    Edit
                  </Button>
                </div>
              </AccordionDetails>
            )
          )
        : null}
    </>
  );
};

export default AdOns;
