import * as React from "react";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Cookies from "js-cookie";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useHistory, Link } from "react-router-dom";
import {
  clearDepartment,
  deletedDepartmentData,
} from "../../Redux/Data/Action";
import {
  clearAllVersionsOfProject,
  clearProject,
  markAsSold,
  projectLogo,
} from "../../Redux/Projects/Action";
import { useDispatch, useSelector } from "react-redux";
import SaveIcon from "@mui/icons-material/Save";
import { Avatar, Button, Tooltip } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Notify } from "../UI/Notify";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { selectVersion } from "../../Redux/Projects/Action";
import "./NavbarCal.css";
import { useEffect } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const style = {
  position: "absolute",
  display: "flex",
  alignItem: "center",
  flexDirection: "column",
  justifyContent: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
};

export const NavbarCal = (props) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [userName, setuserName] = React.useState("");
  const [date1, setDate1] = React.useState("");
  const [save, setSave] = React.useState(true);
  const [logo, setLogo] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [activeCollab, setactiveCollab] = React.useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = params.get("page");

  const [notification, setNotification] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((store) => store.department);
  const projects = useSelector((store) => store.project);
  const [version, setVersion] = React.useState(projects?.selectedVersion);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const projectId = useSelector((store) => store.project.updateProjectData._id);
  const [isSold, setIsSold] = React.useState(false);
  const allVersionsOfProject = useSelector(
    (store) => store.project?.allVersionsofProject
  );

  const versionWithTrue = allVersionsOfProject?.versions?.filter(
    (version) => version?.soldStatus
  );

  /**
   * filter by version
   * also compared the latest version
   */
  const versionFilter = (currentVersion) => {
    let allVrsionData = allVersionsOfProject?.versions;

    if (!allVrsionData || allVrsionData.length === 0) return;

    // this will alwway be bigger or equal to current selected version
    let latestVersionData = allVrsionData[allVrsionData.length - 1];

    // selected version
    let currentVersionData = allVrsionData.filter(
      (item) => item.version === currentVersion
    )[0];

    let deletedDeptContentData = [];

    // if the latest version is same as seleted then do nothing jsut return
    if (latestVersionData.version === currentVersionData.version)
      return deletedDeptContentData;

    // resources data arr for latest and current selected version
    let latestVerionDataResources = latestVersionData?.data[0]?.resources;
    let currentVerionDataResources = currentVersionData?.data[0]?.resources;

    // selected dept. data arr for latest and current selected version
    let latestVerionDataDept = latestVersionData?.data[0]?.selectedDepartments;

    let currentVerionDataDept =
      currentVersionData?.data[0]?.selectedDepartments;

    let latestVersionResourceDataObject = {};
    let currentVersionResourceDataObject = {};

    for (let i = 0; i < latestVerionDataResources.length; i++) {
      latestVersionResourceDataObject[
        latestVerionDataResources[i].departmentName
      ] = latestVerionDataResources[i];
    }

    for (let i = 0; i < currentVerionDataResources.length; i++) {
      currentVersionResourceDataObject[
        currentVerionDataResources[i].departmentName
      ] = currentVerionDataResources[i];
    }

    let highestLengthObj =
      Object.keys(latestVersionResourceDataObject).length >
      Object.keys(currentVersionResourceDataObject).length
        ? latestVersionResourceDataObject
        : currentVersionResourceDataObject;

    let lowestLengthObj =
      Object.keys(latestVersionResourceDataObject).length >
      Object.keys(currentVersionResourceDataObject).length
        ? currentVersionResourceDataObject
        : latestVersionResourceDataObject;

    for (const [key, value] of Object.entries(highestLengthObj)) {
      if (!(key in lowestLengthObj)) {
        deletedDeptContentData.push(highestLengthObj[key]);
      }
    }

    return deletedDeptContentData;
  };

  const handleChange = (event) => {
    setVersion(event.target.value);
    dispatch(selectVersion(event.target.value));

    let data = versionFilter(event.target.value);
    dispatch(deletedDepartmentData(data));
  };

  const collabVersions = useSelector(
    (store) => store.project.projectData.collaborator
  );

  //Getting the Logged In user / Getting the version access
  useEffect(() => {
    if (page !== "shared-with-me") return;
    let allCollabs =
      collabVersions && collabVersions.length > 0 ? collabVersions[0] : null;
    if (allCollabs && allCollabs.collaborators) {
      let currentCollab = allCollabs.collaborators.filter(
        (item) => item.collaboratorId === Cookies.get("_id")
      )[0];
      setactiveCollab(currentCollab);
    }
  }, []);

  if (userName) {
    const splitName = userName.split(" ");
    const firstNameInitial = splitName[0].charAt(0);
    const lastNameInitial = splitName[1].charAt(0);
    var initials = firstNameInitial + lastNameInitial;
  }

  useEffect(() => {
    if (page === "shared-with-me" && activeCollab?.versionAccess !== "all") {
      setVersion(activeCollab?.versionAccess);
      return;
    }
    setVersion(projects.selectedVersion);
  }, [projects.selectedVersion, activeCollab]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    dispatch(clearProject());
    dispatch(clearDepartment());
    Cookies.remove("token");
    Cookies.remove("code");
    history.push("/login");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dT = new Date().toLocaleString();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  React.useEffect(() => {
    if (!userName) {
      setuserName(Cookies.get("userName"));
    }
  }, [Cookies.get("userName")]);

  const clearDataHandler = () => {
    dispatch(clearDepartment());
    dispatch(clearProject());
    dispatch(clearAllVersionsOfProject());
    dispatch(deletedDepartmentData([]));
  };

  const logoHandler = async (e) => {
    if (logo) {
      const fd = new FormData();
      fd.append("logo", logo);
      if (projects.updateProjectData._id) {
        await dispatch(projectLogo(projects.updateProjectData._id, fd))
          .then((response) => {
            setNotification({
              isOpen: true,
              message: "Successfully Added LOGO",
              type: "success",
            });
          })
          .catch((error) => {
            setNotification({
              isOpen: true,
              message: error.response?.data?.error || "Error occurred",
              type: "error",
            });
          });
      }
    } else {
      setNotification({
        isOpen: true,
        message: "Please select a file to upload",
        type: "error",
      });
    }
  };

  //Mark Sold
  function handleSoldButtonClick() {
    if (versionWithTrue[0]?.version === version) {
      // Unmark as sold
      const data = {
        status: false,
      };
      dispatch(markAsSold(data, projectId, version));
      setIsSold(false);
      setNotification({
        isOpen: true,
        message: `Version - ${version} Sold Status Updated`,
        type: "success",
      });
    } else {
      // Mark as sold
      const data = {
        status: true,
      };
      dispatch(markAsSold(data, projectId, version));
      setIsSold(true);
      setNotification({
        isOpen: true,
        message: `Version - ${version} Sold Status Updated`,
        type: "success",
      });
    }
  }

  return (
    <>
      <Notify notification={notification} setNotification={setNotification} />

      <AppBar position="fixed" sx={{ backgroundColor: "white", padding: "0" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Link to="/dashboard" sx={{ marginRight: "40px" }}>
                <HomeOutlinedIcon size="large" onClick={clearDataHandler} />
              </Link>

              <div role="presentation">
                <Breadcrumbs sx={{ margin: "0 40px" }} aria-label="breadcrumb">
                  {true && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* <Button onClick={handleOpenModal}>Open modal</Button> */}

                      <img
                        src={projects.logo}
                        width="41px"
                        height="41px"
                        style={{
                          borderRadius: "50%",
                          cursor: "pointer",
                          border: "3px solid var(--primary)",
                        }}
                        alt=""
                      />

                      <EditIcon
                        onClick={handleOpenModal}
                        sx={{
                          color: "#D9D9D9",
                          position: "absolute",
                          cursor: "pointer",
                          width: "39px",
                          height: "39px",
                          padding: "10px",
                          borderRadius: "50%",
                          backgroundColor: "rgba(0,0,0,0.5)",
                          zIndex: 2,
                        }}
                      />
                      <Modal
                        open={openModal}
                        onClose={handleCloseModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <CloseIcon
                            color="danger"
                            onClick={handleCloseModal}
                            sx={{
                              marginLeft: "auto",
                              display: "flex",
                              cursor: "pointer",
                            }}
                          />
                          <Typography
                            sx={{
                              display: "flex",
                              m: 1,
                              justifyContent: "center ",
                            }}
                            variant="h6"
                          >
                            Add Logo
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              m: 1,

                              padding: "20px 10px",
                            }}
                          >
                            <input
                              id="filePicker"
                              type="file"
                              name="logo"
                              accept="image/png, image/gif, image/jpeg"
                              onChange={(e) => {
                                setLogo(e.target.files[0]);
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              sx={{
                                backgroundColor: "var(--secondary)",
                                color: "white",
                                padding: "2px 7px",
                                cursor: "pointer",
                                width: "80px",

                                borderRadius: "5px",
                                margin: "10px 0",
                              }}
                              onClick={logoHandler}
                            >
                              <CloudUploadIcon
                                sx={{ marginRight: "7px", fontSize: "18px" }}
                              />
                              Logo
                            </Button>
                          </div>
                        </Box>
                      </Modal>
                    </div>
                  )}

                  <span>
                    {/* {projects.updateProjectData.projectTypeName &&
                    projects.updateProjectData.projectTypeName} */}
                    {props.projectTypeName}
                  </span>
                  <Typography color="text.primary">
                    {/* {projects?.updateProjectData.projectName &&
                    projects?.updateProjectData.projectName} */}
                    {props.projectName}
                    &nbsp;-&nbsp;
                    {props.createdAt && props.createdAt.split("T")[0]}
                    {/* {projects.updateProjectData.updatedAt &&
                    projects?.updateProjectData.updatedAt.split("T")[0]} */}
                  </Typography>
                </Breadcrumbs>
              </div>
            </Box>

            <div className="soldButton">
              {projects.pricingData !== null && page !== "shared-with-me" && (
                <>
                  {versionWithTrue &&
                    versionWithTrue.length > 0 &&
                    versionWithTrue[0]?.version === version && (
                      <Tooltip
                        title={<span style={{ fontSize: "14px" }}>Unsell</span>}
                        arrow
                      >
                        <Button
                          className={`btn-sold active`}
                          onClick={handleSoldButtonClick}
                        >
                          Sold
                        </Button>
                      </Tooltip>
                    )}
                  {!(
                    versionWithTrue &&
                    versionWithTrue.length > 0 &&
                    versionWithTrue[0]?.version === version
                  ) && (
                    <Button
                      className={`btn-sold ${
                        versionWithTrue &&
                        versionWithTrue.length > 0 &&
                        versionWithTrue[0]?.version === version
                          ? "active"
                          : ""
                      }`}
                      onClick={handleSoldButtonClick}
                    >
                      {versionWithTrue &&
                      versionWithTrue.length > 0 &&
                      versionWithTrue[0]?.version === version
                        ? "Sold"
                        : isSold
                        ? "Mark as Sold"
                        : "Mark as Sold"}
                    </Button>
                  )}
                </>
              )}
            </div>

            <div className="navbar">
              {projects.pricingData != null && (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Version</InputLabel>
                  {projects?.pricingVersions &&
                    projects?.pricingVersions.length > 0 && (
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue={""}
                        value={`${version}`}
                        disabled={
                          activeCollab &&
                          activeCollab?.versionAccess &&
                          activeCollab?.versionAccess !== "all"
                            ? true
                            : false
                        }
                        sx={{
                          padding: "5px 0px 5px 8px !important",
                          width: "150px",
                          marginRight: "15px",
                        }}
                        label="Version"
                        onChange={handleChange}
                      >
                        {projects?.pricingVersions.map((item, index) => (
                          <MenuItem key={index} value={item.version}>
                            Version - {`${item.version}`}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                </FormControl>
              )}
            </div>

            <Box sx={{ flexGrow: 0, display: "flex", alignItems: "center" }}>
              {projects.pricingData === null ? (
                ""
              ) : (
                // <div
                //   size="large"
                //   edge="end"
                //   aria-label="account of current user"
                //   aria-haspopup="true"
                //   onClick={props.handleOnSave}
                //   color="inherit"
                // >
                //   <Button
                //     variant="outline"
                //     startIcon={<SaveIcon sx={{ marginRight: "8px" }} />}
                //   >
                //     Calculate & Save
                //   </Button>
                // </div>
                <div
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  onClick={props.handleOnSave}
                  color="inherit"
                >
                  {!projects.viewMode && page !== "shared-with-me" && (
                    <Button
                      variant="outline"
                      startIcon={<SaveIcon sx={{ marginRight: "8px" }} />}
                    >
                      Create New Version
                    </Button>
                  )}
                </div>
              )}
              <Box sx={{ flexGrow: 0 }}>
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  onClick={handleClick}
                  color="inherit"
                >
                  <Avatar>{initials}</Avatar>
                </IconButton>
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};
