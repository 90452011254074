import "./App.css";
import { useEffect, useState } from "react";
import { Navbar } from "./Components/Layouts/Navbar";
import { Sidebar } from "./Components/Layouts/Sidebar";
import { RespSidebar } from "./Components/Layouts/RespSidebar";
import { Department } from "./Components/Pages/Department/Department";
import { Home } from "./Components/Pages/Home/Home";
import { Switch, Route, Redirect } from "react-router-dom";
import { Services } from "./Components/Pages/Services/Services";
import { Notes } from "./Components/Pages/Notes/Notes";
import { useLocation } from "react-router-dom";
import { Calculate } from "./Components/Pages/Calculate/Calculate";
import { Starred } from "./Components/Pages/Starred/Starred";
import { Archived } from "./Components/Pages/Archived/Archived";
import { Trash } from "./Components/Pages/Trash/Trash";
import { Draft } from "./Components/Pages/Draft/Draft";
import { Settings } from "./Components/Pages/Settings/Settings";
import { SharedWithMe } from "./Components/Pages/Shared Projects/SharedWithMe";

import Cookies from "js-cookie";

function App() {
  const [sidebarActive, setSidebarActive] = useState(false);
  const location = useLocation();
  const [role, setRole] = useState(Cookies.get("role"));

  useEffect(() => {
    if (
      location.pathname.includes("/dashboard") ||
      location.pathname.includes("/starred") ||
      location.pathname.includes("/archived") ||
      location.pathname.includes("/trash") ||
      location.pathname.includes("/draft") ||
      location.pathname.includes("/settings")
    ) {
      setSidebarActive(true);
    }
  }, []);

  return (
    <>
      <Switch>
        <Route path="/calculate/:id" component={Calculate} />
      </Switch>

      {!location.pathname.includes("/calculate") && <Navbar />}
      {!location.pathname.includes("/calculate") && (
        <div className="custcontainer">
          <div className="side">
            {location.pathname.includes("/dashboard") ||
            location.pathname.includes("/starred") ||
            location.pathname.includes("/shared-with-me") ||
            location.pathname.includes("/archived") ||
            location.pathname.includes("/trash") ||
            location.pathname.includes("/draft") ||
            location.pathname.includes("/settings") ? (
              <Sidebar />
            ) : (
              <RespSidebar />
            )}
          </div>
          <div className="others">
            <Switch>
              <Route path="/dashboard" component={Home} />
              <Route exact path="/">
                <Redirect to="/dashboard" />
              </Route>
              <Route exact path="/home">
                <Redirect to="/dashboard" />
              </Route>
              <Route exact path="/home/department" component={Department} />
              <Route path="/department/update/:id" component={Department} />
              <Route path="/services/update/:id" component={Services} />
              <Route path="/deliverables/update/:id" component={Notes} />
              <Route exact path="/home/Services" component={Services} />
              <Route exact path="/home/deliverables" component={Notes} />
              <Route exact path="/shared-with-me" component={SharedWithMe} />

              <Route exact path="/starred" component={Starred} />
              <Route exact path="/archived" component={Archived} />
              <Route exact path="/trash" component={Trash} />
              {/* <Route exact path="/draft" component={Draft} /> */}
              {role === "Admin" ? (
                <Route exact path="/settings" component={Settings} />
              ) : (
                ""
              )}
            </Switch>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
