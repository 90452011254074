import React from "react";
import { Grid, Typography, Button, TextField } from "@mui/material";
import "./Department.css";
import Checkbox from "@mui/material/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  addDepartment,
  addProjectName,
  addClientName,
} from "../../../Redux/Data/Action";
import useFetch from "../../../Hooks/useFetch";
import { CircularProgress, Stack } from "@mui/material";

export const Department = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = params.get("page");
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const { isLoading, apiData, serverError } = useFetch(
    "GET",
    `${process.env.REACT_APP_API_URL}/api/department/get-department`,
    {}
  );

  const handleOnChange = (event) => {
    const { value } = event.target;
    dispatch(addProjectName(value));
  };

  const handleOnClient = (event) => {
    const { value } = event.target;
    dispatch(addClientName(value));
  };

  const checkHandler = (e, selectedDepartment) => {
    let checked = e.target.checked;

    // storing previous departments to temp array
    // old
    selectedDepartment._id = selectedDepartment._id;
    // new
    // selectedDepartment.departmentName = store.department.departments.name;

    const newDepartments = [...store.department.departments];

    // When user selects a check box
    if (checked) {
      newDepartments.push(selectedDepartment);
      dispatch(addDepartment(newDepartments));
    } else {
      const neededDepartments = newDepartments.filter(
        (dept) => dept._id !== selectedDepartment._id
      );

      dispatch(addDepartment(neededDepartments));
    }
  };

  if (isLoading) {
    return (
      <span>
        <Stack sx={{ margin: "250px 500px" }}>
          <CircularProgress disableShrink />
        </Stack>
      </span>
    );
  }
  if (!isLoading && serverError) {
    return <span>Error!</span>;
  }

  return (
    <>
      <div className="dep">
        <Grid container style={{ gap: "5px" }}>
          <Grid item lg={1}>
            <Typography variant="h5">1/2</Typography>
          </Grid>
          <Grid item lg={11}>
            <div className="project__title">
              <TextField
                sx={{
                  maxWidth: "260px",
                  backgroundColor: "white",
                  boxShadow: "5px 6px 12px rgba(1, 2, 5, 0.2)",
                  borderRadius: 2,
                }}
                fullWidth
                label="Brand Name"
                name="clientName"
                variant="outlined"
                defaultValue={store.department.clientName}
                onChange={handleOnClient}
                required
              />

              <TextField
                sx={{
                  maxWidth: "260px",
                  backgroundColor: "white",
                  boxShadow: "5px 6px 12px rgba(1, 2, 5, 0.3)",
                  borderRadius: 2,
                }}
                fullWidth
                label="Project Name"
                name="projectName"
                variant="outlined"
                defaultValue={store.department.projectName}
                onChange={handleOnChange}
                required
              />
            </div>
            <Typography variant="h5" style={{ margin: "20px 0" }}>
              Select Department
            </Typography>
            <Grid container>
              {store.settings.newDepartments
                ? store.settings.newDepartments.map((item, index) => (
                    // <Grid
                    //   key={index}
                    //   item
                    //   lg={3}
                    //   md={4}
                    //   sx={{
                    //     marginBottom: "25px",
                    //   }}
                    // >
                    <div className="dep__flex" key={index}>
                      <div className="backcarddept" style={{ width: "100%" }}>
                        {/* <div className="viewcarddept" key={item._id}>
                          {item.logo && (
                            <img src={item.logo} width="90px" alt="" />
                          )}{" "}
                        </div> */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                          }}
                        >
                          <Typography pl="10px" variant="body1">
                            {item.name}
                          </Typography>

                          <Checkbox
                            checked={store?.department?.departments
                              .map((elm) => elm._id)
                              .includes(item._id)}
                            name={item.name}
                            onChange={(e) => checkHandler(e, item)}
                            value={item.name}
                            sx={{ fontSize: "11" }}
                          />
                        </div>
                      </div>
                    </div>
                  ))
                : ""}
            </Grid>

            {store.department.projectId ? (
              <Button
                component={Link}
                to={`/deliverables/update/id:${store.department.projectId}?page=${page}`}
                disabled={
                  store.department.projectName.trim() === "" ||
                  store.department.clientName.trim() === "" ||
                  store.department.departments.length <= 0
                }
                sx={{
                  color: "#ffffff",
                  fontWeight: "500",
                  mt: "20px",
                  px: "40px",
                  backgroundColor: "#2d99fe",
                  boxShadow: "5px 6px 12px rgba(1, 2, 5, 0.3)",
                }}
                variant="contained"
              >
                Next
              </Button>
            ) : (
              <Button
                component={Link}
                to={"/home/deliverables"}
                disabled={
                  store.department.projectName.trim() === "" ||
                  store.department.clientName.trim() === "" ||
                  store.department.departments.length <= 0
                }
                sx={{
                  color: "#ffffff",
                  fontWeight: "500",
                  mt: "20px",
                  px: "40px",
                  backgroundColor: "#2d99fe",
                  boxShadow: "5px 6px 12px rgba(1, 2, 5, 0.3)",
                }}
                variant="contained"
              >
                Next
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};
