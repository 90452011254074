import ProjectActionTypes from "./ProjectActionType";

// initialize state
const initialState = {
  searchData: "",
  pricingId: 0,
  projectData: null,
  allProjects: [],
  allHome: [],
  allSharedProjects: [],
  allArchived: [],
  allStarred: [],
  allTrash: [],
  preaddOns: [],
  updateProjectData: null,
  pricingData: null,
  allPricingOfProject: {},
  genericDesignation: [],
  designationByGenericName: [],
  totalResources: 0,
  costToClient: 0,
  costToCompany: 0,
  profitMargin: 33,
  profitToCompany: 0,
  discount: 0,
  profitToCompanyAfterDiscount: 0,
  projectCollaborators: "",
  departments: [],
  addOns: [],
  logo: "https://cdn-icons-png.flaticon.com/512/149/149071.png",
  resources: [],
  projectName: "",
  createAt: "",
  projectTypeName: "",
  pricingVersions: [],
  selectedVersion: 0,
  isLoading: false,
  isError: false,
  viewMode: false,
  editMode: false,
  getMode: false,
  projectLogo: null,
  costToClientAfterDiscount: 0,
  currentPage: 1,
  totalPages: 1,
  itemsPerPage: 10,
  archivedProjects: [],
  trashedProjects: [],
  starredProjects: [],
  searchQuery: "",
  noData: [],
  totalCount: 0,
  projectIdVersion: "",
  allVersions: [],
  selectedVersions: "",
  usersForCollab: [],
  markAsSolded: [],
  allVersionsofProject: [],
  particularVersionComment: "",
  allComments: [],
  homeCurrentPage: 1,
  starredCurrentPage: 1,
  archivedCurrentPage: 1,
  trashCurrentPage: 1,
};

const ProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case ProjectActionTypes.ADD_PROJECT_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.ADD_PROJECT_SUCCESS:
      return {
        ...state,
        projectData: action.payload,
        updateProjectData: action.payload,
        editMode: false,
        departments: action.payload.departments,
      };

    case ProjectActionTypes.ADD_PROJECT_FAIL:
      return {
        ...state,
        isError: true,
      };

    case ProjectActionTypes.SET_SEARCH_DATA:
      return {
        ...state,
        searchData: action.payload,
      };

    case ProjectActionTypes.FETCH_PROJECT_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.FETCH_PROJECT_SUCCESS:
      return {
        ...state,
        // allProjects: action.payload,
        // allHome: action.payload.filter(
        //   (el) => el.trash === false && el.archived === false
        // ),
        // allArchived: action.payload.filter(
        //   (el) => el.trash === false && el.archived === true
        // ),
        // allStarred: action.payload.filter(
        //   (el) => el.trash === false && el.starred === true
        // ),
        // allTrash: action.payload.filter((el) => el.trash === true),
      };

    case ProjectActionTypes.FETCH_PROJECT_FAIL:
      return {
        ...state,
        //  isLoading: false,
        isError: true,
      };

    case ProjectActionTypes.UPDATE_PROJECT_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.UPDATE_PROJECT_SUCCESS:
      let updatedProject = state.allProjects.map((item) =>
        item._id === action.payload._id ? action.payload : item
      );
      let payload = action.payload;

      return {
        ...state,
        allProjects: updatedProject,
        allHome: updatedProject.filter(
          (el) => el.trash === false && el.archived === false
        ),
        allArchived: updatedProject.filter(
          (el) => el.trash === false && el.archived === true
        ),
        allStarred: updatedProject.filter(
          (el) => el.trash === false && el.starred === true
        ),
        allTrash: updatedProject.filter((el) => el.trash === true),
        updateProjectData: payload,
        pricingId: payload._id,
        projectData: payload,
        logo: payload.logo,
        editMode: true,
      };

    case ProjectActionTypes.UPDATE_PROJECT_FAIL:
      return {
        ...state,
        isError: true,
      };

    case ProjectActionTypes.DELETE_PROJECT_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        allProjects: state.allProjects.filter(
          (item) => item._id !== action.payload
        ),
        allTrash: state.allTrash.filter(
          (item) => item._id !== action.payload._id
        ),
      };

    case ProjectActionTypes.DELETE_PROJECT_FAIL:
      return {
        ...state,
        isError: true,
      };

    case ProjectActionTypes.SET_PRICING_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case ProjectActionTypes.SET_PRICING_DATA_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload,
      };

    case ProjectActionTypes.SET_ALL_PROJECTS:
      return {
        ...state,
        allProjects: action.payload,
        allHome: action.payload.filter(
          (el) => el.trash === false && el.archived === false
        ),
        allArchived: action.payload.filter(
          (el) => el.trash === false && el.archived === true
        ),
        allStarred: action.payload.filter(
          (el) => el.trash === false && el.starred === true
        ),
        allTrash: action.payload.filter((el) => el.trash === true),
      };
    case ProjectActionTypes.SET_PRICING_DATA_AD_ONS:
      return {
        ...state,
        addOns: [...action.payload],
      };
    case ProjectActionTypes.SET_VIEW_MODE:
      return {
        ...state,
        viewMode: true,
        projectData: action.payload,
        updateProjectData: action.payload,
      };

    case ProjectActionTypes.ADD_PRICING_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.ADD_PRICING_SUCCESS:
      return {
        ...state,
        totalResources: action.payload.versions[0].data[0].totalResources,
        costToClient: action.payload.versions[0].data[0].costToClient,
        profitMargin: action.payload.versions[0].data[0].profitMargin,
        costToCompany: action.payload.versions[0].data[0].costToCompany,
        profitToCompany: action.payload.versions[0].data[0].profitToCompany,
        pricingData: action.payload,
        pricingId: action.payload._id,
        viewMode: false,
        editMode: false,
        addOns: action.payload.versions[0].data[0].addOns,
        resources: action.payload.versions[0].data[0].resources,
        profitToCompanyAfterDiscount:
          action.payload.versions[0].data[0].profitToCompanyAfterDiscount,
        discount: action.payload.versions[0].data[0].discount,
        costToClientAfterDiscount:
          action.payload.versions[0].data[0].costToClientAfterDiscount,
      };

    case ProjectActionTypes.ADD_PRICING_FAIL:
      return {
        ...state,
        isError: true,
      };

    case ProjectActionTypes.UPDATE_PRICING_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.UPDATE_PRICING_SUCCESS:
      // let resId = state.departments.resources.map((item) => {
      //   action.payload.versions[0].data[0].resources.filter(res => res.departmentId === item.departmentId
      //        if (condition) {

      //        }
      //     )
      // });
      const latestVersion = action.payload.versions.length - 1;
      return {
        ...state,
        _id: action.payload._id,
        totalResources: action.payload.versions[0].data[0].totalResources,
        costToClient: action.payload.versions[0].data[0].costToClient,
        profitMargin: action.payload.versions[0].data[0].profitMargin,
        costToCompany: action.payload.versions[0].data[0].costToCompany,
        profitToCompany: action.payload.versions[0].data[0].profitToCompany,
        pricingData: action.payload,
        pricingId: action.payload._id,
        selectedVersion: latestVersion,
        addOns: action.payload.versions[0].data[0].addOns,
        resources: action.payload.versions[0].data[0].resources,
        profitToCompanyAfterDiscount:
          action.payload.versions[0].data[0].profitToCompanyAfterDiscount,
        discount: action.payload.versions[0].data[0].discount,
        costToClientAfterDiscount:
          action.payload.versions[0].data[0].costToClientAfterDiscount,
        viewMode: false,
        editMode: true,
        getMode: false,
      };

    case ProjectActionTypes.UPDATE_PRICING_FAIL:
      return {
        ...state,
        isError: true,
      };

    case ProjectActionTypes.UPDATE_PROJECT_PRICING_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.ALL_PRICING_OF_PROJECT:
      return {
        ...state,
        allPricingOfProject: action.payload,
      };

    case ProjectActionTypes.UPDATE_PROJECT_PRICING_SUCCESS:
      let updatePricing = action.payload;
      let preAddOns = action.payload.versions[0].data[0].addOns.map((item) => ({
        addOnCost: item.addOnCost,
        _id: item._id,
        genericDesignation: item.designationName,
        genericDesignationName: item.genericDesignationName,
        checked: true,
        isAdded: true,
      }));

      let preResource = action.payload.versions[0].data[0].resources.map(
        (item) => ({
          calculationFactor: item.calculationFactor,
          _id: item._id,
          genericDesignation: item.designationName,
          genericDesignationName: item.genericDesignationName,
          departmentName: item.departmentName,
          designationId: item.designationId,
          departmentId: item.departmentId,
          checked: true,
          isAdded: true,
          isDeleted: false,
          getMode: false,
        })
      );

      let finalres = [];
      let deps = [];
      state.departments.map((item) => {
        const resources = preResource.filter(
          (e) => e.departmentId === item._id
        );
        const resformat = { resources: resources };
        deps = {
          departmentName: item.name,
          departmentId: item._id,
          ...resformat,
        };
        finalres = [...finalres, deps];
      });
      const latestVers = action.payload.versions.length - 1;

      return {
        ...state,

        _id: updatePricing._id,
        totalResources: updatePricing.versions[0].data[0].totalResources,
        costToClient: updatePricing.versions[0].data[0].costToClient,
        profitMargin: updatePricing.versions[0].data[0].profitMargin,
        costToCompany: updatePricing.versions[0].data[0].costToCompany,
        profitToCompany: updatePricing.versions[0].data[0].profitToCompany,
        pricingData: action.payload,
        pricingId: updatePricing._id,
        addOns: updatePricing.addOns,
        resources: updatePricing.resources,
        editMode: true,
        selectedVersion: latestVers,
        departments: finalres,
        preaddOns: preAddOns,
        resources: preResource,
        getMode: true,
        profitToCompanyAfterDiscount:
          updatePricing.versions[0].data[0].profitToCompanyAfterDiscount,
        discount: updatePricing.versions[0].data[0].discount,
        costToClientAfterDiscount:
          updatePricing.versions[0].data[0].costToClientAfterDiscount,
      };

    case ProjectActionTypes.UPDATE_PROJECT_PRICING_FAIL:
      return {
        ...state,
        isError: true,
      };

    case ProjectActionTypes.CLEAR_PROJECT:
      return {
        ...state,
        logo: "https://cdn-icons-png.flaticon.com/512/149/149071.png",
        pricingId: 0,
        projectData: null,
        searchData: "",
        genericDesignation: [],
        designationByGenericName: [],
        totalResources: 0,
        profitToCompanyAfterDiscount: 0,
        discount: 0,
        costToClientAfterDiscount: 0,
        costToClient: 0,
        projectCollaborators: "",
        costToCompany: 0,
        profitMargin: 33,
        profitToCompany: 0,
        departments: [],
        addOns: [],
        projectName: "",
        createAt: "",
        projectTypeName: "",
        isLoading: false,
        isError: false,
        pricingData: null,
        viewMode: false,
        editMode: false,
        resources: [],
        preaddOns: [],
        getMode: false,
        updateProjectData: null,
        projectLogo: null,
        pricingVersions: [],
        pageCount: 1,
        totalPages: 1,
        itemsPerPage: 10,
        selectedVersion: 0,
        archivedProjects: [],
        trashedProjects: [],
        searchProjects: "",
      };

    case ProjectActionTypes.ADD_RESOURCES_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.ADD_RESOURCES_SUCCESS:
      return {
        ...state,
        totalResources: action.payload.versions[0].data[0].totalResources,
        costToClient: action.payload.versions[0].data[0].costToClient,
        profitMargin: action.payload.versions[0].data[0].profitMargin,
        costToCompany: action.payload.versions[0].data[0].costToCompany,
        profitToCompany: action.payload.versions[0].data[0].profitToCompany,
        pricingData: action.payload,
        resources: action.payload.versions[0].data[0].resources,
        profitToCompanyAfterDiscount:
          action.payload.versions[0].data[0].profitToCompanyAfterDiscount,
        discount: action.payload.versions[0].data[0].discount,
        costToClientAfterDiscount:
          action.payload.versions[0].data[0].costToClientAfterDiscount,
      };

    case ProjectActionTypes.ADD_RESOURCES_FAIL:
      return {
        ...state,
        isError: true,
      };

    case ProjectActionTypes.UPDATE_RESOURCES_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.UPDATE_RESOURCES_SUCCESS:
      return {
        ...state,
        totalResources: action.payload.versions[0].data[0].totalResources,
        costToClient: action.payload.versions[0].data[0].costToClient,
        profitMargin: action.payload.versions[0].data[0].profitMargin,
        costToCompany: action.payload.versions[0].data[0].costToCompany,
        profitToCompany: action.payload.versions[0].data[0].profitToCompany,
        pricingData: action.payload,
        resources: action.payload.versions[0].data[0].resources,
        profitToCompanyAfterDiscount:
          action.payload.versions[0].data[0].profitToCompanyAfterDiscount,
        discount: action.payload.versions[0].data[0].discount,
        costToClientAfterDiscount:
          action.payload.versions[0].data[0].costToClientAfterDiscount,
      };

    case ProjectActionTypes.UPDATE_RESOURCES_FAIL:
      return {
        ...state,
        isError: true,
      };

    case ProjectActionTypes.DELETE_RESOURCES_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.DELETE_RESOURCES_SUCCESS:
      return {
        ...state,
        totalResources: action.payload.versions[0].data[0].totalResources,
        costToClient: action.payload.versions[0].data[0].costToClient,
        profitMargin: action.payload.versions[0].data[0].profitMargin,
        costToCompany: action.payload.versions[0].data[0].costToCompany,
        profitToCompany: action.payload.versions[0].data[0].profitToCompany,
        pricingData: action.payload,
        profitToCompanyAfterDiscount:
          action.payload.versions[0].data[0].profitToCompanyAfterDiscount,
        discount: action.payload.versions[0].data[0].discount,
        costToClientAfterDiscount:
          action.payload.versions[0].data[0].costToClientAfterDiscount,
      };

    case ProjectActionTypes.DELETE_RESOURCES_FAIL:
      return {
        ...state,
        isError: true,
      };

    //addons

    case ProjectActionTypes.ADD_ADDONS_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.ADD_ADDONS_SUCCESS:
      return {
        ...state,
        totalResources: action.payload.versions[0].data[0].totalResources,
        costToClient: action.payload.versions[0].data[0].costToClient,
        profitMargin: action.payload.versions[0].data[0].profitMargin,
        costToCompany: action.payload.versions[0].data[0].costToCompany,
        profitToCompany: action.payload.versions[0].data[0].profitToCompany,
        pricingData: action.payload,
        addOns: action.payload.versions[0].data[0].addOns,
        profitToCompanyAfterDiscount:
          action.payload.versions[0].data[0].profitToCompanyAfterDiscount,
        discount: action.payload.versions[0].data[0].discount,
        costToClientAfterDiscount:
          action.payload.versions[0].data[0].costToClientAfterDiscount,
      };

    case ProjectActionTypes.ADD_ADDONS_FAIL:
      return {
        ...state,
        isError: true,
      };

    case ProjectActionTypes.UPDATE_ADDONS_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.UPDATE_ADDONS_SUCCESS:
      return {
        ...state,
        totalResources: action.payload.versions[0].data[0].totalResources,
        costToClient: action.payload.versions[0].data[0].costToClient,
        profitMargin: action.payload.versions[0].data[0].profitMargin,
        costToCompany: action.payload.versions[0].data[0].costToCompany,
        profitToCompany: action.payload.versions[0].data[0].profitToCompany,
        pricingData: action.payload,
        addOns: action.payload.versions[0].data[0].addOns,
        profitToCompanyAfterDiscount:
          action.payload.versions[0].data[0].profitToCompanyAfterDiscount,
        discount: action.payload.versions[0].data[0].discount,
        costToClientAfterDiscount:
          action.payload.versions[0].data[0].costToClientAfterDiscount,
      };

    case ProjectActionTypes.UPDATE_ADDONS_FAIL:
      return {
        ...state,
        isError: true,
      };

    case ProjectActionTypes.DELETE_ADDONS_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.DELETE_ADDONS_SUCCESS:
      return {
        ...state,
        totalResources: action.payload.versions[0].data[0].totalResources,
        costToClient: action.payload.versions[0].data[0].costToClient,
        profitMargin: action.payload.versions[0].data[0].profitMargin,
        costToCompany: action.payload.versions[0].data[0].costToCompany,
        profitToCompany: action.payload.versions[0].data[0].profitToCompany,
        pricingData: action.payload,
        addOns: action.payload.versions[0].data[0].addOns,
        resources: action.payload.versions[0].data[0].resources,
        profitToCompanyAfterDiscount:
          action.payload.versions[0].data[0].profitToCompanyAfterDiscount,
        discount: action.payload.versions[0].data[0].discount,
        costToClientAfterDiscount:
          action.payload.versions[0].data[0].costToClientAfterDiscount,
      };

    case ProjectActionTypes.DELETE_ADDONS_FAIL:
      return {
        ...state,
        isError: true,
      };

    //profitmargin

    case ProjectActionTypes.UPDATE_PROFIT_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.UPDATE_PROFIT_SUCCESS:
      return {
        ...state,
        totalResources: action.payload.versions[0].data[0].totalResources,
        costToClient: action.payload.versions[0].data[0].costToClient,
        profitMargin: action.payload.versions[0].data[0].profitMargin,
        costToCompany: action.payload.versions[0].data[0].costToCompany,
        profitToCompany: action.payload.versions[0].data[0].profitToCompany,
        pricingData: action.payload,
        addOns: action.payload.versions[0].data[0].addOns,
        profitToCompanyAfterDiscount:
          action.payload.versions[0].data[0].profitToCompanyAfterDiscount,
        discount: action.payload.versions[0].data[0].discount,
        costToClientAfterDiscount:
          action.payload.versions[0].data[0].costToClientAfterDiscount,
      };

    case ProjectActionTypes.UPDATE_PROFIT_FAIL:
      return {
        ...state,
        isError: true,
      };

    //GENERATEPDF

    case ProjectActionTypes.GENERATE_PDF_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.GENERATE_PDF_SUCCESS:
      return {
        ...state,
        pdfUrl: action.payload,
      };

    case ProjectActionTypes.GENERATE_PDF_FAIL:
      return {
        ...state,
        isError: true,
      };

    //project Logo

    case ProjectActionTypes.ADD_PROJECT_LOGO_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.ADD_PROJECT_LOGO_SUCCESS:
      return {
        ...state,
        pdfUrl: action.payload,
        logo: action.payload.logo,
      };

    case ProjectActionTypes.ADD_PROJECT_LOGO_FAIL:
      return {
        ...state,
        isError: true,
      };
    //Add Discount

    case ProjectActionTypes.ADD_DISCOUNT_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.ADD_DISCOUNT_SUCCESS:
      return {
        ...state,
        totalResources: action.payload.versions[0].data[0].totalResources,
        costToClient: action.payload.versions[0].data[0].costToClient,
        profitMargin: action.payload.versions[0].data[0].profitMargin,
        costToCompany: action.payload.versions[0].data[0].costToCompany,
        profitToCompany: action.payload.versions[0].data[0].profitToCompany,

        pricingData: action.payload,
        addOns: action.payload.versions[0].data[0].addOns,
        profitToCompanyAfterDiscount:
          action.payload.versions[0].data[0].profitToCompanyAfterDiscount,
        discount: action.payload.versions[0].data[0].discount,
        costToClientAfterDiscount:
          action.payload.versions[0].data[0].costToClientAfterDiscount,
      };

    case ProjectActionTypes.ADD_DISCOUNT_FAIL:
      return {
        ...state,
        isError: true,
      };

    // CLONE PRICING
    case ProjectActionTypes.CLONE_PRICING_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.CLONE_PRICING_SUCCESS:
      return {
        ...state,
        totalResources: action.payload.totalResources,
        costToClient: action.payload.costToClient,
        profitMargin: action.payload.profitMargin,
        costToCompany: action.payload.costToCompany,
        profitToCompany: action.payload.profitToCompany,
        pricingData: action.payload,
        pricingId: action.payload._id,
        logo: action.payload.logo,
        addOns: action.payload.versions[0].data[0].addOns,
        profitToCompanyAfterDiscount:
          action.payload.profitToCompanyAfterDiscount,
        discount: action.payload.discount,
        costToClientAfterDiscount: action.payload.costToClientAfterDiscount,
      };

    case ProjectActionTypes.CLONE_PRICING_FAIL:
      return {
        ...state,
        isError: true,
      };

    //Add Discount

    case ProjectActionTypes.ADD_COLLABORATORS_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.ADD_COLLABORATORS_SUCCESS:
      return {
        ...state,
        projectCollaborators: action.payload,
      };

    case ProjectActionTypes.ADD_COLLABORATORS_FAIL:
      return {
        ...state,
        isError: true,
      };

    case ProjectActionTypes.FETCH_COLLABORATORS_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.FETCH_COLLABORATORS_SUCCESS:
      return {
        ...state,
        projectCollaborators: action.payload,
      };

    case ProjectActionTypes.FETCH_COLLABORATORS_FAIL:
      return {
        ...state,
        isError: true,
      };

    case ProjectActionTypes.UPDATE_TEAM_COLLABORATORS_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.UPDATE_TEAM_COLLABORATORS_SUCCESS:
      return {
        ...state,
        projectCollaborators: action.payload.data.collaborator[0],
      };

    case ProjectActionTypes.UPDATE_TEAM_COLLABORATORS_FAIL:
      return {
        ...state,
        isError: true,
      };

    case ProjectActionTypes.UPDATE_COLLABORATORS_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.UPDATE_COLLABORATORS_SUCCESS:
      return {
        ...state,
        projectCollaborators: action.payload.data.collaborator[0],
      };

    case ProjectActionTypes.UPDATE_COLLABORATORS_FAIL:
      return {
        ...state,
        isError: true,
      };

    case ProjectActionTypes.DELETE_COLLABORATORS_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.DELETE_COLLABORATORS_SUCCESS:
      return {
        ...state,
        projectCollaborators: action.payload.data.collaborator[0],
      };

    case ProjectActionTypes.DELETE_COLLABORATORS_FAIL:
      return {
        ...state,
        isError: true,
      };

    case ProjectActionTypes.CLEAR_COLLABORATORS:
      return {
        ...state,
        projectCollaborators: "",
      };
    case ProjectActionTypes.FETCH_VERSIONS_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.FETCH_VERSIONS_SUCCESS:
      const reverseVersion = action.payload.reverse().map((elm) => elm);
      return {
        ...state,
        pricingVersions: reverseVersion,
      };

    case ProjectActionTypes.FETCH_VERSIONS_FAIL:
      return {
        ...state,
        isError: true,
      };
    case ProjectActionTypes.SELECT_VERSIONS:
      return {
        ...state,
        selectedVersion: action.payload,
      };
    case ProjectActionTypes.FETCH_SHARED_PROJECT_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.FETCH_SHARED_PROJECT_SUCCESS:
      return {
        ...state,
        allSharedProjects: action.payload,
      };

    case ProjectActionTypes.FETCH_SHARED_PROJECT_FAIL:
      return {
        ...state,
        isError: true,
      };

    case ProjectActionTypes.GET_PROJECT_BY_OWNER_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.GET_PROJECT_BY_OWNER_SUCCESS:
      return {
        ...state,
        allProjects: action.payload,
        allHome: action.payload.filter(
          (el) => el.trash === false && el.archived === false
        ),
        allArchived: action.payload.filter(
          (el) => el.trash === false && el.archived === true
        ),
        allStarred: action.payload.filter(
          (el) => el.trash === false && el.starred === true
        ),
        allTrash: action.payload.filter((el) => el.trash === true),
      };

    case ProjectActionTypes.GET_PROJECT_BY_OWNER_FAIL:
      return {
        ...state,
        isError: true,
      };

    case ProjectActionTypes.GET_PROJECT_BY_OWNER_PAGINATION_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case ProjectActionTypes.GET_PROJECT_BY_OWNER_PAGINATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allProjects: action.payload.data,
        itemsPerPage: action.payload.itemsPerPage,
        totalRecords: action.payload.totalRecords,
        currentPage: action.payload.currentPage,
        pageCount: action.payload.pageCount,
        allHome: action.payload.data.filter(
          (el) => el.trash === false && el.archived === false
        ),
        allArchived: action.payload.data.filter(
          (el) => el.trash === false && el.archived === true
        ),
        allStarred: action.payload.data.filter(
          (el) => el.trash === false && el.starred === true
        ),
        allTrash: action.payload.data.filter((el) => el.trash === true),
        error: {
          type: "ProjectActionTypes.NO_SEARCH_RESULTS",
          message: "No items found",
        },
      };

    case ProjectActionTypes.GET_PROJECT_BY_OWNER_PAGINATION_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
        allProjects: [],
      };

    //Archived Projects
    case ProjectActionTypes.FETCH_ARCHIVED_PROJECTS_SUCCESS:
      return {
        ...state,
        archivedProjects: action.payload.data,
        isLoading: true,
        isError: false,
      };

    case ProjectActionTypes.FETCH_ARCHIVED_PROJECTS_FAILURE:
      return {
        ...state,
        archivedProjects: [],
        isLoading: false,
        isError: true,
      };

    //Trashed Projects
    case ProjectActionTypes.FETCH_TRASHED_PROJECTS_SUCCESS:
      return {
        ...state,
        trashedProjects: action.payload.data,
        isLoading: true,
        isError: false,
      };

    case ProjectActionTypes.FETCH_TRASHED_PROJECTS_FAILURE:
      return {
        ...state,
        trashedProjects: [],
        isLoading: false,
        isError: true,
      };

    //Starred Projects
    case ProjectActionTypes.FETCH_STARRED_PROJECTS_SUCCESS:
      return {
        ...state,
        starredProjects: action.payload.data,
        isLoading: true,
        isError: false,
      };

    case ProjectActionTypes.FETCH_STARRED_PROJECTS_FAILURE:
      return {
        ...state,
        starredProjects: [],
        isLoading: false,
        isError: true,
      };

    // Search Data
    case ProjectActionTypes.FETCH_SEARCH_DATA_SUCCESS:
      return {
        ...state,
        allHome: action.payload.data,
        // searchProjects: action.payload.data,
        starredProjects: action.payload,
        archivedProjects: action.payload,
        trashedProjects: action.payload,
        pageCount: action.payload.pageCount,
        isLoading: true,
        isError: false,
      };

    case ProjectActionTypes.FETCH_SEARCH_DATA_FAILURE:
      return {
        ...state,
        searchProjects: "",
        isLoading: false,
        isError: true,
      };

    //No search result found
    case ProjectActionTypes.NO_SEARCH_RESULTS:
      return {
        ...state,
        allHome: [],
        starredProjects: [],
        archivedProjects: [],
        trashedProjects: [],
        message: "No items found",
        isError: true,
      };

    case ProjectActionTypes.SET_LIMIT:
      return {
        ...state,
        itemsPerPage: action.payload,
      };

    case ProjectActionTypes.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case ProjectActionTypes.SET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      };

    case ProjectActionTypes.SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload,
      };

    // case ProjectActionTypes.SET_PROJECT_ID:
    //   return {
    //     ...state,
    //     projectIdVersion: action.payload,
    //   };

    // case ProjectActionTypes.CLEAR_SET_PROJECT_ID:
    //   return {
    //     ...state,
    //     projectIdVersion: "",
    //   };

    case ProjectActionTypes.FETCH_TOTAL_VERSIONS_SUCCESS:
      return {
        ...state,
        allVersions: action.payload,
      };

    case ProjectActionTypes.SET_SELECTED_VERSION:
      return {
        ...state,
        selectedVersions: action.payload,
      };

    // All users for "Add Collaborators"
    case ProjectActionTypes.FETCH_USERS_To_COLLAB_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.FETCH_USERS_To_COLLAB_SUCCESS:
      return {
        ...state,
        usersForCollab: action.payload,
      };

    case ProjectActionTypes.FETCH_USERS_To_COLLAB_FAIL:
      return {
        ...state,
        isError: true,
      };

    //Mark As Sold

    case ProjectActionTypes.MARK_AS_SOLD_SUCCESS:
      return {
        ...state,
        markAsSolded: action.payload,
      };

    case ProjectActionTypes.ALL_VERSIONS_OF_PROJECTS:
      return {
        ...state,
        allVersionsofProject: action.payload,
      };

    case ProjectActionTypes.CLEAR_ALL_VERSIONS:
      return {
        ...state,
        allVersions: [],
      };

    //Versioning Notes to a particular version
    case ProjectActionTypes.UPDATE_VERSION_COMMENT_START:
      return {
        ...state,
        isError: false,
      };

    case ProjectActionTypes.UPDATE_VERSION_COMMENT_SUCCESS:
      return {
        ...state,
        particularVersionComment: action.payload,
      };

    case ProjectActionTypes.UPDATE_VERSION_COMMENT_FAIL:
      return {
        ...state,
        isError: true,
      };

    case ProjectActionTypes.ALL_COMMENTS_OF_PROJECTS:
      return {
        ...state,
        allComments: action.payload,
      };

    case ProjectActionTypes.SET_EDIT_VERSION_NOTES:
      return {
        ...state,
        allComments: (action.payload.allComments || []).map((item) => {
          return item.version === action.payload.versionNumber
            ? { ...item, versionNote: action.payload.data.versionNote }
            : item;
        }),
      };

    //Current Page handler

    case ProjectActionTypes.SET_CURRENT_PAGE_HANDLER:
      return {
        ...state,
        homeCurrentPage: action.payload.homeCurrentPage || 1,
        starredCurrentPage: action.payload.starredCurrentPage || 1,
        archivedCurrentPage: action.payload.archivedCurrentPage || 1,
        trashCurrentPage: action.payload.trashCurrentPage || 1,
      };

    default: {
      return state;
    }
  }
};

export default ProjectReducer;
