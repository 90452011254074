import React from "react";
import { Button, MenuItem, Select, FormControl } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { styled } from "@mui/material/styles";
import { Notify } from "../../UI/Notify";
import { useDispatch } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import {
  deleteCollaborator,
  requestAllSharedProject,
  updateCollaborator,
} from "../../../Redux/Projects/Action";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0f172a",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CollaboratorRow = (props) => {
  const dispatch = useDispatch();

  const [notification, setNotification] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [collabId, setCollabId] = React.useState("");

  const [inputRole, setInputRole] = React.useState(null);
  const [inputVersion, setInputVersion] = React.useState(null);

  //Common handler for role and version
  const handleChange = (event, data, type) => {
    if (type === "role") {
      setInputRole(event.target.value);

      setInputVersion(inputVersion || data?.versionAccess);
    } else {
      setInputVersion(event.target.value);

      setInputRole(inputRole || data?.collaboratorRole);
    }
    setCollabId(data._id);
  };

  const updateHandler = (collabId) => {
    if (inputRole || inputVersion) {
      const data = {
        role: inputRole,
        versionAccess: inputVersion,
      };
      dispatch(updateCollaborator(data, props.projectId, collabId));

      setNotification({
        isOpen: true,
        message: "Successfully Updated User Role and/or Version Access",
        type: "success",
      });

      setInputRole(inputRole);
      setInputVersion(inputVersion);
      setCollabId("");
      dispatch(requestAllSharedProject());
    }
  };

  const deleteHandler = (collabId) => {
    dispatch(deleteCollaborator(props.projectId, collabId));

    setNotification({
      isOpen: true,
      message: "Successfully Deleted Collaborator",
      type: "error",
    });
    dispatch(requestAllSharedProject());
    setInputRole(inputRole);
    setInputVersion(inputVersion);
    setCollabId("");
  };

  return (
    <>
      <Notify notification={notification} setNotification={setNotification} />

      <StyledTableRow key={props.row._id}>
        <StyledTableCell align="center">{props.index + 1}</StyledTableCell>
        <StyledTableCell align="center">
          {props.row.collaboratorName}
        </StyledTableCell>

        <StyledTableCell align="center">
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              defaultValue={props.row.collaboratorRole}
              onChange={(e) => {
                handleChange(e, props.row, "role");
              }}
              name="role"
            >
              <MenuItem value={"view"}>View</MenuItem>
              <MenuItem value={"edit"}>Edit</MenuItem>
            </Select>
          </FormControl>
        </StyledTableCell>

        <StyledTableCell align="center">
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              defaultValue={props.row.versionAccess}
              onChange={(event) => {
                handleChange(event, props.row, "versionAccess");
              }}
              name="versionAccess"
            >
              {props.allVersions.map((version) => (
                <MenuItem key={version.version} value={version.version}>
                  {version.version}
                </MenuItem>
              ))}
              <MenuItem value={"all"}>All</MenuItem>
            </Select>
          </FormControl>
        </StyledTableCell>

        <StyledTableCell align="center">
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            sx={{ color: "white", marginRight: "10px" }}
            onClick={() => updateHandler(props.row.collaboratorId)}
          >
            Update
          </Button>
          <Button
            color="error"
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => deleteHandler(props.row.collaboratorId)}
            startIcon={<DeleteForeverIcon />}
          >
            Delete
          </Button>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default CollaboratorRow;
