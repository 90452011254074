import React, { useState } from "react";
import {
  Typography,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import "./Settings.css";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useDispatch, useSelector } from "react-redux";
import { Notify } from "../../UI/Notify";
import {
  addNewService,
  deleteService,
  requestAllServices,
} from "../../../Redux/Settings/Action";

export const AddServices = () => {
  const store = useSelector((store) => store);
  const [deleteDept, setDeleteDept] = useState([]);
  const [serviceDept, setServiceDept] = useState([]);
  const [departmentValue, setDepartmentValue] = useState([]);
  const [addService, setAddService] = useState("");

  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const dispatch = useDispatch();

  const addServiceHandler = () => {
    if (addService !== "" && departmentValue !== "") {
      const apiData = {
        serviceName: addService,
        departmentId: departmentValue._id,
      };

      dispatch(addNewService(apiData));
      setNotification({
        isOpen: true,
        message: "Successfully Added New Service",
        type: "success",
      });
      setAddService("");
    } else {
      setNotification({
        isOpen: true,
        message: "Service Field is empty",
        type: "error",
      });
    }
  };

  const deleteHandler = () => {
    if (serviceDept !== "") {
      dispatch(deleteService(serviceDept));
      setTimeout(() => {
        dispatch(requestAllServices());
      }, 1000);
      setNotification({
        isOpen: true,
        message: "Permanently Deleted Service",
        type: "error",
      });
    }
  };

  return (
    <>
      <Notify notification={notification} setNotification={setNotification} />
      <div className="set__departments">
        <Typography
          className="set__headings"
          sx={{ color: "#5E5873" }}
          variant="h6"
        >
          Add New Service :
        </Typography>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          onChange={(e, value) => setDepartmentValue(value)}
          getOptionLabel={(option) => option.name}
          options={store?.settings?.newDepartments}
          className="set_autocomplete"
          renderInput={(params) => (
            <TextField {...params} label="Select Department" />
          )}
          fullWidth
        />
        <TextField
          id="outlined-basic"
          label="Add Service"
          onChange={(e) => setAddService(e.target.value)}
          value={addService}
          variant="outlined"
          fullWidth
        />
        <div className="set__button">
          <Button
            variant="contained"
            color="success"
            sx={{
              color: "white",
            }}
            onClick={addServiceHandler}
            startIcon={<AddCircleOutlinedIcon />}
          >
            Add
          </Button>
        </div>
        <Typography
          className="set__headings"
          sx={{ color: "#5E5873" }}
          variant="h6"
        >
          Update Service :
        </Typography>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          onChange={(e, value) => setDeleteDept(value)}
          getOptionLabel={(option) => option.name}
          options={store?.settings?.newDepartments || []}
          className="set_autocomplete"
          renderInput={(params) => (
            <TextField {...params} label="Select Department" />
          )}
          fullWidth
        />
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          onChange={(e, value) => setServiceDept(value)}
          getOptionLabel={(option) => option.serviceName}
          options={
            store?.settings?.newServices.filter(
              (item) => deleteDept && item.departmentId === deleteDept._id
            ) || ""
          }
          className="set_autocomplete"
          renderInput={(params) => (
            <TextField {...params} label="Select Department" />
          )}
          fullWidth
        />
        <div className="set__button">
          <Button
            variant="contained"
            color="danger"
            sx={{
              color: "white",
            }}
            onClick={deleteHandler}
            startIcon={<DeleteForeverIcon />}
          >
            Delete
          </Button>
        </div>
      </div>
    </>
  );
};
