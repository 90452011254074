import React, { useState, useEffect } from "react";
import { CircularProgress, Stack } from "@mui/material";
import useFetch from "../../../Hooks/useFetch";
import { RecentProjectGrid } from "../../Main/RecentProjectGrid";
import { RecentProjectList } from "../../Main/RecentProjectList";
import { Typography } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProject,
  fetchArchivedProjects,
  fetchSearchData,
  fetchTrashedProjects,
  setCurrentPageHandler,
  setSearchNewQuery,
} from "../../../Redux/Projects/Action";
import { InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import { TramSharp } from "@mui/icons-material";

export const Trash = (props) => {
  const dispatch = useDispatch();
  const { isLoading, apiData, serverError } = useFetch(
    "POST",
    `${process.env.REACT_APP_API_URL}/api/project/get-projects`,
    {}
  );

  const trashedProjects = useSelector(
    (store) => store.project?.trashedProjects
  );

  //Pagination, Limit and Search
  const [limit, setlimit] = useState(10);
  const currentPage = useSelector((store) => store.project.trashCurrentPage);

  const searchQuery = useSelector((store) => store.project?.searchQuery);

  /**
   * <-- make the curret page being veiwed = 1 -->
   * 1. initally the current page is 1
   * 2. on pagination limit change , then current is page is 1
   * 3. when search handler is called again the current page is 1
   */
  useEffect(() => {
    dispatch(setCurrentPageHandler(1, "trash"));
  }, [searchQuery]);

  useEffect(() => {
    dispatch(setSearchNewQuery(""));
  }, []);

  function handlePageChange(newPage) {
    dispatch(setCurrentPageHandler(newPage, "trash"));
    if (searchQuery) {
      dispatch(fetchSearchData(searchQuery, limit, newPage, "trash"));
    } else {
      dispatch(fetchTrashedProjects(limit, newPage));
    }
  }
  function handleItemsPerPageChange(newItemsPerPage) {
    if (searchQuery) {
      dispatch(fetchSearchData(searchQuery, newItemsPerPage, 1, "trash"));
    } else {
      dispatch(fetchTrashedProjects(newItemsPerPage, 1));
    }

    setlimit(newItemsPerPage);
    dispatch(setCurrentPageHandler(1, "trash"));

    localStorage.setItem("item-per-page", newItemsPerPage);
  }

  const [view, setView] = useState(
    localStorage.getItem("content-view-type") || "grid"
  );
  const allProjects = useSelector((store) => store.project);

  const gridHandler = () => {
    setView("grid");
    localStorage.setItem("content-view-type", "grid");
  };

  const listHandler = () => {
    setView("list");
    localStorage.setItem("content-view-type", "list");
  };
  if (isLoading) {
    return (
      <span>
        <Stack sx={{ margin: "250px 500px" }}>
          <CircularProgress disableShrink />
        </Stack>
      </span>
    );
  }
  if (!isLoading && serverError) {
    return <span>Error!</span>;
  }

  return (
    <>
      <div className="starred">
        <div className="topbar">
          <Typography
            variant="h6"
            sx={{ color: "#0F172A" }}
            mb="30px"
            fontWeight="500"
          >
            Trash Projects
          </Typography>
          <div>
            <FormatListBulletedIcon
              onClick={listHandler}
              sx={{
                verticalAlign: "middle",
                mr: "10px",

                cursor: "pointer",
              }}
            />
            <GridViewIcon
              onClick={gridHandler}
              sx={{
                verticalAlign: "middle",
                align: "right",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        {allProjects?.trashedProjects?.data &&
          allProjects?.trashedProjects?.data?.length > 0 && (
            <div className="pagination-container">
              <FormControl className="select">
                <InputLabel>Items</InputLabel>
                <Select
                  value={limit}
                  label="limit"
                  onChange={(e) => handleItemsPerPageChange(e.target.value)}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>

              <ResponsivePagination
                total={allProjects?.trashedProjects?.pageCount}
                current={currentPage}
                onPageChange={(page) => handlePageChange(page)}
              />
            </div>
          )}
        {!allProjects?.trashedProjects?.data ||
        allProjects?.trashedProjects?.data?.length === 0 ? (
          <div>No Items Found!</div>
        ) : view === "grid" ? (
          <RecentProjectGrid
            limit={limit}
            currentPage={currentPage}
            data={trashedProjects.data}
            action={false}
          />
        ) : (
          <RecentProjectList
            limit={limit}
            currentPage={currentPage}
            data={trashedProjects.data}
            action={false}
          />
        )}
      </div>
    </>
  );
};
