import { combineReducers } from "redux";
import ProjectReducer from "./Projects/ProjectReducer";
import DataReducer from "./Data/DataReducer";
import SettingsReducer from "./Settings/SettingsReducer";

const rootReducer = combineReducers({
  project: ProjectReducer,
  department: DataReducer,
  settings: SettingsReducer,
});

export default rootReducer;
