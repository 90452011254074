import React, { useEffect, useState } from "react";

import {
  Typography,
  Grid,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import "./Settings.css";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import {
  addNewDepartment,
  addNewOverHead,
} from "../../../Redux/Settings/Action";
import { useDispatch, useSelector } from "react-redux";
import Store from "../../../Redux/Storage";
import { Notify } from "../../UI/Notify";

export const OverHead = () => {
  const store = useSelector((store) => store);
  const [overHead, setOverHead] = useState(store.settings?.overHeadAmt);
  const dispatch = useDispatch();
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const overHeadChange = (e) => {
    let enteredOverHead = e.target.value;

    enteredOverHead = enteredOverHead.replace(/[^0-9.]/g, "");

    let overHeadValue =
      enteredOverHead !== "" && parseFloat(enteredOverHead) > 0
        ? enteredOverHead
        : "";

    setOverHead(overHeadValue);
  };

  const overHeadHandler = (e) => {
    if (overHead !== "") {
      dispatch(
        addNewOverHead({
          overHead: overHead,
          _id: store.settings?.overHead?._id,
        })
      );

      setNotification({
        isOpen: true,
        message: "Successfully Added Overhead",
        type: "success",
      });
    } else {
      setNotification({
        isOpen: true,
        message: "Overhead Field is empty",
        type: "error",
      });
    }
  };

  return (
    <>
      <Notify notification={notification} setNotification={setNotification} />
      <div className="set__departments">
        <Typography
          className="set__headings"
          sx={{ color: "#5E5873" }}
          variant="h6"
        >
          Add Overhead Cost:
        </Typography>
        <TextField
          id="outlined-basic"
          label="OverHead Cost"
          type="number"
          variant="outlined"
          defaultValue={store.settings?.overHeadAmt}
          value={overHead}
          onChange={overHeadChange}
          fullWidth
          required
        />

        <div className="set__button">
          <Button
            variant="contained"
            color="success"
            sx={{
              color: "white",
            }}
            onClick={overHeadHandler}
            startIcon={<AddCircleOutlinedIcon />}
          >
            Add
          </Button>
        </div>
      </div>
    </>
  );
};
