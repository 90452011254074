import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { Typography, Grid, CardMedia } from "@mui/material";
import logo from "../../assets/logo.png";
import Avatar from "@mui/material/Avatar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./Login.css";
import axios from "axios";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import cal from "../../assets/cost.png";
import google from "../../assets/google.png";

export const Login = () => {
  const history = useHistory();

  if (Cookies.get("code") && Cookies.get("token")) {
    history.push("/dashboard");
  }

  const [showloginButton, setShowloginButton] = useState(true);
  const [showlogoutButton, setShowlogoutButton] = useState(false);
  const onLoginSuccess = (res) => {
    setShowloginButton(false);
    setShowlogoutButton(true);
  };

  const onLoginFailure = (res) => {
    console.log("Login Failed:", res);
  };

  const onSignoutSuccess = () => {
    alert("You have been logged out successfully");
    console.clear();
    setShowloginButton(true);
    setShowlogoutButton(false);
  };

  const loginHandler = async () => {
    try {
      const resp = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/api/auth/google/get-auth-url`,
      });

      if (resp.status == 200) {
        window.location.replace(resp.data);
      }
    } catch (error) {
      console.log("error");
    }
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          lg={6}
          md={6}
          sx={{ height: "100vh", backgroundColor: "#0f172a" }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              backgroundColor: "#0f172a",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "white" }}
                fontWeight="bold"
                textAlign="center"
              >
                Welcome to
              </Typography>
              <Typography
                variant="h3"
                sx={{ color: "white" }}
                fontWeight="bold"
              >
                Cost Calculator
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sx={{
            display: "flex",

            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              width: "350px",
              height: "320px",
              margin: "0 auto",
              textAlign: "center",
              padding: "30px 0",
              backgroundColor: "white",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <CardMedia
              component="img"
              sx={{ width: "180px", pb: "5px", margin: "0 auto 0 auto" }}
              image={logo}
              alt="logo"
            />

            <CardContent>
              <AccountCircleIcon sx={{ fontSize: "100px" }} />
              <Typography variant="h5" my="30px" fontWeight="500">
                Login
              </Typography>

              <CardActions style={{ justifyContent: "center" }}>
                <Button
                  variant="contained"
                  sx={{
                    width: "250px",
                    backgroundColor: "#2d99fe",
                    color: "white",
                    fontSize: "16px",
                    borderRadius: "5px",
                    padding: "4px 20px 4px 0",
                    textTransform: "capitalize",
                    justifyContent: "space-around",
                  }}
                  fontWeight="bold"
                  onClick={loginHandler}
                >
                  <Avatar
                    src={google}
                    sx={{ borderRadius: "3px", marginRight: "22px" }}
                    variant="square"
                    width="10%"
                  />
                  Sign in with Google
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
