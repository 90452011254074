import * as React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import { Button } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Notify } from "../UI/Notify";
import Cookies from "js-cookie";

import {
  updateProject,
  deleteProject,
  updatePricingByProject,
  viewMode,
  getOwnerProjectByPagination,
  fetchArchivedProjects,
  fetchTrashedProjects,
  fetchStarredProjects,
  fetchSearchData,
} from "../../Redux/Projects/Action";
import { addDepartment, finalUpdateProject } from "../../Redux/Data/Action";
import "./RecentProjectList.css";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0f172a",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const RecentProjectList = (props) => {
  const location = useLocation();

  const [inputValues, setInputValues] = useState(null);
  const [archivedValues, setArchivedValues] = useState(null);
  const [trashValues, setTrashValues] = useState(null);
  const [projects, setProjects] = useState("");
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const starredHandler = (data) => {
    if (data.starred === false) {
      setInputValues({ ...data, starred: true });
    } else {
      setInputValues({ ...data, starred: false });
    }
  };

  const searchQuery = useSelector((store) => store.project?.searchQuery);

  useEffect(() => {
    setProjects(props.data);
  }, [props.data]);

  /**
   *
   * @param {boolean} starredButton
   * @param {boolean} archivedButton
   * @param {boolean} trashButton
   * @returns void
   */
  const actionButtonHandlers = async () => {
    let pathName = location?.pathname?.match(/[^/]/gm).join("");

    if (searchQuery && searchQuery.length) {
      await dispatch(
        fetchSearchData(searchQuery, props.limit, props.currentPage, pathName)
      );
    } else {
      if (pathName.includes("archived")) {
        await dispatch(fetchArchivedProjects(props.limit, props.currentPage));
      } else if (pathName.includes("starred")) {
        await dispatch(fetchStarredProjects(props.limit, props.currentPage));
      } else if (pathName.includes("trash")) {
        await dispatch(fetchTrashedProjects(props.limit, props.currentPage));
      } else {
        await dispatch(
          getOwnerProjectByPagination(props.limit, props.currentPage)
        );
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (inputValues) {
          await dispatch(updateProject(inputValues));

          await actionButtonHandlers();

          setTimeout(() => {
            setNotification({
              isOpen: true,
              message: "Successfully updated Starred Project",
              type: "success",
            });
          }, 1500);
        }
      } catch (error) {
        console.error("Error updating starred project:", error);

        setNotification({
          isOpen: true,
          message: "Error updating Starred Project",
          type: "error",
        });
      }
    };

    fetchData();
  }, [inputValues, dispatch, props.limit, props.currentPage]);

  const archivedHandler = (data) => {
    if (data.archived === false) {
      setArchivedValues({ ...data, archived: true });
    } else {
      setArchivedValues({ ...data, archived: false });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (archivedValues) {
          await dispatch(updateProject(archivedValues));

          await actionButtonHandlers();

          setTimeout(() => {
            setNotification({
              isOpen: true,
              message: "Successfully updated Archived Project",
              type: "success",
            });
          }, 1500);
        }
      } catch (error) {
        console.error("Error updating archived project:", error);

        setNotification({
          isOpen: true,
          message: "Error updating Archived Project",
          type: "error",
        });
      }
    };

    fetchData();
  }, [archivedValues, dispatch, props.limit, props.currentPage]);

  const trashHandler = (data) => {
    if (data.trash === false) {
      setTrashValues({ ...data, trash: true });
    } else {
      setTrashValues({ ...data, trash: false });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (trashValues) {
          await dispatch(updateProject(trashValues));

          await actionButtonHandlers();

          setTimeout(() => {
            setNotification({
              isOpen: true,
              message: "Successfully updated Trashed Project",
              type: "success",
            });
          }, 1500);
        }
      } catch (error) {
        console.error("Error updating trashed project:", error);

        setNotification({
          isOpen: true,
          message: "Error updating Trashed Project",
          type: "error",
        });
      }
    };

    fetchData();
  }, [trashValues, dispatch, props.limit, props.currentPage]);

  //Search logic
  useEffect(() => {
    // if (store.department.searchData) {
    const searchData = props.data.filter((row) => {
      return Object.values(row)
        .reverse(" ")
        .join(" ")
        .toLowerCase()
        .includes(store.project.searchData.toLowerCase());
    });
    setProjects(searchData);
    // }
  }, [store.project.searchData]);

  //permanently delete
  const deleteHandler = async (data) => {
    try {
      await dispatch(deleteProject(data));

      if (searchQuery) {
        await dispatch(
          fetchSearchData(searchQuery, props.limit, props.currentPage, "trash")
        );
      } else {
        await dispatch(fetchTrashedProjects(props.limit, props.currentPage));
      }

      setTimeout(() => {
        setNotification({
          isOpen: true,
          message: "Permanently Deleted Project",
          type: "error",
        });
      }, 1000);
    } catch (error) {
      console.error("Error deleting project:", error);

      setNotification({
        isOpen: true,
        message: "Error Deleting Project",
        type: "error",
      });
    }
  };

  // update store for project edit opp
  const updateProjectHandler = (data) => {
    // Department Updated as per all deps
    let selectedDept = [];
    data.departments.map((e) => {
      const dept = store?.settings?.newDepartments.filter(
        (elm) => elm._id === e.departmentId
      );

      selectedDept = [...selectedDept, ...dept];
    });

    dispatch(addDepartment(selectedDept));
    dispatch(finalUpdateProject(data));
  };

  //View Project Pricing

  const pricingViewHandler = (data) => {
    dispatch(viewMode(data));
    dispatch(finalUpdateProject(data));
    dispatch(updatePricingByProject(data._id));
  };

  return (
    <div>
      <Notify notification={notification} setNotification={setNotification} />
      <TableContainer
        sx={{
          maxWidth: 1100,
          boxShadow:
            " 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.1)",
          marginBottom: 5,
        }}
        component={Paper}
      >
        {location.pathname !== "/shared-with-me" ? (
          <Table sx={{ maxWidth: 1100 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Sl No</StyledTableCell>
                <StyledTableCell align="center">Project Type</StyledTableCell>
                <StyledTableCell align="center">Project Name</StyledTableCell>
                <StyledTableCell align="center">Actions</StyledTableCell>
                <StyledTableCell align="center">Update</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects && projects.length > 0
                ? projects.map((row, index) => (
                    <StyledTableRow key={row._id}>
                      <StyledTableCell align="center">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell
                        onClick={() => pricingViewHandler(row)}
                        align="center"
                      >
                        <Link
                          to={`/calculate/${row?._id}`}
                          className="linklist"
                        >
                          {row.projectTypeName}
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        component="th"
                        scope="row"
                      >
                        {row.projectName}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        component="th"
                        scope="row"
                      >
                        {true ? (
                          <span className="project__iconsList">
                            {!row.starred ? (
                              <Button
                                variant="text"
                                sx={{ padding: "0" }}
                                value={row.name}
                                onClick={() => starredHandler(row)}
                              >
                                <StarOutlineIcon
                                  fontSize="small"
                                  className="project__singleiconsList btn btn-1"
                                />
                              </Button>
                            ) : (
                              <Button
                                sx={{ padding: "0" }}
                                variant="text"
                                value={row.name}
                                onClick={() => starredHandler(row)}
                              >
                                <StarIcon
                                  fontSize="small"
                                  className="project__singleiconsList btn btn-1"
                                />
                              </Button>
                            )}
                            <Button
                              sx={{ padding: "0" }}
                              variant="text"
                              value={row}
                              onClick={() => archivedHandler(row)}
                            >
                              <ArchiveIcon
                                fontSize="small"
                                className="project__singleiconsList btn btn-2"
                              />
                            </Button>
                            <Button
                              sx={{ padding: "0" }}
                              variant="text"
                              value={row}
                              onClick={() => trashHandler(row)}
                            >
                              <DeleteForeverIcon
                                fontSize="small"
                                className=" btn btn-3 project__singleiconsList"
                              />
                            </Button>
                          </span>
                        ) : (
                          ""
                        )}
                      </StyledTableCell>
                      {location.pathname === "/shared-with-me" ? (
                        <StyledTableCell align="center" scope="row">
                          {row.collaborator[0].collaborators.filter(
                            (elm) => elm.collaboratorId === Cookies.get("_id")
                          )[0].collaboratorRole !== "view" ? (
                            <Link
                              style={{
                                color: "black",
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                              to={`/department/update/id:${
                                row._id
                              }?page=${location.pathname.substring(1)}`}
                            >
                              <Button
                                variant="contained"
                                sx={{
                                  color: "white",
                                }}
                                onClick={() => updateProjectHandler(row)}
                                fullWidth
                              >
                                <div>Edit Project</div>
                              </Button>
                            </Link>
                          ) : (
                            <Link
                              style={{
                                color: "black",
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                              to={`/calculate/${row?._id}`}
                            >
                              <Button
                                variant="contained"
                                color="warning"
                                sx={{
                                  color: "white",
                                }}
                                onClick={() => pricingViewHandler(row)}
                                fullWidth
                              >
                                <div>View Project</div>
                              </Button>
                            </Link>
                          )}
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell align="center" scope="row">
                          <Link
                            style={{
                              color: "black",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                            to={`/department/update/id:${row._id}`}
                          >
                            <Button
                              variant="contained"
                              sx={{
                                color: "white",
                              }}
                              onClick={() => updateProjectHandler(row)}
                              fullWidth
                            >
                              <div>Edit Project</div>
                            </Button>
                          </Link>
                        </StyledTableCell>
                      )}
                    </StyledTableRow>
                  ))
                : ""}
            </TableBody>
          </Table>
        ) : (
          <Table sx={{ maxWidth: 1100 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Sl No</StyledTableCell>
                <StyledTableCell align="center">Project Type</StyledTableCell>
                <StyledTableCell align="center">Project Name</StyledTableCell>
                {location.pathname !== "/shared-with-me" && (
                  <StyledTableCell align="center">Actions</StyledTableCell>
                )}
                <StyledTableCell align="center">Update</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects
                ? projects.map((row, index) => (
                    <StyledTableRow key={row._id}>
                      <StyledTableCell align="center">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell
                        onClick={() => pricingViewHandler(row)}
                        align="center"
                      >
                        <Link
                          to={`/calculate/${row?._id}`}
                          className="linklist"
                        >
                          {row.projectTypeName}
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        component="th"
                        scope="row"
                      >
                        {row.projectName}
                      </StyledTableCell>
                      {location.pathname !== "/shared-with-me" && (
                        <StyledTableCell
                          align="center"
                          component="th"
                          scope="row"
                        >
                          {true ? (
                            <span className="project__iconsList">
                              {/* Your actions buttons */}
                            </span>
                          ) : (
                            ""
                          )}
                        </StyledTableCell>
                      )}
                      <StyledTableCell align="center" scope="row">
                        {/* Update column with conditional rendering */}
                        {location.pathname === "/shared-with-me" ? (
                          row.collaborator[0].collaborators.filter(
                            (elm) => elm.collaboratorId === Cookies.get("_id")
                          )[0].collaboratorRole !== "view" ? (
                            <Link
                              style={{
                                color: "black",
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                              to={`/department/update/id:${
                                row._id
                              }?page=${location.pathname.substring(1)}`}
                            >
                              <Button
                                variant="contained"
                                sx={{
                                  color: "white",
                                }}
                                onClick={() => updateProjectHandler(row)}
                                fullWidth
                              >
                                <div>Edit Project</div>
                              </Button>
                            </Link>
                          ) : (
                            <Link
                              style={{
                                color: "black",
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                              to={`/calculate/${row?._id}`}
                            >
                              <Button
                                variant="contained"
                                color="warning"
                                sx={{
                                  color: "white",
                                }}
                                onClick={() => pricingViewHandler(row)}
                                fullWidth
                              >
                                <div>View Project</div>
                              </Button>
                            </Link>
                          )
                        ) : (
                          <Link
                            style={{
                              color: "black",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                            to={`/department/update/id:${row._id}`}
                          >
                            <Button
                              variant="contained"
                              sx={{
                                color: "white",
                              }}
                              onClick={() => updateProjectHandler(row)}
                              fullWidth
                            >
                              <div>Edit Project</div>
                            </Button>
                          </Link>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                : ""}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
};
