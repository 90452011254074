const DataActionTypes = {
  FETCH_DEPARTMENT_START: "FETCH_DEPARTMENT_START",
  FETCH_DEPARTMENT_SUCCESS: "FETCH_DEPARTMENT_SUCCESS",
  FETCH_DEPARTMENT_FAIL: "FETCH_DEPARTMENT_FAIL",
  FETCH_SERVICE_START: "FETCH_SERVICE_START",
  FETCH_SERVICE_SUCCESS: "FETCH_SERVICE_SUCCESS",
  FETCH_SERVICE_FAIL: "FETCH_SERVICE_FAIL",
  SET_DEPARTMENTS: "SET_DEPARTMENTS",
  SET_DEPARTMENT_SERVICES: "SET_DEPARTMENT_SERVICES",
  ADD_NOTE: "ADD_NOTE",
  ADD_USERNAME: "ADD_USERNAME",
  ADD_SLUG: "ADD_SLUG",
  ADD_PROJECT_TYPE: "ADD_PROJECT_TYPE",
  SET_PROJECT_INFO: "SET_PROJECT_INFO",
  SET_PROJECT_NAME: "SET_PROJECT_NAME",
  CLEAR_DEPARTMENT: "CLEAR_DEPARTMENT",
  SET_CLIENT_NAME: "SET_CLIENT_NAME",
  SET_PROJECT_ID: "SET_PROJECT_ID",
  SET_DEPARTMENTS_DATA: "SET_DEPARTMENTS_DATA",
  SET_SERVICES_DATA: "SET_SERVICES_DATA",
  SET_FINAL_UPDATE_PROJECT_DATA: "SET_FINAL_UPDATE_PROJECT_DATA",
  SET_TOKEN_DATA: "SET_TOKEN_DATA",

  // clone a project
  CLONE_PROJECT_SUCCESS: "CLONE_PROJECT_SUCCESS",
  CLONE_PROJECT_FAIL: "CLONE_PROJECT_FAIL",
  CLONE_PROJECT_START: "CLONE_PROJECT_START",

  // Deleted Department Data
  DELETED_DEPARTMENT_DATA: "DELETED_DEPARTMENT_DATA",
};
export default DataActionTypes;
