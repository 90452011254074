import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import { Button, MenuItem, Select, FormControl } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import GridOnIcon from "@mui/icons-material/GridOn";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteUsers,
  exportAllProjects,
  exportSingleProject,
  requestAllUsers,
  soldProjects,
  updateUsers,
} from "../../../Redux/Settings/Action";
import { Notify } from "../../UI/Notify";
import { useEffect } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0f172a",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const SoldProjects = () => {
  const getSoldProjects = useSelector(
    (store) => store.settings?.soldProjects?.data
  );

  const binaryDataOfSingleProject = useSelector(
    (store) => store.settings?.exportSingleProject
  );
  const [input, setInput] = React.useState("");
  const dispatch = useDispatch();
  const [notification, setNotification] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const exportProject = (row) => {
    const data = [row];
    dispatch(exportSingleProject(data));
    setTimeout(() => {
      dispatch(requestAllUsers());
      setNotification({
        isOpen: true,
        message: "Exported Project",
        type: "success",
      });
    });
  };

  const exportAllTheProjects = () => {
    const data = getSoldProjects;
    dispatch(exportAllProjects(data));
    setTimeout(() => {
      dispatch(requestAllUsers());
      setNotification({
        isOpen: true,
        message: "Exported All Projects",
        type: "success",
      });
    });
  };

  return (
    <>
      <Notify notification={notification} setNotification={setNotification} />

      <TableContainer
        sx={{
          maxWidth: 1200,
          marginBottom: "50px",
          boxShadow:
            " 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.1)",
        }}
        component={Paper}
      >
        <Table sx={{ maxWidth: 1200 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Sl No</StyledTableCell>
              <StyledTableCell align="center">Project Name</StyledTableCell>
              <StyledTableCell align="center">Brand Name</StyledTableCell>
              <StyledTableCell align="center">Version No</StyledTableCell>
              <StyledTableCell align="center">Project Type</StyledTableCell>
              <StyledTableCell align="center">Project Owner</StyledTableCell>
              <StyledTableCell align="center">
                <Button
                  variant="contained"
                  sx={{ color: "white" }}
                  onClick={exportAllTheProjects}
                >
                  Export All Projects
                </Button>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getSoldProjects
              ? getSoldProjects?.map((row, index) => (
                  <StyledTableRow key={row._id}>
                    <StyledTableCell align="center">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.projectName}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.clientName}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.pricingData?.version}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.projectTypeName}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.owner}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <Button
                        variant="contained"
                        sx={{ color: "white" }}
                        onClick={() => exportProject(row)}
                      >
                        {/* <a href={downloadURL} download="exported_data.xlsx"> */}
                        Export To Excel
                        {/* </a> */}
                      </Button>
                    </StyledTableCell>

                    {/* <StyledTableCell align="center">
                      {row.salary.perDayCost}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.salary.perHourCost}
                    </StyledTableCell> */}
                  </StyledTableRow>
                ))
              : ""}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
