import SettingsActionType from "./SettingsActionType";

// initialize state
const initialState = {
  allUsers: [],
  newDepartments: [],
  newServices: [],
  newDesignation: [],
  newGenericDesignation: [],
  genericDesignationLabel: [],
  overHead: null,
  overHeadAmt: 0,
  team: null,
  soldProjects: [],
  exportSingleProject: [],
};

const SettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SettingsActionType.FETCH_USERS_START:
      return {
        ...state,
        isError: false,
      };

    case SettingsActionType.FETCH_USERS_SUCCESS:
      return {
        ...state,
        allUsers: action.payload,
      };

    case SettingsActionType.UPDATE_USERS_FAIL:
      return {
        ...state,
        isError: true,
      };

    case SettingsActionType.UPDATE_USERS_START:
      return {
        ...state,
        isError: false,
      };

    case SettingsActionType.UPDATE_USERS_SUCCESS:
      let updatedUsers = state.allUsers.map((item) =>
        item._id === action.payload._id ? action.payload : item
      );
      return {
        ...state,
        allUsers: updatedUsers,
      };

    case SettingsActionType.UPDATE_USERS_FAIL:
      return {
        ...state,
        //  isLoading: false,
        isError: true,
      };

    case SettingsActionType.DELETE_USERS_START:
      return {
        ...state,
        isError: false,
      };

    case SettingsActionType.DELETE_USERS_SUCCESS:
      return {
        ...state,
      };

    case SettingsActionType.DELETE_USERS_FAIL:
      return {
        ...state,
        isError: true,
      };

    case SettingsActionType.FETCH_DEPARTMENT_START:
      return {
        ...state,
        isError: false,
      };

    case SettingsActionType.FETCH_DEPARTMENT_SUCCESS:
      return {
        ...state,
        newDepartments: action.payload,
      };

    case SettingsActionType.FETCH_DEPARTMENT_FAIL:
      return {
        ...state,
        isError: true,
      };

    case SettingsActionType.ADD_DEPARTMENT_START:
      return {
        ...state,
        isError: false,
      };
    case SettingsActionType.ADD_DEPARTMENT_SUCCESS:
      const addDept = action.payload;

      return {
        ...state,
        newDepartments: [...state.newDepartments, addDept],
      };

    case SettingsActionType.ADD_DEPARTMENT_FAIL:
      return {
        ...state,
        isError: true,
      };

    case SettingsActionType.DELETE_DEPARTMENT_START:
      return {
        ...state,
        isError: false,
      };

    case SettingsActionType.DELETE_DEPARTMENT_SUCCESS:
      const id = action.payload._id;
      return {
        ...state,
        newDepartments: state.newDepartments.filter(function (item) {
          return item._id !== id;
        }),
      };

    case SettingsActionType.DELETE_DEPARTMENT_FAIL:
      return {
        ...state,
        isError: true,
      };

    case SettingsActionType.FETCH_SERVICE_START:
      return {
        ...state,
        isError: false,
      };

    case SettingsActionType.FETCH_SERVICE_SUCCESS:
      return {
        ...state,
        newServices: action.payload,
      };

    case SettingsActionType.FETCH_SERVICE_FAIL:
      return {
        ...state,
        isError: true,
      };

    case SettingsActionType.FETCH_DESIGNATION_START:
      return {
        ...state,
        isError: false,
      };

    case SettingsActionType.FETCH_DESIGNATION_SUCCESS:
      return {
        ...state,
        newDesignation: action.payload,
      };

    case SettingsActionType.FETCH_DESIGNATION_FAIL:
      return {
        ...state,
        isError: true,
      };

    case SettingsActionType.ADD_SERVICE_START:
      return {
        ...state,
        isError: false,
      };
    case SettingsActionType.ADD_SERVICE_SUCCESS:
      const addServ = action.payload;

      return {
        ...state,
        newServices: [...state.newServices, addServ],
      };

    case SettingsActionType.ADD_SERVICE_FAIL:
      return {
        ...state,
        isError: true,
      };

    case SettingsActionType.DELETE_SERVICE_START:
      return {
        ...state,
        isError: false,
      };

    case SettingsActionType.DELETE_SERVICE_SUCCESS:
      return {
        ...state,
        newServices: state.newServices.filter(function (item) {
          return item.id !== action.payload;
        }),
      };

    case SettingsActionType.DELETE_SERVICE_FAIL:
      return {
        ...state,
        isError: true,
      };

    case SettingsActionType.ADD_DESIGNATION_START:
      return {
        ...state,
        isError: false,
      };

    case SettingsActionType.ADD_DESIGNATION_SUCCESS:
      const addDesgn = action.payload;

      return {
        ...state,
        newDesignation: [...state.newDesignation, addDesgn],
      };

    case SettingsActionType.ADD_DESIGNATION_FAIL:
      return {
        ...state,
        isError: true,
      };

    case SettingsActionType.UPDATE_DESIGNATION_START:
      return {
        ...state,
        isError: false,
      };

    case SettingsActionType.UPDATE_DESIGNATION_SUCCESS:
      return {
        ...state,
        newDesignation: action.payload,
      };

    case SettingsActionType.UPDATE_DESIGNATION_FAIL:
      return {
        ...state,
        isError: true,
      };
    case SettingsActionType.DELETE_DESIGNATION_START:
      return {
        ...state,
        isError: false,
      };
    case SettingsActionType.ADD_DESIGNATION_SUCCESS:
      return {
        ...state,
        newDesignation: state.newDesignation.filter(function (item) {
          return item.id !== action.payload;
        }),
      };

    case SettingsActionType.DELETE_DESIGNATION_FAIL:
      return {
        ...state,
        isError: true,
      };

    case SettingsActionType.BULK_ADD_DESIGNATION_START:
      return {
        ...state,
        isError: false,
      };

    case SettingsActionType.BULK_ADD_DESIGNATION_SUCCESS:
      return {
        ...state,
        newDesignation: action.payload.data,
      };
    case SettingsActionType.BULK_ADD_DESIGNATION_FAIL:
      return {
        ...state,
        isError: true,
      };

    case SettingsActionType.SET_GENERIC_DESIGNATION_LABEL:
      return { ...state, genericDesignationLabel: action.payload };

    case SettingsActionType.FETCH_GENERIC_DESIGNATION_START:
      return {
        ...state,
        isError: false,
      };

    case SettingsActionType.FETCH_GENERIC_DESIGNATION_SUCCESS:
      return {
        ...state,
        newGenericDesignation: action.payload,
      };

    case SettingsActionType.FETCH_GENERIC_DESIGNATION_FAIL:
      return {
        ...state,
        isError: true,
      };

    case SettingsActionType.ADD_GENERIC_DESIGNATION_START:
      return {
        ...state,
        isError: false,
      };

    case SettingsActionType.ADD_GENERIC_DESIGNATION_SUCCESS:
      const addGenericDesgn = action.payload;

      return {
        ...state,
        newGenericDesignation: [
          ...state.newGenericDesignation,
          addGenericDesgn,
        ],
      };
    case SettingsActionType.ADD_GENERIC_DESIGNATION_FAIL:
      return {
        ...state,
        isError: true,
      };

    case SettingsActionType.DELETE_GENERIC_DESIGNATION_START:
      return {
        ...state,
        isError: false,
      };

    case SettingsActionType.DELETE_GENERIC_DESIGNATION_SUCCESS:
      return {
        ...state,
        newGenericDesignation: state.genericDesignationLabel.filter(function (
          item
        ) {
          return item.id !== action.payload;
        }),
      };

    case SettingsActionType.DELETE_GENERIC_DESIGNATION_FAIL:
      return {
        ...state,
        isError: true,
      };

    case SettingsActionType.FETCH_OVERHEAD_START:
      return {
        ...state,
        isError: false,
      };

    case SettingsActionType.FETCH_OVERHEAD_SUCCESS:
      const amt = action.payload[0];
      return {
        ...state,
        overHead: amt,
        overHeadAmt: amt.overHead,
      };

    case SettingsActionType.FETCH_OVERHEAD_FAIL:
      return {
        ...state,
        isError: true,
      };

    case SettingsActionType.UPDATE_OVERHEAD_START:
      return {
        ...state,
        isError: false,
      };

    case SettingsActionType.UPDATE_OVERHEAD_SUCCESS:
      const updateAmt = action.payload;
      return {
        ...state,
        overHead: updateAmt,
        overHeadAmt: updateAmt.overHead,
      };

    case SettingsActionType.UPDATE_OVERHEAD_FAIL:
      return {
        ...state,
        isError: true,
      };

    case SettingsActionType.ADD_TEAM_START:
      return {
        ...state,
        isError: false,
      };

    case SettingsActionType.ADD_TEAM_SUCCESS:
      return {
        ...state,
        team: action.payload,
      };

    case SettingsActionType.ADD_TEAM_FAIL:
      return {
        ...state,
        isError: true,
      };

    case SettingsActionType.SOLD_PROJECTS_SUCCESS:
      return {
        ...state,
        soldProjects: action.payload,
      };

    case SettingsActionType.EXPORT_SINGLE_PROJECT_SUCCESS:
      return {
        ...state,
        exportSingleProject: action.payload,
      };

    case SettingsActionType.EXPORT_ALL_PROJECT_SUCCESS:
      return {
        ...state,
        exportAllProjects: action.payload,
      };

    default: {
      return state;
    }
  }
};

export default SettingsReducer;
