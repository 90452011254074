import React, { useEffect, useState } from "react";
import "./SharedWithMe.css";
import { Typography } from "@mui/material";
import { ProjectType } from "../../Main/ProjectType";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useHistory } from "react-router-dom";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { Notify } from "../../UI/Notify";
import Cookies from "js-cookie";
import { CircularProgress, Stack } from "@mui/material";
import useFetch from "../../../Hooks/useFetch";
import { RecentProjectGrid } from "../../Main/RecentProjectGrid";
import { RecentProjectList } from "../../Main/RecentProjectList";
import {
  addAllProjects,
  deleteProject,
  requestAllSharedProject,
} from "../../../Redux/Projects/Action";
import { useDispatch, useSelector } from "react-redux";
import { requestDepartment, requestService } from "../../../Redux/Data/Action";

export const SharedWithMe = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const allProjects = useSelector((store) => store.project.allSharedProjects);

  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [view, setView] = useState(
    localStorage.getItem("content-view-type") || "grid"
  );

  const gridHandler = () => {
    setView("grid");
    localStorage.setItem("content-view-type", "grid");
  };

  const listHandler = () => {
    setView("list");
    localStorage.setItem("content-view-type", "list");
  };

  useEffect(() => {
    dispatch(requestAllSharedProject());
  }, []);
  useEffect(() => {}, [allProjects]);

  return (
    <>
      <Notify notification={notification} setNotification={setNotification} />

      <div className="recentviewed">
        <div className="topbar">
          <div>
            <Typography
              variant="h6"
              sx={{ color: "#0F172A" }}
              mb="30px"
              fontWeight="500"
            >
              Shared Projects
            </Typography>
          </div>
          {/* <MoreHorizIcon sx={{ verticalAlign: "middle", color: "#c4c4c4" }} /> */}
          <div>
            <FormatListBulletedIcon
              onClick={listHandler}
              sx={{
                verticalAlign: "middle",
                mr: "10px",
                cursor: "pointer",
              }}
            />
            <GridViewIcon
              onClick={gridHandler}
              sx={{
                verticalAlign: "middle",
                align: "right",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        {view === "grid" ? (
          <RecentProjectGrid data={allProjects} action={true} />
        ) : (
          <RecentProjectList data={allProjects} action={true} />
        )}
      </div>
    </>
  );
};
