import SettingsActionType from "./SettingsActionType";
import axios from "axios";
import Cookies from "js-cookie";

// uSERS
export const requestAllUsers = () => async (dispatch) => {
  dispatch({
    type: SettingsActionType.FETCH_USERS_START,
  });
  try {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/api/user/get-users`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: SettingsActionType.FETCH_USERS_SUCCESS,
      payload: response.data,
      isError: false,
    });
  } catch (e) {
    dispatch({
      type: SettingsActionType.FETCH_USERS_FAIL,
      payload: [],
      isError: true,
    });
  }
};

export const updateUsers = (data) => async (dispatch) => {
  dispatch({
    type: SettingsActionType.UPDATE_USERS_START,
  });

  try {
    const response = await axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/api/user/edit-user-role/${data._id}`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: SettingsActionType.UPDATE_USERS_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: SettingsActionType.UPDATE_USERS_FAIL,
      payload: e,
      isError: true,
    });
  }
};

export const deleteUsers = (data) => async (dispatch) => {
  dispatch({
    type: SettingsActionType.DELETE_USERS_START,
  });

  try {
    const response = await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/api/user/delete-user/${data._id}`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: SettingsActionType.DELETE_USERS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: SettingsActionType.DELETE_USERS_FAIL,
      payload: e,
      isError: true,
    });
  }
};

// Department
export const requestAllDepartment = () => async (dispatch) => {
  dispatch({
    type: SettingsActionType.FETCH_DEPARTMENT_START,
  });
  try {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/api/department/get-department`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: SettingsActionType.FETCH_DEPARTMENT_SUCCESS,
      payload: response.data,
      isError: false,
    });
  } catch (e) {
    dispatch({
      type: SettingsActionType.FETCH_DEPARTMENT_FAIL,
      payload: [],
      isError: true,
    });
  }
};

export const addNewDepartment = (formData) => async (dispatch) => {
  dispatch({
    type: SettingsActionType.ADD_DEPARTMENT_START,
  });

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/department/create-department`,
      data: formData,
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });

    dispatch({
      type: SettingsActionType.ADD_DEPARTMENT_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: SettingsActionType.ADD_DEPARTMENT_FAIL,
      payload: e,
      isError: true,
    });
  }
};

export const deleteDepartment = (data) => async (dispatch) => {
  dispatch({
    type: SettingsActionType.DELETE_DEPARTMENT_START,
  });

  try {
    const response = await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/api/department/delete-department/${data._id}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: SettingsActionType.DELETE_DEPARTMENT_SUCCESS,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: SettingsActionType.DELETE_DEPARTMENT_FAIL,
      payload: e,
      isError: true,
    });
  }
};

// Service

export const requestAllServices = () => async (dispatch) => {
  dispatch({
    type: SettingsActionType.FETCH_SERVICE_START,
  });
  try {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/api/service/get-service`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: SettingsActionType.FETCH_SERVICE_SUCCESS,
      payload: response.data,
      isError: false,
    });
  } catch (e) {
    dispatch({
      type: SettingsActionType.FETCH_SERVICE_FAIL,
      payload: [],
      isError: true,
    });
  }
};

export const addNewService = (data) => async (dispatch) => {
  dispatch({
    type: SettingsActionType.ADD_SERVICE_START,
  });

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/service/create-service`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: SettingsActionType.ADD_SERVICE_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: SettingsActionType.ADD_SERVICE_FAIL,
      payload: e,
      isError: true,
    });
  }
};

export const deleteService = (data) => async (dispatch) => {
  dispatch({
    type: SettingsActionType.DELETE_SERVICE_START,
  });

  try {
    const response = await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/api/service/delete-service/${data._id}`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: SettingsActionType.DELETE_SERVICE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: SettingsActionType.DELETE_SERVICE_FAIL,
      payload: e,
      isError: true,
    });
  }
};

export const requestAllDesignation = () => async (dispatch) => {
  dispatch({
    type: SettingsActionType.FETCH_DESIGNATION_START,
  });
  try {
    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/designation/get-designation-by-generic-name`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: SettingsActionType.FETCH_DESIGNATION_SUCCESS,
      payload: response.data,
      isError: false,
    });
  } catch (e) {
    dispatch({
      type: SettingsActionType.FETCH_DESIGNATION_FAIL,
      payload: [],
      isError: true,
    });
  }
};

export const addNewDesignation = (data) => async (dispatch) => {
  dispatch({
    type: SettingsActionType.ADD_DESIGNATION_START,
  });

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/designation/create-designation`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: SettingsActionType.ADD_DESIGNATION_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: SettingsActionType.ADD_DESIGNATION_FAIL,
      payload: e,
      isError: true,
    });
  }
};

export const bulkAddNewDesignation = (data) => async (dispatch) => {
  dispatch({
    type: SettingsActionType.BULK_ADD_DESIGNATION_START,
  });

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/designation/bulk-update-designations`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: SettingsActionType.BULK_ADD_DESIGNATION_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: SettingsActionType.BULK_ADD_DESIGNATION_FAIL,
      payload: e,
      isError: true,
    });
  }
};

export const updateDesignation = (_id, data) => async (dispatch) => {
  dispatch({
    type: SettingsActionType.UPDATE_DESIGNATION_START,
  });

  try {
    const response = await axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/api/designation/update-designation/${_id}`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: SettingsActionType.UPDATE_DESIGNATION_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: SettingsActionType.UPDATE_DESIGNATION_FAIL,
      payload: e,
      isError: true,
    });
  }
};

export const deleteDesignation = (data) => async (dispatch) => {
  dispatch({
    type: SettingsActionType.DELETE_DEPARTMENT_START,
  });

  try {
    const response = await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/api/designation/delete-designation/${data._id}`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: SettingsActionType.DELETE_DEPARTMENT_SUCCESS,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: SettingsActionType.DELETE_DEPARTMENT_FAIL,
      payload: e,
      isError: true,
    });
  }
};

// GeneriC Designation

export const requestAllGenericDesignation = () => async (dispatch) => {
  dispatch({
    type: SettingsActionType.FETCH_GENERIC_DESIGNATION_START,
  });
  try {
    const response = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/genericDesignation/get-generic-designation`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: SettingsActionType.FETCH_GENERIC_DESIGNATION_SUCCESS,
      payload: response.data,
      isError: false,
    });
  } catch (e) {
    dispatch({
      type: SettingsActionType.FETCH_GENERIC_DESIGNATION_FAIL,
      payload: [],
      isError: true,
    });
  }
};

export const setGenericDesignationLabel = (Data) => ({
  type: SettingsActionType.SET_GENERIC_DESIGNATION_LABEL,
  payload: Data,
});

export const addNewGenericDesignation = (data) => async (dispatch) => {
  dispatch({
    type: SettingsActionType.ADD_GENERIC_DESIGNATION_START,
  });

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/genericDesignation/create-generic-designation`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: SettingsActionType.ADD_GENERIC_DESIGNATION_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: SettingsActionType.ADD_GENERIC_DESIGNATION_FAIL,
      payload: e,
      isError: true,
    });
  }
};

export const deleteGenericDesignation = (data) => async (dispatch) => {
  dispatch({
    type: SettingsActionType.DELETE_GENERIC_DESIGNATION_START,
  });

  try {
    const response = await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/api/genericDesignation/delete-generic-designation/${data._id}`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: SettingsActionType.DELETE_GENERIC_DESIGNATION_SUCCESS,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: SettingsActionType.DELETE_GENERIC_DESIGNATION_FAIL,
      payload: e,
      isError: true,
    });
  }
};

//OVERHEAD
export const requestOverHead = () => async (dispatch) => {
  dispatch({
    type: SettingsActionType.FETCH_OVERHEAD_START,
  });
  try {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/api/overhead/get-over-head`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: SettingsActionType.FETCH_OVERHEAD_SUCCESS,
      payload: response.data,
      isError: false,
    });
  } catch (e) {
    dispatch({
      type: SettingsActionType.FETCH_OVERHEAD_FAIL,
      payload: [],
      isError: true,
    });
  }
};

export const addNewOverHead = (data) => async (dispatch) => {
  dispatch({
    type: SettingsActionType.UPDATE_OVERHEAD_START,
  });

  try {
    const response = await axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/api/overhead/update-over-head/${data._id}`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: SettingsActionType.UPDATE_OVERHEAD_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: SettingsActionType.UPDATE_OVERHEAD_FAIL,
      payload: e,
      isError: true,
    });
  }
};

// Add Team
export const addTeam = (data) => async (dispatch) => {
  dispatch({
    type: SettingsActionType.ADD_TEAM_START,
  });
  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/team/create-team`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });

    dispatch({
      type: SettingsActionType.ADD_TEAM_SUCCESS,
      payload: response.data,
      isError: false,
    });
  } catch (e) {
    dispatch({
      type: SettingsActionType.ADD_TEAM_FAIL,
      payload: [],
      isError: true,
    });
  }
};

//Get Sold Projects
export const soldProjects = () => async (dispatch) => {
  dispatch({
    type: SettingsActionType.SOLD_PROJECTS_START,
  });
  try {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/api/project/get-projects-to-admin?pagination=false`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });
    dispatch({
      type: SettingsActionType.SOLD_PROJECTS_SUCCESS,
      payload: response.data,
      isError: false,
    });
  } catch (e) {
    dispatch({
      type: SettingsActionType.SOLD_PROJECTS_FAIL,
      payload: [],
      isError: true,
    });
  }
};

//Export Single Project
export const exportSingleProject = (data) => async (dispatch) => {
  dispatch({
    type: SettingsActionType.EXPORT_SINGLE_PROJECT_START,
  });
  try {
    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/project/generate-excel?q=single`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      responseType: "blob",
    });
    const blob = response.data;
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = "exported-data.xlsx";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    dispatch({
      type: SettingsActionType.EXPORT_SINGLE_PROJECT_SUCCESS,
      payload: response.data,
      isError: false,
    });
  } catch (e) {
    dispatch({
      type: SettingsActionType.EXPORT_SINGLE_PROJECT_FAIL,
      payload: [],
      isError: true,
    });
  }
};

//Export All Projects
export const exportAllProjects = (data) => async (dispatch) => {
  dispatch({
    type: SettingsActionType.EXPORT_ALL_PROJECT_START,
  });
  try {
    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/project/generate-excel?q=multiple`,
      data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      responseType: "blob",
    });
    const blob = response.data;
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = "exported-data.xlsx";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    dispatch({
      type: SettingsActionType.EXPORT_ALL_PROJECT_SUCCESS,
      payload: response.data,
      isError: false,
    });
  } catch (e) {
    dispatch({
      type: SettingsActionType.EXPORT_ALL_PROJECT_FAIL,
      payload: [],
      isError: true,
    });
  }
};
