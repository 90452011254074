import DataActionType from "./DataActionType";

// initialize state
const initialState = {
  allDepartments: [],
  allServices: [],
  projectId: "",
  projectName: "",
  projectTypeName: "Retainer Project",
  userName: "",
  clientName: "",
  notes: "",
  slug: "",
  totalResources: 0,
  costToClient: 0,
  costToCompany: 0,
  profitToCompany: 0,
  createdAt: "",
  updatedAt: "",
  departmentData: [],
  departments: [],
  departmentServices: [],
  isLoading: false,
  isError: false,
  token: "",
  cloneId: null,
  deletedDepartmentData: [],
};
const DataReducer = (state = initialState, action) => {
  switch (action.type) {
    case DataActionType.SET_DEPARTMENTS:
      return { ...state, departments: action.payload };

    case DataActionType.CLEAR_DEPARTMENT:
      return {
        ...state,
        cloneId: null,
        projectName: "",
        projectTypeName: "",
        userName: "",
        projectData: "",
        clientName: "",
        notes: "",
        slug: "",
        projectId: "",
        departmentData: [],
        departments: [],
        departmentServices: [],
        createdAt: "",
        updatedAt: "",
        clientName: "",
      };

    case DataActionType.SET_PROJECT_INFO:
      return { ...state, ...action.payload };

    case DataActionType.SET_DEPARTMENTS_DATA:
      return { ...state, allDepartments: action.payload };

    case DataActionType.SET_SERVICES_DATA:
      return { ...state, allServices: action.payload };

    case DataActionType.ADD_PROJECT_TYPE:
      return { ...state, projectTypeName: action.payload };

    case DataActionType.ADD_USERNAME:
      return { ...state, userName: action.payload };

    case DataActionType.ADD_SLUG:
      return { ...state, slug: action.payload };

    case DataActionType.SET_PROJECT_NAME:
      return { ...state, projectName: action.payload };

    case DataActionType.SET_CLIENT_NAME:
      return { ...state, clientName: action.payload };

    case DataActionType.SET_DEPARTMENT_SERVICES:
      return { ...state, departmentServices: action.payload };

    case DataActionType.ADD_NOTE:
      return { ...state, notes: action.payload };

    case DataActionType.SET_PROJECT_ID:
      return { ...state, projectId: action.payload };

    case DataActionType.SET_FINAL_UPDATE_PROJECT_DATA:
      return {
        ...state,
        projectId: action.payload._id,
        notes: action.payload.notes,
        clientName: action.payload.clientName,
        projectName: action.payload.projectName,
        userName: action.payload.userName,
        projectTypeName: action.payload.projectTypeName,
        createdAt: action.payload.createdAt,
        updatedAt: action.payload.updatedAt,
      };

    case DataActionType.CLONE_PROJECT_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case DataActionType.CLONE_PROJECT_SUCCESS:
      return {
        ...state,
        cloneId: action.payload.oldProjectId,
        notes: action.payload.notes,
        userName: action.payload.userName,
        projectTypeName: action.payload.projectTypeName,
      };
    case DataActionType.CLONE_PROJECT_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case DataActionType.FETCH_DEPARTMENT_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case DataActionType.FETCH_DEPARTMENT_SUCCESS:
      return {
        ...state,
        allDepartments: action.departmentData,
        isLoading: false,
      };
    case DataActionType.FETCH_DEPARTMENT_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case DataActionType.FETCH_SERVICE_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case DataActionType.FETCH_SERVICE_SUCCESS:
      return {
        ...state,
        allServices: action.departmentData,
        isLoading: false,
      };
    case DataActionType.FETCH_SERVICE_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case DataActionType.SET_TOKEN_DATA:
      return { ...state, token: action.payload };

    // Deleted Department Data
    case DataActionType.DELETED_DEPARTMENT_DATA:
      return {
        ...state,
        deletedDepartmentData: action.payload,
      };

    default:
      return state;
  }
};

export default DataReducer;
