import React, { useState, useEffect } from "react";
import { Typography, Grid, Box, Button, TextField, Modal } from "@mui/material";
import "./Settings.css";
import { AddDepartment } from "./AddDepartment";
import { AddServices } from "./AddServices";
import { GenericDesignation } from "./GenericDesignation";
import CloseIcon from "@mui/icons-material/Close";
import { flexbox } from "@mui/system";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { Designation } from "./Designation";
import { useDispatch, useSelector } from "react-redux";
import { UserRole } from "./UserRole";
import { OverHead } from "./OverHead";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { DesignationView } from "./DesignationView";
import { Collaborators } from "../Collaborators/Collaborators";
import { AddTeam } from "./AddTeam";
import { SoldProjects } from "./SoldProjects";
import { soldProjects } from "../../../Redux/Settings/Action";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "5px",
  boxShadow: 24,
  backgroundColor: "white",
  p: 2,
};



// tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
            {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Settings = () => {
  const [department, setDepartment] = useState(false);
  const [team, setTeam] = useState(false);
  const [genericDesignation, setGenericDesignation] = useState(false);
  const [designation, setDesignation] = useState(false);
  const [overHead, setOverHead] = useState(false);

  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const departmentOpenHandler = () => setDepartment(true);
  const departmentCloseHandler = () => setDepartment(false);

  const teamOpenHandler = () => setTeam(true);
  const teamCloseHandler = () => setTeam(false);

  const genericDesignationOpenHandler = () => setGenericDesignation(true);
  const genericDesignationCloseHandler = () => setGenericDesignation(false);

  const designationOpenHandler = () => setDesignation(true);
  const designationCloseHandler = () => setDesignation(false);

  const overHeadOpenHandler = () => setOverHead(true);
  const overHeadCloseHandler = () => setOverHead(false);

  //Rendering All Sold Projects when the Settings component is clicked
  useEffect(() => {
    dispatch(soldProjects())
  }, [])

  return (
    <>
      <Typography sx={{ color: "#0F172A" }} mb="30px" variant="h6">
        Settings:
      </Typography>

      <Grid
        container
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        spacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {/* Department */}
        <Grid item sm={6} xs={3} lg={3} md={4}>
          <div className="set__cards">
            <div className="set__cardsinfo" onClick={departmentOpenHandler}>
              <Typography
                variant="h6"
                sx={{ color: "#5E5873", fontSize: "1.1rem" }}
                fontWeight="500"
              >
                Departments
              </Typography>
              <AddTaskIcon color="primary" className="set__cardsicons" />
            </div>
          </div>
          <Modal open={department} onClose={departmentCloseHandler}>
            <Box sx={style}>
              <CloseIcon
                color="danger"
                onClick={departmentCloseHandler}
                sx={{ marginLeft: "auto", display: "flex", cursor: "pointer" }}
              />
              <AddDepartment />
            </Box>
          </Modal>
        </Grid>

        {/*  Generic Designation  */}
        <Grid item sm={6} xs={3} lg={3} md={4}>
          <div onClick={genericDesignationOpenHandler} className="set__cards">
            <div className="set__cardsinfo">
              <Typography
                variant="h6"
                sx={{ color: "#5E5873", fontSize: "1.1rem" }}
                fontWeight="500"
              >
                Generic Designation
              </Typography>
              <AddTaskIcon color="primary" className="set__cardsicons" />
            </div>
          </div>
          <Modal
            open={genericDesignation}
            onClose={genericDesignationCloseHandler}
          >
            <Box sx={style}>
              <CloseIcon
                color="danger"
                onClick={genericDesignationCloseHandler}
                sx={{ marginLeft: "auto", display: "flex", cursor: "pointer" }}
              />
              <GenericDesignation />
            </Box>
          </Modal>
        </Grid>

        {/* Designation */}
        <Grid item sm={6} xs={3} lg={3} md={4}>
          <div onClick={designationOpenHandler} className="set__cards">
            <div className="set__cardsinfo">
              <Typography
                variant="h6"
                sx={{ color: "#5E5873", fontSize: "1.1rem" }}
                fontWeight="500"
              >
                Designation
              </Typography>
              <AddTaskIcon color="primary" className="set__cardsicons" />
            </div>
          </div>
          <Modal open={designation} onClose={designationCloseHandler}>
            <Box sx={style}>
              <CloseIcon
                color="danger"
                onClick={designationCloseHandler}
                sx={{ marginLeft: "auto", display: "flex", cursor: "pointer" }}
              />
              <Designation />
            </Box>
          </Modal>
        </Grid>

        {/* OverHead */}
        <Grid item sm={6} xs={3} lg={3} md={4}>
          <div className="set__cards">
            <div className="set__cardsinfo" onClick={overHeadOpenHandler}>
              <Typography
                variant="h6"
                sx={{ color: "#5E5873", fontSize: "1.1rem" }}
                fontWeight="500"
              >
                Overhead
              </Typography>
              <AddTaskIcon color="primary" className="set__cardsicons" />
            </div>
          </div>
          <Modal open={overHead} onClose={overHeadCloseHandler}>
            <Box sx={style}>
              <CloseIcon
                color="danger"
                onClick={overHeadCloseHandler}
                sx={{ marginLeft: "auto", display: "flex", cursor: "pointer" }}
              />
              <OverHead />
            </Box>
          </Modal>
        </Grid>

        {/* Teams */}
        {/* <Grid item lg={3}>
          <div onClick={teamOpenHandler} className="set__cards">
            <div onClick={teamOpenHandler} className="set__cardsinfo">
              <Typography
                variant="h6"
                sx={{ color: "#5E5873" }}
                fontWeight="500"
              >
                Team
              </Typography>

              <AddTaskIcon color="primary" className="set__cardsicons" />
            </div>
          </div>

          <Modal open={team} onClose={teamCloseHandler}>
            <Box sx={style}>
              <CloseIcon
                color="danger"
                onClick={teamCloseHandler}
                sx={{ marginLeft: "auto", display: "flex", cursor: "pointer" }}
              />
              <AddTeam />
            </Box>
          </Modal>
        </Grid> */}
      </Grid>
      <Grid>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{ borderBottom: 1, marginTop: "20px", borderColor: "divider" }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Users" {...a11yProps(0)} />
              <Tab label="Designations" {...a11yProps(1)} />
              <Tab label="All Sold Projects" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <UserRole />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DesignationView />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <SoldProjects />
          </TabPanel>
        </Box>
        {/* <Typography sx={{ color: "#0F172A" }} my="30px" variant="h6">
          Users:
        </Typography>
        <Grid sx={{ marginTop: "20px" }} item lg={12}>
          <UserRole />
        </Grid> */}
      </Grid>
    </>
  );
};
