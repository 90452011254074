import React, { useState } from "react";
import { Grid, Typography, Button, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addNote } from "../../../Redux/Data/Action";
import Cookies from "js-cookie";
import {
  EmptyVersionComment,
  addProject,
  clonePricing,
  editVersionNotes,
  getPricingVersions,
  setProjectPricingDepartmentData,
  updatePricingByProject,
  updateProject,
} from "../../../Redux/Projects/Action";
import { Notify } from "../../UI/Notify";
import { Link, useHistory, useLocation } from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import "./Notes.css";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css"; // Add css for snow theme

// New code

const PayloadFormatter = ({
  projectName,
  clientName,
  projectTypeName,
  selectedDepartments = [],
  services = [],
  notes,
}) => {
  const userName = Cookies.get("userName");
  const departments = selectedDepartments.map((department) => {
    let itsServices = [];

    services.forEach((item) => {
      if (item.departmentId === department._id) {
        itsServices.push({
          serviceName: item.serviceName,
          serviceId: item._id,
        });
      }
    });

    return {
      departmentName: department.name,
      departmentId: department._id,
      // services: itsServices,
    };
  });

  return {
    projectName,
    clientName,
    projectTypeName,
    userName,
    notes,
    departments,
  };
};

export const Notes = () => {
  const {
    departments,
    departmentServices,
    projectTypeName,
    projectName,
    clientName,
    notes,
    allDepartments,
  } = useSelector((store) => store.department);
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = params.get("page");
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const { quill, quillRef } = useQuill(notes);

  const store = useSelector((store) => store);

  const [note, setNote] = useState(notes);
  const [clone, setClone] = useState(false);

  const dispatch = useDispatch();

  const notesInputHandler = (e) => {
    dispatch(addNote(note));
  };

  React.useEffect(() => {
    dispatch(addNote(note));
  }, [note]);

  const notesHandler = async () => {
    dispatch(addNote(note));
    dispatch(editVersionNotes({}));
    dispatch(EmptyVersionComment());
    if (store.project.isError === true) {
      setNotification({
        isOpen: true,
        message: "Project Name Already exists!!",
        type: "error",
      });
    }

    //remember before using formatter here you will need to get data from store and then give it to formatter function then you will be get payload and pass it to api
    const ApiFinalPayload = PayloadFormatter({
      projectName,
      clientName,
      projectTypeName,
      selectedDepartments: departments,
      services: departmentServices,
      notes,
    });
    // Department Updated as per all deps

    if (store.department.cloneId === null) {
      if (store.department.projectId) {
        const id = { _id: store.department.projectId };
        const updatedProject = { ...id, ...ApiFinalPayload };
        await dispatch(updateProject(updatedProject));
        const deps = departments;
        dispatch(setProjectPricingDepartmentData(deps));
        await dispatch(updatePricingByProject(id._id));
        await dispatch(getPricingVersions(id._id));
        await history.push(
          `/calculate/${store.department.projectId}?page=${page}`
        );
      } else {
        dispatch(addProject(ApiFinalPayload));
      }
    } else {
      dispatch(addProject({ ...ApiFinalPayload, logo: store.project.logo }));
      setTimeout(() => {
        setClone(true);
      }, 2000);
    }
  };

  React.useEffect(() => {
    if (clone && store.project.projectData._id) {
      const _id = store.project.projectData._id;

      dispatch(clonePricing(store.department.cloneId, _id));
      // setTimeout(() => {
      history.push(`/calculate/${_id}?page=${page}`);
      setClone(false);
      // }, 1000);
    }
  }, [clone]);

  if (
    store.project.projectData !== null &&
    store.project.editMode === false &&
    store.department.cloneId === null
  ) {
    const _id = store.project.projectData._id;
    history.push(`/calculate/${_id}?page=${page}`);
  }

  return (
    <>
      <Notify notification={notification} setNotification={setNotification} />

      <div>
        <Grid container mt="40px" gap={"5px"}>
          <Grid item lg={1}>
            <Typography variant="h5">2/2</Typography>
          </Grid>
          <Grid item lg={11}>
            <Typography variant="h5">
              {projectTypeName === "Fixed Project"
                ? "Add Notes"
                : "Add Deliverables"}
            </Typography>
            <Grid item lg={9} mt="40px">
              <TextField
                multiline
                rows={15}
                color="primary"
                value={note}
                style={{ background: "white" }}
                fullWidth
                onChange={(e) => setNote(e.target.value)}
              />
            </Grid>
            {!store.department.projectId ? (
              <>
                <Button
                  sx={{
                    color: "#ffffff",
                    fontWeight: "500",
                    mt: "30px",
                    px: "40px",
                    backgroundColor: "#2d99fe",
                  }}
                  component={Link}
                  to={`/home/department`}
                  variant="contained"
                >
                  Back
                </Button>
                <Button
                  sx={{
                    color: "#ffffff",
                    fontWeight: "500",
                    mt: "30px",
                    ml: "8px",
                    px: "40px",
                    backgroundColor: "#2d99fe",
                  }}
                  variant="contained"
                  onClick={notesHandler}
                  startIcon={<SaveIcon />}
                >
                  Calculate
                </Button>
              </>
            ) : (
              <>
                {/* </Link> */}
                <Button
                  sx={{
                    color: "#ffffff",
                    fontWeight: "500",
                    mt: "30px",
                    px: "40px",
                    backgroundColor: "#2d99fe",
                  }}
                  component={Link}
                  to="/home/department"
                  variant="contained"
                >
                  Back
                </Button>
                {/* <Link
                  to={`/calculate/${store.department.projectId}`}
                  className="link"
                > */}
                <Button
                  sx={{
                    color: "#ffffff",
                    fontWeight: "500",
                    mt: "30px",
                    ml: "8px",
                    px: "40px",
                    backgroundColor: "#2d99fe",
                  }}
                  variant="contained"
                  onClick={notesHandler}
                  startIcon={<EditIcon />}
                >
                  Calculate
                </Button>
              </>
            )}

            {/* </a> */}
          </Grid>
        </Grid>
      </div>
    </>
  );
};
